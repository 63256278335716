import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Statistics
export function* usersStatisticsSaga() {
  try {
    const { data } = yield call(APIS.usersStatisticsRequest);
    yield put(ACTIONS.usersStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Internal Users List
export function* internalUsersSaga({payload}) {
  try {
    const { data } = yield call(APIS.internalUsersRequest, payload);
    yield put(ACTIONS.internalUsersReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get User
export function* userSaga({id}) {
  try {
    const { data } = yield call(APIS.userRequest, id);
    yield put(ACTIONS.userReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add User
export function* addUserSaga({payload, history}) {
  try {
    yield call(APIS.addUserRequest, payload);
    dispatchSnackbarSuccess("success");
    history.push("/users/internal");
  } catch (err) {
    let msg = "";
    if (err.response?.data?.errors?.name) {
      msg = err.response?.data?.errors?.name?.en || err.response?.data?.errors?.name?.ar;
    } else if (err.response?.data?.errors?.phone_number) {
      msg = err.response?.data?.errors?.phone_number[0];
      //yield put(ACTIONS.validateMobileReceive({val: false, msg: err.response?.data?.errors?.phone_number[0]}));
    } else if (err.response?.data?.errors?.email) {
      msg = err.response?.data?.errors?.email[0];
    } else {
      msg = err.response?.data?.message;
    }
    dispatchSnackbarError({message : msg});
  }
}
//Edit User
export function* editUserSaga({payload, history}) {
  try {
    yield call(APIS.editUserRequest, payload);
    dispatchSnackbarSuccess("success");
    history.push("/users/internal");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete User
export function* deleteUserSaga({payload}) {
  try {
    yield call(APIS.deleteUserRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteUserReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Block User
export function* blockUserSaga({payload}) {
  try {
    yield call(APIS.blockUserRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.blockUserReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Unblock User
export function* unblockUserSaga({payload}) {
  try {
    yield call(APIS.unblockUserRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.unblockUserReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Validate Email
export function* validateEmailSaga({payload}) {
  try {
    yield put(ACTIONS.cleanErrors());
    yield call(APIS.validateEmailRequest, payload);
    yield put(ACTIONS.validateEmailReceive({val: true, msg: ''}));
  } catch (err) {
    yield put(ACTIONS.validateEmailReceive({val: false, msg: err.response?.data?.errors?.email[0]}));
  }
}
//Validate Mobile
export function* validateMobileSaga({payload}) {
  try {
    yield put(ACTIONS.cleanErrors());
    yield call(APIS.validateMobileRequest, payload);
    yield put(ACTIONS.validateMobileReceive({val: true, msg: ''}));
  } catch (err) {
    yield put(ACTIONS.validateMobileReceive({val: false, msg: err.response?.data?.errors?.phone_number[0]}));
  }
}

export function* usersSagas() {
  yield takeLatest(TYPES.USERS_STATISTICS_REQUEST, usersStatisticsSaga);
  yield takeLatest(TYPES.INTERNAL_USERS_REQUEST, internalUsersSaga);
  yield takeLatest(TYPES.USER_REQUEST, userSaga);
  yield takeLatest(TYPES.ADD_USER_REQUEST, addUserSaga);
  yield takeLatest(TYPES.EDIT_USER_REQUEST, editUserSaga);
  yield takeLatest(TYPES.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(TYPES.BLOCK_USER_REQUEST, blockUserSaga);
  yield takeLatest(TYPES.UNBLOCK_USER_REQUEST, unblockUserSaga);
  yield takeLatest(TYPES.VALIDATE_EMAIL_REQUEST, validateEmailSaga);
  yield takeLatest(TYPES.VALIDATE_MOBILE_REQUEST, validateMobileSaga);
}


