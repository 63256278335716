//new requests
export const NEW_REQUESTS_REQUEST = "NEW_REQUESTS_REQUEST";
export const NEW_REQUESTS_RECEIVE = "NEW_REQUESTS_RECEIVE";
//request sources
export const REQUEST_SOURCES_REQUEST = "REQUEST_SOURCES_REQUEST";
export const REQUEST_SOURCES_RECEIVE = "REQUEST_SOURCES_RECEIVE";
//show request
export const SHOW_REQUEST_REQUEST = "SHOW_REQUEST_REQUEST";
export const SHOW_REQUEST_RECEIVE = "SHOW_REQUEST_RECEIVE";
//request rejection reasons
export const REQUEST_REJECTION_REASONS_REQUEST = "REQUEST_REJECTION_REASONS_REQUEST";
export const REQUEST_REJECTION_REASONS_RECEIVE = "REQUEST_REJECTION_REASONS_RECEIVE";
//rejection reasons
export const REJECTION_REASONS_REQUEST = "REJECTION_REASONS_REQUEST";
export const REJECTION_REASONS_RECEIVE = "REJECTION_REASONS_RECEIVE";
//reject request
export const REJECT_REQUEST_REQUEST = " REJECT_REQUEST_REQUEST";
//accept request
export const ACCEPT_REQUEST_REQUEST = "ACCEPT_REQUEST_REQUEST";
export const ACCEPT_REQUEST_RECEIVE = "ACCEPT_REQUEST_RECEIVE";
//Active requests
export const ACTIVE_REQUESTS_REQUEST = "ACTIVE_REQUESTS_REQUEST";
export const ACTIVE_REQUESTS_RECEIVE = "ACTIVE_REQUESTS_RECEIVE";
//rejected requests
export const REJECTED_REQUESTS_REQUEST = "REJECTED_REQUESTS_REQUEST"
export const REJECTED_REQUESTS_RECEIVE = "REJECTED_REQUESTS_RECEIVE"
//All Requests
export const ALL_REQUESTS_REQUEST = "ALL_REQUESTS_REQUEST"
export const ALL_REQUESTS_RECEIVE = "ALL_REQUESTS_RECEIVE"
//Completed Requests
export const COMPLETED_REQUESTS_REQUEST = "COMPLETED_REQUESTS_REQUEST"
export const COMPLETED_REQUESTS_RECEIVE = "COMPLETED_REQUESTS_RECEIVE"
//Canceled Requests
export const CANCELED_REQUESTS_REQUEST = "CANCELED_REQUESTS_REQUEST"
export const CANCELED_REQUESTS_RECEIVE = "CANCELED_REQUESTS_RECEIVE"
//Patient Requests
export const PATIENT_REQUESTS_REQUEST = "PATIENT_REQUESTS_REQUEST"
export const PATIENT_REQUESTS_RECEIVE = "PATIENT_REQUESTS_RECEIVE"
//Cancel Request
export const CANCEL_REQUEST_REQUEST = "CANCEL_REQUEST_REQUEST";
export const CANCEL_REQUEST_RECEIVE = "CANCEL_REQUEST_RECEIVE";
//set Canceled Request
export const SET_CANCELED_REQUEST = "SET_CANCELED_REQUEST";
//Add New Request 
export const ADD_NEW_REQUEST_REQUEST = "ADD_NEW_REQUEST_REQUEST";
export const ADD_NEW_REQUEST_RECEIVE = "ADD_NEW_REQUEST_RECEIVE";
//Set New Request
export const SET_REQUEST_ADDED = "SET_REQUEST_ADDED";
//get requests cancel reasons
export const REQUESTS_CANCEL_REASONS_REQUEST = "REQUESTS_CANCEL_REASONS_REQUEST";
export const REQUESTS_CANCEL_REASONS_RECEIVE = "REQUESTS_CANCEL_REASONS_RECEIVE";