import { axiosInstance } from "../../network";

//Get Statistics
export const getRefsStatisticsRequest = async () => {
  return await axiosInstance.get('system/v1/reference-labs/statistics');
}
// Get RefrenceLabs List for LabTests
export const getReferenceLabsListRequest = async (payload) => {
  return await axiosInstance.get('system/v1/reference-labs/list', {params: payload});
}
//GET ReferenceLabs List
export const getReferenceLabsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/reference-labs', {params: payload});
}
//Get ReferenceLab
export const getReferenceLabRequest = async (payload) => {
  return await axiosInstance.get('system/v1/reference-labs/' + payload);
}
//Add ReferenceLab
export const addReferenceLabRequest = async (payload) => {
  return await axiosInstance.post('system/v1/reference-labs', payload);
}
//Edit ReferenceLab
export const editReferenceLabRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/reference-labs/${payload.id}`, payload);
}
//Delete ReferenceLab
export const deleteReferenceLabRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/reference-labs/${payload}`);
}
//Active ReferenceLab
export const activeReferenceLabRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/reference-labs/${payload}/active`);
}
//Desactive ReferenceLab
export const desactiveReferenceLabRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/reference-labs/${payload}/deactive`);
}
//Get Countries List
export const getCountriesRequest = async () => {
  return await axiosInstance.get('system/v1/reference-labs/countries');
}
