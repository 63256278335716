//Get ATTACHMENT List
export const GET_ATTACHMENTS_REQUEST = "GET_ATTACHMENTS_REQUEST";
export const GET_ATTACHMENTS_RECEIVE = "GET_ATTACHMENTS_RECEIVE";
//Get ATTACHMENT
export const GET_ATTACHMENT_REQUEST = "GET_ATTACHMENT_REQUEST";
export const GET_ATTACHMENT_RECEIVE = "GET_ATTACHMENT_RECEIVE";
//Add ATTACHMENT
export const ADD_ATTACHMENT_REQUEST = "ADD_ATTACHMENT_REQUEST";
export const ADD_ATTACHMENT_RECEIVE = "ADD_ATTACHMENT_RECEIVE";
//Edit ATTACHMENT
export const EDIT_ATTACHMENT_REQUEST = "EDIT_ATTACHMENT_REQUEST";
export const EDIT_ATTACHMENT_RECEIVE = "EDIT_ATTACHMENT_RECEIVE";
//Delete ATTACHMENT
export const DELETE_ATTACHMENT_REQUEST = "DELETE_ATTACHMENT_REQUEST";
export const DELETE_ATTACHMENT_RECEIVE = "DELETE_ATTACHMENT_RECEIVE";
//Download ATTACHMENT
export const DOWNLOAD_ATTACHMENT_REQUEST = "DOWNLOAD_ATTACHMENT_REQUEST";
export const DOWNLOAD_ATTACHMENT_RECEIVE = "DOWNLOAD_ATTACHMENT_RECEIVE";
//Get MESSAGES List
export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_RECEIVE = "GET_MESSAGES_RECEIVE";