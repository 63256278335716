import * as TYPES from "./types";

// side menu links
export const sideMenuRequest = (payload) => ({
  type: TYPES.SIDE_MENU_REQUEST,
  payload,
});
export const sideMenuReceive = (payload) => ({
  type: TYPES.SIDE_MENU_RECEIVE,
  payload,
});

// statistics request
export const requestStatisticsRequest = (payload) => ({
  type: TYPES.REQUEST_STATISTICS_REQUEST,
  payload,
});
export const requestStatisticsReceive = (payload) => ({
  type: TYPES.REQUEST_STATISTICS_RECEIVE,
  payload,
});
