import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import moment from "moment";
import history from "../../routes/history";
import "./header.scss";
import { useDispatch, useSelector } from "react-redux";
import { oldNotificationsRequest, newNotificationsRequest, markAsReadedRequest } from "../../store/notifications/actions";

const Notifications = ({ intl: { messages }, show, close }) => {
    const dispatch = useDispatch();
    const lang = localStorage.getItem("lang") || "en";
    const {oldNotifications, newNotifications} = useSelector((state) => state?.notifications);
    const [notifications, setNotifications] = useState([])
    const roleId = parseInt(localStorage.getItem("role"));
    const user = useSelector((state) => state?.auth?.user?.data);

    useEffect(() => {
        if (user) {
            const payload = roleId === 7 || roleId === 8 ? {type: "agency"} : null
            // old notifications (READED)
            dispatch(oldNotificationsRequest(payload));
            // new notifications (UNREAD) 
            dispatch(newNotificationsRequest(payload));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, user]);

    // Concating old and new notifications
    useEffect(() => {
        if (oldNotifications && newNotifications) {
            setNotifications(newNotifications?.concat(oldNotifications));
        }
    }, [oldNotifications, newNotifications]);

    // Sort Notifications by date
    useEffect(() => {
        if (notifications) {
            setNotifications(notifications.sort((a, b) => {
                return moment(b.created_at).diff(moment(a.created_at));
            }));
        }
    }, [notifications]);

    const handleClickNote = (e, note) => {
        e.preventDefault();
        if (newNotifications.find((item) => item.id === note.id)) {
            dispatch(markAsReadedRequest({
                note_id: note.id, 
                type: roleId === 7 || roleId === 8 ? "agency" : null
            }));
        }
        if (note.data.type === "request") {
            history.push(`/newRequests`);
            close();
            // history.push(`/requests/${note.data.id}`)
        } else if (note.data.type === "task") {
            history.push(`/tasks`);
            close();
            // history.push(`/tasks/${note.data.id}`)
        } else if (note.data.type === "patient") {
            if (note.data.body_en.includes("have a note")) {
                history.push(`/patient-preview/${note.data.id}#patient-notes`);
            } else {
                history.push(`/patient-preview/${note.data.id}`);
            }
            close();
            // history.push(`/appointments/${note.data.id}`)
        } else {
            
        }
    }

    console.log(notifications);

    // test

    return (
        <div className={`notifications-modal_container ${show && " show"} ${lang === 'ar' && 'rtlPosition'}`}>
            <h4>{messages.header.notifications}</h4>
            <div className="notifications-modal_content">
                {notifications?.map(notification => (
                    <div key={notification?.id}
                         className={`notifications-modal_container_content_item ${lang === 'ar' && 'rtlFlex'}`}
                         onClick={(e) => handleClickNote(e, notification)}>
                        <div className="notifications-modal_container_content_item_redDot">
                            <span className={newNotifications?.find(note => note.id === notification?.id) ? 'show' : ''} >&nbsp;</span>
                        </div>
                        <div className="notifications-modal_container_content_item_date">
                            {moment(notification?.created_at).format('HH:mm')}
                            <span className="notifications-modal_container_content_item_date_full">
                                {moment(notification?.created_at).format('DD/MM/YYYY')}
                            </span>
                        </div>
                        <div className="notifications-modal_container_content_item_message"
                            style={ newNotifications?.find(note => note.id === notification?.id) ? { backgroundColor: '#EDFAEF' } :
                            notification?.data?.type === 'request' ? { backgroundColor: '#F6F7F7' } :
                            notification?.data?.type === 'task' ? { backgroundColor: '#FCF9E8' } : {} }>
                            {lang === 'ar' ? notification?.data?.body_ar : notification?.data?.body_en}
                        </div>
                    </div>
                ))}   
            </div>
        </div>
    );
};

export default injectIntl(Notifications);