import * as TYPES from "./types";

const initialAuthState = {
  token: null,
  showSuccessMsg: false,
  expirationDate: null,
  redirectToResetPopup: null,
  errormsg: null,
  blockedTime: null,
  reset_token: null,
  user: null,
  reset_password_success: null,
};

export default function authReducer(state = initialAuthState, action) {
  switch (action.type) {
    case TYPES.LOGIN_WITH_OTP_RECEIVE:
      return {
        ...state,
        expirationDate: action.payload,
      };
    case TYPES.OTP_LOGIN_RECEIVE:
      return {
        ...state,
        token: action.payload,
      };
    case TYPES.RESEND_OTP_RECEIVE:
      return {
        ...state,
        expirationDate: action.payload,
      };
    case TYPES.FORGET_PASSWORD_RECEIVE:
      return {
        ...state,
        reset_token: action.payload,
      };
    case TYPES.RESET_PASSWORD_RECEIVE:
      return {
        ...state,
        reset_password_success: action.payload,
      };
    case TYPES.GET_USER_RECEIVE:
      return {
        ...state,
        user: action.payload,
      };
    // Agencies
    case TYPES.AGENCIES_LOGIN_WITH_OTP_RECEIVE:
      return {
        ...state,
        expirationDate: action.payload,
      };
    case TYPES.AGENCIES_OTP_LOGIN_RECEIVE:
      return {
        ...state,
        token: action.payload,
      };
    case TYPES.AGENCIES_RESEND_OTP_RECEIVE:
      return {
        ...state,
        expirationDate: action.payload,
      };
    case TYPES.AGENCIES_FORGET_PASSWORD_RECEIVE:
      return {
        ...state,
        reset_token: action.payload,
      };
    case TYPES.AGENCIES_RESET_PASSWORD_RECEIVE:
      return {
        ...state,
        reset_password_success: action.payload,
      };
    case TYPES.AGENCIES_GET_USER_RECEIVE:
      return {
        ...state,
        user: action.payload,
      };
    case TYPES.AGENCIES_LOGOUT_RECEIVE:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}
