//Statistics
export const USERS_STATISTICS_REQUEST = "USERS_STATISTICS_REQUEST";
export const USERS_STATISTICS_RECEIVE = "USERS_STATISTICS_RECEIVE";
//Get Internal Users List
export const INTERNAL_USERS_REQUEST = "INTERNAL_USERS_REQUEST";
export const INTERNAL_USERS_RECEIVE = "INTERNAL_USERS_RECEIVE";
//Get User
export const USER_REQUEST = "USER_REQUEST";
export const USER_RECEIVE = "USER_RECEIVE";
//Add User
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_RECEIVE = "ADD_USER_RECEIVE";
//Edit User
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_RECEIVE = "EDIT_USER_RECEIVE";
//Delete User
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_RECEIVE = "DELETE_USER_RECEIVE";
//Block User
export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST";
export const BLOCK_USER_RECEIVE = "BLOCK_USER_RECEIVE";
//Unlock User
export const UNBLOCK_USER_REQUEST = "UNBLOCK_USER_REQUEST";
export const UNBLOCK_USER_RECEIVE = "UNBLOCK_USER_RECEIVE";
//Validate Email
export const VALIDATE_EMAIL_REQUEST = "VALIDATE_EMAIL_REQUEST";
export const VALIDATE_EMAIL_RECEIVE = "VALIDATE_EMAIL_RECEIVE";
//Validate Mobile
export const VALIDATE_MOBILE_REQUEST = "VALIDATE_MOBILE_REQUEST";
export const VALIDATE_MOBILE_RECEIVE = "VALIDATE_MOBILE_RECEIVE";
//Clean Errors
export const CLEAN_ERRORS = "CLEAN_ERRORS";
