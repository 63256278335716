import { axiosInstance } from "../../network";

// side menu links
export const sideMenuRequest = async (payload) => {
  if (payload && payload?.type === "agency") {
    return await axiosInstance.get("agency/v1/side-menu");
  } else {
    return await axiosInstance.get("system/v1/side-menu");
  }
};

// statistics
export const requestStatisticsRequest = async (payload) => {
  return await axiosInstance.get("system/v1/requests/statistics", { params: payload });
};
