import * as TYPES from "./types";

//Get Statistics
export const getRefsStatisticsRequest = () => ({
  type: TYPES.GET_REFS_STATISTICS_REQUEST,
});
export const getRefsStatisticsReceive = (payload) => ({
  type: TYPES.GET_REFS_STATISTICS_RECEIVE,
  payload,
});
//Get RefrenceLabs List for LabTests
export const getReferenceLabsListRequest = (payload) => ({
  type: TYPES.GET_REFERENCELABS_LIST_REQUEST,
  payload,
});

export const getReferenceLabsListReceive = (payload) => ({
  type: TYPES.GET_REFERENCELABS_LIST_RECEIVE,
  payload,
});
//Get ReferenceLabs List
export const getReferenceLabsRequest = (payload) => ({
  type: TYPES.GET_REFERENCELABS_REQUEST,
  payload,
});
export const getReferenceLabsReceive = (payload) => ({
  type: TYPES.GET_REFERENCELABS_RECEIVE,
  payload,
});
//Get ReferenceLab
export const getReferenceLabRequest = (payload) => ({
  type: TYPES.GET_REFERENCELAB_REQUEST,
  payload,
});
export const getReferenceLabReceive = (payload) => ({
  type: TYPES.GET_REFERENCELAB_RECEIVE,
  payload,
});
//Delete ReferenceLab
export const deleteReferenceLabRequest = (payload) => ({
  type: TYPES.DELETE_REFERENCELAB_REQUEST,
  payload,
});
export const deleteReferenceLabReceive = (payload) => ({
  type: TYPES.DELETE_REFERENCELAB_RECEIVE,
  payload,
});
//Active ReferenceLab
export const activeReferenceLabRequest = (payload) => ({
  type: TYPES.ACTIVE_REFERENCELAB_REQUEST,
  payload,
});
export const activeReferenceLabReceive = (payload) => ({
  type: TYPES.ACTIVE_REFERENCELAB_RECEIVE,
  payload,
});
//Desactive ReferenceLab
export const desactiveReferenceLabRequest = (payload) => ({
  type: TYPES.DESACTIVE_REFERENCELAB_REQUEST,
  payload,
});
export const desactiveReferenceLabReceive = (payload) => ({
  type: TYPES.DESACTIVE_REFERENCELAB_RECEIVE,
  payload,
});
//Add ReferenceLab
export const addReferenceLabRequest = (payload, history) => ({
  type: TYPES.ADD_REFERENCELAB_REQUEST,
  payload,
  history,
});
export const addReferenceLabReceive = (payload) => ({
  type: TYPES.ADD_REFERENCELAB_RECEIVE,
  payload,
});
//Edit ReferenceLab
export const editReferenceLabRequest = (payload, history) => ({
  type: TYPES.EDIT_REFERENCELAB_REQUEST,
  payload,
  history,
});
export const editReferenceLabReceive = (payload) => ({
  type: TYPES.EDIT_REFERENCELAB_RECEIVE,
  payload,
});
//Get Countries List
export const getCountriesRequest = () => ({
  type: TYPES.GET_COUNTRIES_LIST_REQUEST,
});
export const getCountriesReceive = (payload) => ({
  type: TYPES.GET_COUNTRIES_LIST_RECEIVE,
  payload,
});