import { axiosInstance } from "../../network";

//GET users List
export const getAppointmentsByUsersRequest = async (payload) => {
  return await axiosInstance.post('system/v1/appointments/available-list', payload);
}
//GET Appointments List
export const getAppointmentsListRequest = async (payload) => {
  return await axiosInstance.post('system/v1/appointments/list', payload);
}
//Get MY Appointments
export const getMyAppointmentsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/my-appointments', { params: payload });
}
//Get My Today Appointments
export const getMyTodayAppointmentsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/my-appointments', { params: payload });
}
//GET Appointments
export const getAppointmentsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/appointments', {params: payload});
}
//Get Upcomming Appointment
export const getUpcommingAppointmentRequest = async () => {
  return await axiosInstance.get('system/v1/appointments/upcoming');
}
// Get Receptionist Appointments List
export const getReceptionistAppointmentsListRequest = async (payload) => {
  return await axiosInstance.post('system/v1/appointments/calendar', payload);
}
//Get Appointment
export const getAppointmentRequest = async (payload) => {
  return await axiosInstance.get('system/v1/appointments/' + payload);
}
//Add Appointment
export const addAppointmentRequest = async (payload) => {
  return await axiosInstance.post('system/v1/appointments', payload);
}
//Edit Appointment
export const editAppointmentRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/appointments/${payload.id}`, payload);
}
//Delete Appointment
export const deleteAppointmentRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/appointments/${payload}`);
}
//Active Appointment
export const activeAppointmentRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/appointments/${payload}/active`);
}
//Change Status Appointment
export const changeStatusAppointmentRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/appointments/${payload.id}/change-status`, payload);
}
//Update Change Status Appointment
export const updateChangeStatusAppointmentRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/appointments/${payload.id}/change-reason`, payload);
}
//Send To Patient
export const sendToPatientRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload}/patient-lab-tests/send`);
}
//Get Appointment Reasons
export const getAppointmentReasonsRequest = async () => {
  return await axiosInstance.get('system/v1/appointment-reasons');
}
//Get Labtests List
export const getLabtestsListRequest = async (payload) => {
  return await axiosInstance.get('system/v1/lab-tests/list', { params: payload });
}
//Get Patient Labtests
export const getPatientLabtestsRequest = async (payload, patient_id) => {
  return await axiosInstance.get(`system/v1/patients/${patient_id}/patient-lab-tests`, { params: payload });
}
//Get Patient Labtests List
export const getPatientLabtestsListRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/patients/${payload}/patient-lab-tests/list`);
}
//Add Labtest
export const addAppointmentLabtestRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload.patient_id}/patient-lab-tests`, payload);
}
//Edit Labtest
export const editAppointmentLabtestRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/patients/${payload.patient_id}/patient-lab-tests/${payload.id}`, payload);
}
//Delete Labtest
export const deleteAppointmentLabtestRequest = async (payload, patient_id) => {
  return await axiosInstance.delete(`system/v1/patients/${patient_id}/patient-lab-tests/${payload}`);
}
//Appointments Statistics
export const getAppointmentsStatisticsRequest = async () => {
  return await axiosInstance.get('system/v1/appointments/my-statistics');
}
//Appointments Statistics Block
export const getAppointmentsStatisticsBlockRequest = async (payload) => {
  return await axiosInstance.get('system/v1/appointments/statistics', { params: payload });
}
//Appointments Users List
export const getAppointmentsUsersListRequest = async (payload) => {
  return await axiosInstance.get('system/v1/users/list', { params: payload });
}

