import { axiosInstance } from "../../network";

//Get Patient labtests List
export const getPaymentsListRequest = async (payload) => {
  return await axiosInstance.get('/system/v1/payments', { params: payload });
}
//Get Payments Statistics
export const getPaymentsStatisticsRequest = async (payload) => {
  return await axiosInstance.get('/system/v1/payments/statistics', payload);
}
//Accept Payment
export const acceptPaymentRequest = async (payload) => {
  return await axiosInstance.post(`/system/v1/payments/${payload}/accept`);
}
//Upload Payment Image
export const uploadPaymentImageRequest = async (payload, id) => {
  return await axiosInstance.post(`/system/v1/payments/${id}/pay`, payload);
}
//Get Payment Rejection Reasons
export const getPaymentRejectionReasonsRequest = async (payload) => {
  return await axiosInstance.get('/system/v1/payment-reasons');
}
//Reject Payment
export const rejectPaymentRequest = async (payload) => {
  return await axiosInstance.post(`/system/v1/payments/${payload.id}/reject`, payload);
}
//New Count
export const newCountRequest = async (payload) => {
  return await axiosInstance.get('/system/v1/payments/new-count', payload);
}
//Last Seen
export const lastSeenRequest = async () => {
  return await axiosInstance.post('/system/v1/payments/last-seen');
}
//Get Payment Methods
export const getPaymentMethodsRequest = async (payload) => {
  return await axiosInstance.get('/system/v1/offline-payment-methods', payload);
}
//Download Excel
export const downloadExcelRequest = async (payload) => {
  return await axiosInstance.get('/system/v1/payments/excel', { params: payload });
}
//Refund Payment
export const refundPaymentRequest = async (payload) => {
  return await axiosInstance.post(`/system/v1/payments/${payload}/refund`);
}
//Get Payment Details
