import { axiosInstance } from "../../network";

//GET Attachments List
export const getAttachmentsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/attachments', {params: payload});
}
//Get Attachment
export const getAttachmentRequest = async (payload) => {
  return await axiosInstance.get('system/v1/attachments/' + payload);
}
//Add Attachment
export const addAttachmentRequest = async (payload) => {
  return await axiosInstance.post('system/v1/attachments', payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
});
}
//Edit Attachment
export const editAttachmentRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/attachments/${payload.id}`, payload);
}
//Delete Attachment
export const deleteAttachmentRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/attachments/${payload}`);
}
//Downlaod Attachment
export const downloadAttachmentRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/attachments/${payload}/download`, {
    headers: {
      'Accept': "application/json"
    }
});
}
//Get Messages
export const getMessagesRequest = async (payload) => {
  return await axiosInstance.get('system/v1/messages', {params: payload});
}
