//Get Patient Labtests List
export const GET_PAYMENTS_LIST_REQUEST = "GET_PAYMENTS_LIST_REQUEST";
export const GET_PAYMENTS_LIST_RECEIVE = "GET_PAYMENTS_LIST_RECEIVE";
//Get Payments Statistics
export const GET_PAYMENTS_STATISTICS_REQUEST = "GET_PAYMENTS_STATISTICS_REQUEST";
export const GET_PAYMENTS_STATISTICS_RECEIVE = "GET_PAYMENTS_STATISTICS_RECEIVE";
//Accept Payment
export const ACCEPT_PAYMENT_REQUEST = "ACCEPT_PAYMENT_REQUEST";
export const ACCEPT_PAYMENT_RECEIVE = "ACCEPT_PAYMENT_RECEIVE";
//Upload Payment Image
export const UPLOAD_PAYMENT_IMAGE_REQUEST = "UPLOAD_PAYMENT_IMAGE_REQUEST";
export const UPLOAD_PAYMENT_IMAGE_RECEIVE = "UPLOAD_PAYMENT_IMAGE_RECEIVE";
//Get Payment Rejection Reasons
export const GET_PAYMENT_REJECTION_REASONS_REQUEST = "GET_PAYMENT_REJECTION_REASONS_REQUEST";
export const GET_PAYMENT_REJECTION_REASONS_RECEIVE = "GET_PAYMENT_REJECTION_REASONS_RECEIVE";
//Reject Payment
export const REJECT_PAYMENT_REQUEST = "REJECT_PAYMENT_REQUEST";
export const REJECT_PAYMENT_RECEIVE = "REJECT_PAYMENT_RECEIVE";
//New Count
export const NEW_COUNT_REQUEST = "NEW_COUNT_REQUEST";
export const NEW_COUNT_RECEIVE = "NEW_COUNT_RECEIVE";
//Last Seen
export const LAST_SEEN_REQUEST = "LAST_SEEN_REQUEST";
export const LAST_SEEN_RECEIVE = "LAST_SEEN_RECEIVE";
//Get Payment Methods
export const GET_PAYMENT_METHODS_REQUEST = "GET_PAYMENT_METHODS_REQUEST";
export const GET_PAYMENT_METHODS_RECEIVE = "GET_PAYMENT_METHODS_RECEIVE";
//Hide Payment Bullet
export const HIDE_PAYMENT_BULLET_REQUEST = "HIDE_PAYMENT_BULLET_REQUEST";
export const HIDE_PAYMENT_BULLET_RECEIVE = "HIDE_PAYMENT_BULLET_RECEIVE";
//Download Excel
export const DOWNLOAD_EXCEL_REQUEST = "DOWNLOAD_EXCEL_REQUEST";
export const DOWNLOAD_EXCEL_RECEIVE = "DOWNLOAD_EXCEL_RECEIVE";
//Refund Payment
export const REFUND_PAYMENT_REQUEST = "REFUND_PAYMENT_REQUEST";
export const REFUND_PAYMENT_RECEIVE = "REFUND_PAYMENT_RECEIVE";