import React from "react";
import "./Loader.scss";

const Loader = ({className}) => {
  return (
    <div className={`spinnerContainer d-flex justify-content-center align-items-center ${className}`}>
      <div className="spinner-border text-dark" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
