const en = {
  en: {
    hello: "Hello",
    lang: "عربى",
    common: {
      second: "Second",
      resend: "Resend",
      save: "Save",
      edit: "Edit",
      cancel: "Cancel",
      close: "Close",
      change: "Change",
      confirm: "Confirm",
      back: "Back",
      show: "Show",
      hide: "Hide",
      add_new_reason:"+ Add New Reason",
      all_days: "All Days",
      currency: 'SAR',
      show_more: "Show More",
      show_less: "Show Less",
    },
    home: {
      myTasks: "My Tasks",
      taskBtn: "Add a New Task",
      chart: {
        newTasks: "New",
        inProgress: "In Progress",
        late: "Late",
        completed: "Completed",
        completed_late: "Completed Late",
        tasks: "Tasks",
      },
      system_accounts: 'System Accounts',
      patients_list: 'Patients List',
      programs_subscription: 'Subscription programs',
      lab_tests: 'Lab Tests List',
      ref_labs: 'Reference Labs',
      myappointments: 'Today Appointments',
      tree: 'The Pedigree Tool',
    },
    auth: {
      logout: "Logout",
      login: "Login",
      agency_login: "Agency Login",
      forgetPassword: "Forget Password?",
      forgetPasswordFormHint: "Please enter your user name",
      resetPassword: "Reset Password",
      setPassword: "Set Password",
      otpTitle: "Verification code",
      otpContent: "Please enter verification code that sent to you in sms",
      send: "Send",
      resetPasswordSuccess: "Password has been reseted successfully",
      labels: {
        userName: "User name",
        password: "Password",
      },
      placeholders: {
        userName: "Please enter your user name",
        password: "Please enter your password",
        otp: "Please enter code",
      },
      errors: {
        required: "This field is required",
        credentialsError:
          "Wrong employee ID or password, please check your credentials.",
        userNameError:
          "User name should contains english letters and numbers or special characters",
        userNameLength: "User name must not be more than 50 characters",

        passwordPattern:
          "Password must contains letters, numbers & special characters",
        passwordLength: "Password must not be less than 8 characters",
        otp: "OTP consist of 4 numbers",
        confirmPassword: "Unmatched password",
      },
    },
    header: {
      hello: "Hello ",
      notifications: "Notifications",
      title: {
        allRequests: 'All Requests',
        newRequests: 'New requests',
        activeRequests: 'Active Requests',
        rejectedRequests: 'Rejected requests',
        tasksList: 'Tasks list',
        users: 'System Accounts',
        internal: 'Internal Users',
        add_user: 'New user',
        edit_user: 'Edit user',
        lab_tests: 'Test Labs List',
        add_lab: 'Add a new lab test',
        edit_lab: 'Edit lab test',
        reference_labs: 'The Reference Labs List',
        add_reference_lab: 'Add a new reference lab',
        edit_reference_lab: 'Edit reference lab',
        users_labs: 'Labs',
        add_users_lab: 'Add a new lab',
        edit_users_lab: 'Edit lab',
        users_hospitals: 'Hospitals',
        add_users_hospital: 'Add a new hospital',
        edit_users_hospital: 'Edit hospital',
        mypatients_list: 'Patients List',
        patients_list: 'Clients List',
        add_patient: 'Add Patient',
        edit_patient: 'Edit Patient Profile',
        patient_profile: 'Patient Profile',
        patient_preview: 'Patient Preview',
        canceled_appointments: 'Canceled Appointments',
        completed_appointments: 'Completed Appointments',
        appointment_statistics: 'The Specialists Appointments',
      }
    },
    statistics: {
      new: "New request",
      active: "Active request",
      completed: "Completed request",
      canceled: "Canceled request",
      rejected: "Rejected request",
      appointments: {
        new: "New Appointment",
        active: "Active Appointment",
        not_completed: "Not completed Appointment",
        completed: "Completed Appointment",
        canceled: "Canceled Appointment",
        missed: "Missed Appointments",
        obligations_sent: "Suggested Lab Tests Have Been Done",
        late: "Not Completed and Late",
        canceled_apps: "Canceled",
      },
      payments: {
        title: "Payments",
        info: "The required payments for the accepted obligations",
        late: "Late",
        waiting: "Waiting for Confirmation",
        confirmed: "Done",
        not_confirmed: "In Progress",
        rejected: "Rejected",
      },
      sampling: {
        title: "Sampling Reservation",
        sampling_title: "Sampling",
        late: "Late",
        done: "Done",
        in_progress: "In Progress",
      }
    },
    usersStatistics: {
      employee_vacations: "Employee vacations",
      employee_leaves: "Employee leaves",
    },
    profile: {
      header: "Personal info :",
      code: "Employee code: ",
      job: "Job",
      mobile: "Mobile",
      editInfo: "Edit info",
      cancelChangePasswordMSGHeader: "Cancel change password",
      cancelChangePasswordMSGContent:
        "Are you sure you want to cancel changing password",
    },
    formControl: {
      input: "Write here",
      select: "Select",
      autoCompelete: "Autocompelete",
      autoCompeleteMulti: "Autocompelete multiple",
      email: "Email",
      password: "New password",
      confirmPassword: "Confirm new password",
    },
    errors: {
      required: "This field is required",
      invalidEmail: "Invalid email",
      invalidPassword: "Invalid password",
      invalidMatchPassword: "Passwords don't match",
      imageTypeErr: "Image type is not supported jpg, jpeg and png only",
      imageSizeErr: "Image size is too large, maximum 1 Mb",
      imageSizeErr2: "Image size is too large, maximum 2 Mb",
      imageSizeErr3: "Image type not supported",
      noImage: "No image selected",
      specialist_have_not_started_yet: "The specialist have not started the appointment yet",
      specialist_error: "You have to start the appointment from the homepage",
    },
    buttons: {
      submit: "Submit",
      view: "View",
      details: "Details",
      ok: "Ok",
      back: "Back",
      confirm: "Confirm",
      reject: "Reject",
      yes: "Yes",
      no: "No",
      save: "Save",
      send: "Send",
      cancel: "Cancel",
      change: "Change",
      accept: "Accept",
      restore: "Restore",
    },
    pages: {
      dashboard: "Dasboard",
      forms: "Forms",
      tables: "Tables",
    },
    snackbar: {
      success: "Done successfully",
      sent: "The obligation's sent successfully",
    },
    branches: {
      name_en: "Branch name EN",
      name_ar: "Branch name AR",
      address: "Address",
      country: "Country",
      city: "City",
    },
    requests: {
      patient_name: "Patient Name",
      request_date: "Request Date",
      request_channel: "Request Channel",
      request_status: "Status ",
      request_reason: "Rejection Reason",
      cancel_reasons: "All Cancel Reasons",
      request: "Requests",
      search_by_phone_or_name_or_id_placholder: "Search By Name, Mobile, or ID",
      request_of: "Requests {total} from {totals}",
      all_sources: "All Request Sources",
      all: "All Request Sources",
      all_reasons: "All Rejection Reasons",

      name: "Name",
      phone: "Phone",
      text_msg: "Text Message",
      new_request_to_appointment: "New request to Book Appointment",
      activeRequests: "Active Requests",
      rejectedRequests: "Rejected Requests",

      request_confirmation: "The patient's request has been accepted successfully",
      request_confirmation_btn: "Patient profile",
      request_confirmed: "Confirmed requests",
      request_restored: "New requests",
      not_completed: "Not completed",
      request_confirmation_close: "Close",
      finish_validation_msg: "Are you sure you did not complete the customer data?",
      all_new_requests: "All New Requests",
      date: 'Date',
      patient_profile_open: "Open patient profile",
      rejection_reason: "Rejection reason",
      rejection_reason_placeholder: "Rejection reason",
      new_rejection_reason_placeholder: "Reason",
      new_rejection_reason_description: "Description",
      anther_reason: "Add another reason",
      validation_reason: "You should enter reason",
      reject_request:"Reject Request",

      stages: {
        all_stages: "All Request Stages",
        new_requests: "New Requests",
        not_completed: "Not Completed",
        active_requests: "Active Requests",
        appointment_reservation: "Appointment Reservation",
        interview: "Interview",
        no_interview: "No Interview",
        obligation_sending: "Obligation Sent",
        obligation_no_sent: "Obligation Not Sent",
        obligation_confirmation: "Obligation Confirmed",
        no_obligation_confirmation: "Obligation Not Confirmed",
        payment: "Paid",
        not_paid: "Not Paid",
        sampling_reservation: "Sampling Reservation",
        sampling_reserved: "Sampling Reservation",
        sampling_not_reserved: "Sample Not Reserved",
        sampling_deposited: "Sample Deposited",
        sampling_not_deposited: "Sample Not deposited",
        shipping: "Sample Shipping",
        sample_shipped: "Sample Shipped",
        sample_not_shipped: "Sample Not Shipped",
        result_explantation: "Result Explantation",
        delay_result_explantation: "Delay In Result Explantation",
        result_received: "Sending Result",
        result_not_received: "Result Not Received",
        registered_With_no_Request: "Registered With No Request",
        rejected: "Rejected",
        canceled: "Canceled",
        completed: 'Completed',
      },
      cancel_reason: "Cancel reason",
      add_cancel_reason: "Add a new cancel reason",
      cancel_reason_required: "Cancel reason is required",
    },
    tasks:{
      new_tasks:'New Tasks',
      inprogress_tasks:'In progress Tasks',
      completed_tasks:'Closed Tasks',
      add_new_task:'Add New Task',
      search_content: 'Search',
      coordinator: 'Coordinator',
      coordinatorsTasksTitle: 'Coordinators Tasks',
      form: {
        add_title: 'Add a New Task',
        edit_title: 'Edit task',
        view_title: 'Task Details',
        date: 'Due date',
        editing_reason: 'Editing reason',
        reason_placeholder: 'Please enter the reason of editing',
        reason_error: 'Please enter the reason of editing',
        date_placeholder: 'Select the required due date',
        date_error: 'Please choose the date greater than now',
        details: 'Details',
        details_placeholder: 'Enter the description of the task',
        details_error: 'Please enter task description',
        save: 'Save',
        edit: 'Update',
        finish: 'Finish Task',
        cancel: 'Cancel',
        success: 'Task added successfully',
        success_edit: 'Task updated successfully',
        success_finish: 'Task finished successfully',
        assignedBy: 'Assigned By',
        log: {
          creation_date: 'Creation date',
          in_progress: 'In progress',
          in_progress_late: 'In progress late',
          completed: 'Completed',
          completed_late: 'Completed late',
        }
      }
    },
    users: {
      users: 'Users',
      internal: "Internal User",
      factory: "Lab Test",
      hospital: "Hospital",
      users_of: "{total} User from {totals}",
      add_user: "Add a new user",
      add_lab: "Add a new lab",
      add_hospital: "Add a new hospital",
      edit_user: "Edit user",
      code: 'ID',
      user_name: 'User Name',
      position: 'Position',
      email: 'Email',
      mobile: 'Mobile',
      status: 'Status',
      actions: '',
      search: 'Search',
      admin: 'Admin',
      receptionist: 'Receptionist',
      coordinator: 'Coordinator',
      specialist: 'Specialist',
      accountant: 'Accountant',
      consultant: 'Consultant',
      position_placeholder: 'Position',
      delete_confirmation: 'Are you sure that you want to delete this user?',
      block_confirmation: 'Are you sure that you want to block this user?',
      form : {
        title: "Personal Data",
        code: 'Employee ID',
        code_placeholder: 'Please enter the Employee ID',
        en_name: 'English Name',
        en_name_placeholder: 'Please enter the English Name',
        ar_name: 'Arabic Name',
        ar_name_placeholder: 'Please enter the Arabic Name',
        position: 'Position',
        position_placeholder: 'Please select the Position',
        email: 'Email Address',
        email_placeholder: 'Please enter the Email Address',
        mobile: 'Mobile Number',
        mobile_placeholder: 'Please enter the mobile number',
        btn_save: 'Save',
        btn_next: 'Next',
        btn_back: 'Back',
        btn_cancel: 'Cancel',
        email_invalid: 'Invalid Email Address',
        phone_invalid: 'Invalid Mobile Number',
        scheduler_title: 'Work Schedule',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        time_from: 'From',
        time_to: 'To',
        all_fields_required: 'All fields are required',
        code_invalid: 'Invalid Employee ID',
        position_invalid: 'User Position is required',
        en_name_invalid: 'English Name is required',
        ar_name_invalid: 'Arabic Name is required',
        schedules_invalid: 'Invalid Schedule',
        schedule_invalid_2h: 'Working hours cannot be less than 2 hours',
        schedule_invalid_30m: 'Appointments hours cannot be less than 30 minutes',
        time_invalid: 'Invalid Time',
        time_apps_required: 'Appointments hours are required',
        schedule_invalid_section: 'Appointments hours must be between the working hours',
        working_hours_invalid: 'Invalid Working Hours',
        apps_hours_invalid: 'Invalid Appointments Hours',
        appointments: 'Appointments Schedule',
      }
    },
    usersLabs: {
      add_new: "Add a New Lab",
      edit_ref: "Edit Lab",
      refs_of: "{total} Lab from {totals}",
      ref_id: "Code",
      arabic_name: "Lab Arabic Name",
      english_name: "Lab English Name",
      city: "City",
      location: "Location",
      status: "Status",
      actions: "",
      desactive_confirmation: "Are you sure that you want to deactivate this lab?",
      search: "Search",
      showLink: "Show location",
      allcities: "All Cities",
      form : {
        title: "Lab Details",
        code: 'Code',
        code_placeholder: 'Please Enter The Lab Code',
        en_name: 'Lab English Name',
        en_name_placeholder: 'Please Enter The Lab English Name',
        ar_name: 'Lab Arabic Name',
        ar_name_placeholder: 'Please Enter The Lab Arabic Name',
        cities: 'City',
        cities_placeholder: 'Select',
        cities_error: 'Please Select The City',
        username: 'Username',
        username_placeholder: 'Please enter a username',
        google: 'Google maps Link',
        google_placeholder: 'Please enter the link',
        user_title: "Point of Contact Details",
        btn_save: 'Save',
        btn_cancel: 'Cancel',
        allRequired: 'All fields are required',
        position_placeholder: 'Please enter the position',
      }
    },
    usersHospitals: {
      add_new: "Add a New Hospital",
      edit_ref: "Edit Hospital",
      refs_of: "{total} Hospital from {totals}",
      ref_id: "Code",
      arabic_name: "Hospital Arabic Name",
      english_name: "Hospital English Name",
      city: "City",
      location: "Location",
      status: "Status",
      actions: "",
      desactive_confirmation: "Are you sure that you want to deactivate this hospital?",
      search: "Search",
      showLink: "Show location",
      allcities: "All Cities",
      form : {
        title: "Hospital Details",
        code: 'Code',
        code_placeholder: 'Please Enter The Hospital Code',
        en_name: 'Hospital English Name',
        en_name_placeholder: 'Please Enter The Hospital English Name',
        ar_name: 'Hospital Arabic Name',
        ar_name_placeholder: 'Please Enter The Hospital Arabic Name',
        cities: 'City',
        cities_placeholder: 'Select',
        cities_error: 'Please Select The City',
        username: 'Username',
        username_placeholder: 'Please enter a username',
        google: 'Google maps Link',
        google_placeholder: 'Please enter the link',
        user_title: "Point of Contact Details",
        btn_save: 'Save',
        btn_cancel: 'Cancel',
        allRequired: 'All fields are required',
        position_placeholder: 'Please enter the position',
      }
    },
    labTests: {
      add_new: "Add a New Lab Test",
      edit_lab: "Edit Lab Test",
      labs_of: "{total} Lab from {totals}",
      test_id: "Test ID",
      arabic_name: "Test Arabic Name",
      english_name: "Test English Name",
      ref_lab: "Reference Lab",
      price_before: "Price Before",
      price_after: "Price After",
      status: "Status",
      actions: "",
      desactive_confirmation: "Are you sure that you want to deactivate this lab test?",
      search: "Search",
      all_labs: "Select a Reference Lab",
      currency: "SR",
      delete_confirmation: "Are you sure that you want to delete this lab test?",
      result_due_days: "The delivery time from the sampling date",
      result_due_days_label: "Days",
      result_due_days_error: "The delivery time is required",
      form : {
        title: "Lab Test Details",
        code: 'Test ID',
        code_placeholder: 'Please Enter The Test ID',
        en_name: 'Test English Name',
        en_name_placeholder: 'Please Enter The Test English Name',
        ar_name: 'Test Arabic Name',
        ar_name_placeholder: 'Please Enter The Test Arabic Name',
        ref_labs: 'Reference Lab',
        ref_labs_placeholder: 'Select',
        blood: 'Sample Type in English',
        blood_placeholder: 'Blood Sample',
        blood_ar: 'Sample Type in Arabic',
        ammount: 'Sample Amount',
        ammount_placeholder: '3 to 5 ml',
        tube_type: "Tube type",
        tube_type_placeholder: "Select",
        tube_number: "Number of Tubes",
        price_before: "Price Before",
        price_before_placeholder: "Please Enter The Price in Saudi Riyal",
        price_after: "Price After",
        price_after_placeholder: "Please Enter The Price in Saudi Riyal",
        btn_save: 'Save',
        btn_cancel: 'Cancel',
        ref_lab_error: 'Please select a reference lab',
        blood_error: 'Please enter a sample type',
        ammount_error: 'Please enter a sample amount',
        tube_type_error: 'Please select a tube type',
        tube_number_error: 'Please enter a number of tubes',
        en_name_invalid: 'English Name is required',
        ar_name_invalid: 'Arabic Name is required',
        price_invalid: 'Price is required',
        price_error: 'The price after discount must be less than or equal to the initial price.',
        allRequired: 'All fields are required',
        obligation_type: 'Related Test Obligation',
        obligation_type_placeholder: 'Select',
        option1: 'Individual Obligation / Superior Obligation',
        option2: 'NIPT Obligation',
        option3: 'Chromosomal Obligation',
        obligation_type_error: 'Please select a related test',
        shipping_company: 'Shipping Company',
        shipping_company_placeholder: 'Select',
        company1: 'Aramex',
        company2: 'DHL',
        company3: 'Smsa',
        shipping_company_error: 'Please select the shipping company',
        location1: 'Agency location',
        location2: 'Genome location',
        location3: 'Refrence lab location',
      }
    },
    referenceLabs: {
      add_new: "Add a New Reference Lab",
      edit_ref: "Edit Reference Lab",
      refs_of: "{total} Reference from {totals}",
      ref_id: "Reference Lab ID",
      arabic_name: "Reference Lab Arabic Name",
      english_name: "Reference Lab English Name",
      country: "Country",
      city: "City",
      status: "Status",
      actions: "",
      desactive_confirmation: "Are you sure that you want to deactivate this reference lab?",
      search: "Search",
      all_labs: "All",
      currency: "SR",
      form : {
        title: "Reference Lab Details",
        code: 'Reference Lab ID',
        code_placeholder: 'Please Enter The Reference Lab ID',
        en_name: 'Reference Lab English Name',
        en_name_placeholder: 'Please Enter The Reference Lab English Name',
        ar_name: 'Reference Lab Arabic Name',
        ar_name_placeholder: 'Please Enter The Reference Lab Arabic Name',
        countries: 'Country',
        countries_placeholder: 'Select',
        city: 'City',
        city_placeholder: 'Please enter a city',
        google: 'Google maps Link',
        google_placeholder: 'Please enter the link',
        user_title: "Point of Contact Details",
        btn_save: 'Save',
        btn_cancel: 'Cancel',
        allRequired: 'All fields are required',
        position_placeholder: 'Please enter the position',
        en_name_invalid: 'English Name is required',
      }
    },
    patients: {
      add_new: "Add a New Client",
      edit_ref: "Edit Client",
      refs_of: "{total} Client from {totals}",
      client_name: "Patient Name",
      source_name: "Source Name",
      inscription_date: "Inscription Date",
      status: "Status",
      actions: "",
      desactive_confirmation: "Are you sure that you want to deactivate this client?",
      search: "Search",
      all_labs: "All",
      currency: "SR",
      stage: "Stage",
      not_saudi: "Non-Saudi",
      form : {
        title: "Patient Profile",
        step1: "Personal Data",
        step2: "Contact Data",
        step3: "Medical Appointment",
        personal_data: "Personal Data",
        family_history: "Family History",
        yes: 'Yes',
        no: 'No',
        patientId: 'ID Number',
        patientId_placeholder: 'Please Enter The ID Number',
        patientId_feedback: 'ID Number is required and must be > 10 digits',
        customer_type: 'Customer Type',
        healthy: 'Healthy',
        affected: 'Affected',
        prenatal: 'Prenatal',
        test_type: 'Test Type',
        premarital: 'Premarital',
        pregnancy: 'Post Pregnancy',
        individual: 'Individual',
        firstname: 'First Name',
        firstname_placeholder: 'Please Enter The First Name',
        en_name: 'English Full Name',
        en_name_placeholder: 'English Full Name',
        ar_name: 'Arabic Full Name',
        ar_name_placeholder: 'Arabic Full Name',
        father_link: "Father's Family Link",
        father_link_placeholder: 'Select',
        father_link_feedback: 'Please select a father link',
        mother_link: "Mother's Family Link",
        mother_link_placeholder: 'Select',
        mother_link_feedback: 'Please select a mother link',
        relationship: "Parents' Relationship",
        relationship_placeholder: 'Select',
        relationship_feedback: 'Please select a relationship',
        same_family: 'Same Family',
        different_family: 'Different Family',
        add_new_family: 'Add New Family Name',
        birthday: 'Date of Birth',
        birthday_feedback: 'Please Enter The Date of Birth',
        origin_city: 'Origin City',
        origin_city_placeholder: 'Select',
        origin_city_feedback: 'Please select a origin city',
        birth_city: 'Birth City',
        birth_city_placeholder: 'Select',
        birth_city_feedback: 'Please select a birth City',
        gender: "Gender",
        gender_placeholder: 'Select',
        gender_feedback: 'Please select a gender',
        male: 'Male',
        female: 'Female',
        marital_status: "Marital Status",
        marital_status_placeholder: 'select',
        marital_status_feedback: 'Plese select a marital status',
        single: 'Single',
        married: 'Married',
        widow: 'Widow/er',
        divorced: 'Divorced',
        request_channel: 'Request Channel',
        request_channel_placeholder: 'Select',
        request_channel_feedback: 'Please select a request channel',
        website: 'Website',
        phone_call: 'Phone Call',
        whatsapp: 'Whatsapp',
        facebook: 'Facebook',
        twitter: 'Twitter',
        instagram: 'Instagram',
        mobile_apps: 'Mobile Apps',
        dealing_type: 'Dealing Type',
        dealing_type_placeholder: 'Select',
        dealing_type_feedback: 'Please select a dealing type',
        online: 'Online',
        offline: 'Offline',
        subscription_programs: 'Subscription Programs',
        subscription_programs_placeholder: 'Select',
        subscription_programs_feedback: 'Please select a subscription program',
        general_program: 'General Program',
        healthy_family_program: 'Healthy Family Program',
        premarital_program: 'Premarital Program',
        family_wala_program: "Family Wala' Program",
        email: 'Email Address',
        email_placeholder: 'Please enter the Email Address',
        mobile: 'Mobile Number',
        mobile_placeholder: 'Please enter the mobile number',
        governorate: 'Governorate',
        governorate_placeholder: 'Select',
        governorate_feedback: 'Please select a governorate',
        city: 'Residential City',
        city_placeholder: 'Select',
        city_feedback: 'Please select a Residential City',
        address: 'The Address',
        address_placeholder: 'Please enter the address',
        contact_details_point: 'Points of Contacts Details',
        contact_nameen: 'English name',
        contact_nameen_placeholder: 'Please enter the english name',
        contact_namear: 'Arabic name',
        contact_namear_placeholder: 'Please enter the arabic name',
        relation: 'Relation',
        relation_placeholder: 'Please enter the relation',
        contact_reason: 'Reason of Contact',
        contact_reason_placeholder: 'Please enter the reason of contact',
        add_new_person: 'Add a New Person',
        specialist: "Specialist / Genetic Consultant",
        specialist_placeholder: 'Select',
        specialist_feedback: 'Please select a specialist',
        time_feedback: 'Please select a time',
        app_type: 'Appointment Type',
        app_type_placeholder: 'Select',
        app_type_feedback: 'Please select a appointment type',
        at_office: 'At the office',
        textual_app: 'Textual Appointment',
        video_call: 'Video Call',
        audio_call: 'Audio Call',
        app_status: 'Appointment Status',
        app_status_feedback: 'Please select an appointment status',
        app_status_placeholder: 'Select',
        first_app: 'First Appointment',
        inquiry_app: 'Inquiry Appointment',
        explantation_app: 'Result Explantation Appointment',
        completing_app: 'Completing Appointment',
        btn_save: 'Save',
        btn_cancel: 'Cancel',
        btn_link: 'Link Social',
        allRequired: 'All fields are required',
        position_placeholder: 'Please enter the position',
        en_name_invalid: 'English Name is required',
        messages_title: 'Messages List',
        view_profile: "View Profile",
        deleteContact: "Are you sure that you want to delete this contact?",
        patient_requests: "Patient Requests",
        canceled: "Canceled",
      },
      patient_requests: {
        add: "Add a request",
        request_date: "Date",
        request_message: "Request Message",
        request_status: "Request Status",
        request_stage: "Request Stage",
        details: "Details",
        cancel_request: "Cancel Request",
        noData: "No Requests Yet",
        request_details: "Request Details",
        date: "Date",
        message: "Message",
        notes: "Notes",
        status: "Status",
        stage: "Stage",
        cancel_reason: "Cancelling Reason",
        add_new_request: "Add New Request",
      },
      contacts: {
        title: 'Points of Contact',
        add: 'Add New',
        add_title: 'Add New Point of Contact',
        edit_title: 'Edit Point of Contact',
      },
      attachments: {
        title: 'General Attachments',
        filename: 'File Name',
        creation_date: 'Upload Date',
        created_by: 'Uploaded By',
        actions: 'Actions',
        btn: 'Add Attachment',
        delete_confirmation: 'Are you sure that you want to delete this attachment?',
      },
      appointments: {
        title: 'Medical Appointments',
        app_type: 'Appointment Type',
        specialist_name: 'Specialist Name',
        app_date: 'Appointment Date',
        app_status: 'Appointment Status',
        status: 'Status',
        actions: 'Actions',
        btn: 'Add Appointment',
        edit: 'Edit Appointment',
        confirmDelete: 'Are you sure you want to delete this appointment?',
        user_appointments: 'User Appointments',
        not_active: 'You can start the appointment on',
        return_to_patient: 'Return to patient',
      },
      labtests: {
        title: 'Labs List',
        btn: 'Add New Labtest',
        empty: 'No lab test has been suggested yet by the specialist',
        stage1: 'Submission',
        stage2: 'Confirmation',
        stage3: 'Appointment Booking',
        stage4: 'Medical Appointment',
        stage5: 'Sending Obligation',
        stage6: 'Obligation Confirmation',
        stage7: 'Payment',
        stage8: 'Sampling',
        stage9: 'Result Explantation',
        stage10: 'Sending Result',
        stage11: 'Shipping',
        accepted: 'Accepted',
        rejected: 'Not Accepted',
        paid: 'Paid',
        not_paid: 'Not Paid',
      },
      notes: {
        title: 'Notes section',
        btn: 'Add New Note',
        no_data: 'No notes yet',
      }
    },
    programs: {
      add_new: 'Add New Program',
      program_of: '{total} Program from {totals}',
      program_name: 'Program Name',
      status: 'Status',
      actions: '',
      delete_confirmation: 'Are you sure that you want to delete this program?',
      block_confirmation: 'Are you sure that you want to block this program?',
      save: "Save",
      cancel: "Cancel",
      en_name: "Program Name in English",
      ar_name: "Program Name in Arabic",
    },
    appointments: {
      off: "Appointments",
      app_of: "{total} Appointment from {totals}",
      allAppointments: 'All Appointments',
      newAppointments: 'New Appointments',
      activeAppointments: 'Active Appointments',
      patient_name: 'Patient Name',
      appointment_date: 'Appointment Date',
      appointment_status: 'Appointment Status',
      appointment_type: 'Appointment Type',
      actions: '',
      all_status: 'Appointment Status',
      all_types: 'Appointment Type',
      btn: 'Details',
      search: 'Search',
      next: 'Next Appointment',
      current: 'Current Appointment',
      no_next: 'No Next Appointment',
      app_tests: 'Suggested Lab Tests',
      app_test_name: 'Test Name',
      app_suggession: 'Recommendations',
      app_actions: 'Actions',
      app_add_test: 'Add a New Test',
      add_test: 'Add a New Test',
      edit_test: 'Edit Test',
      send_btn: 'Send To The Patient',
      no_data: 'There are no suggested lab tests so far',
      select: 'Select',
      select_test: 'Select a Test',
      suggession_recommendation: 'Suggested Recommendations',
      suggession_recommendation_placeholder: 'Please Enter the suggested recommendations',
      app_test_required: 'Please select a test',
      app_suggession_required: 'Suggested Recommendations is required',
      start_app: 'Start',
      start_conversation: 'Start',
      done: 'Done',
      cancel: 'Cancel',
      next_step: 'Next Step',
      next_step_placeholder: 'Select',
      remarks: 'Notes',
      remarks_placeholder: 'Notes',
      save: 'Add',
      edit: 'Edit',
      copied: 'Link Copied To Clipboard',
      reason: 'Reason',
      new_reason: 'Add another reason (This text will be sent to the patient)',
      reason_placeholder: 'Please enter the reason',
      genome_office: 'Genome Office',
      noMessages: 'No Messages',
      noAppointments: 'No Reserved Appointments Yet',
      noContacts: 'No Points of Contacts Details',
      noAttachments: 'No Files Attached',
      notCompletedAppointments: 'Not Completed Appointments',
      all_reasons: 'All Cancellation Reasons',
      hold_reasons: 'On-Hold Reason',
      hold: 'On-Hold',
      specialistAppointments: 'Specialists Appointments',
      allSpecialist: 'Genetic Specialist / Consultant',
      allSpecialist_placeholder: 'All Genetic Specialist/Consultant',
      refresh: 'Clear',
      statistics: {
        allAppointments: 'All Appointments',
        newAppointment: 'New Appointment',
        notCompletedAppointment: 'Not Completed Appointment',
        notCompletedAppointmentLate: 'Not Completed and Late',
        completedAppointment: 'Completed Appointment',
        cancelledAppointment: 'Cancelled Appointment',
        missedAppointment: 'Missed Appointment',
      },
      cancel_feedback: "An active request at the medical interview stage",
      cancel_feedback_part1: "An active request at the",
      cancel_feedback_part2: "stage",
      cancel_btn: "Cancel Request",
      cancel_title: 'Cancel Request',
      request_status: "Request Stage",
      medical_interview: "Medical Interview",
      canceling_reason: 'Notes',
      canceling_reason_placeholder: "Please enter the notes",
      show_recording: 'Play Recording',
      specialist_appointments: "Specialists Appointments",
      medical_appointments: "Medical Appointments",
      date: "Date",
      app_status: 'Appointment Status',
      app_type: 'Appointment Type',
      status: 'Status',
      appointment_user: 'Specialist / Consultant',
      title_hover: "The first, inquiry, & completing Appointments",
    },
    obligations: {
      title: 'Obligation Acceptance',
      infos: 'The suggested lab tests that have been sent to the patients',
      app_off: "Obligations",
      status: 'Status',
      labtest: 'Lab Test',
      sent: 'Obligation Sent',
      not_sent: 'Obligation Not Sent',
      accepted: 'Obligation Accepted',
      not_accepted: 'Obligation Not Accepted',
      patient_name: 'Patient Name',
      obligation_date: 'Delivery Date',
      labtest_name: 'Lab Test Name',
      price: 'Price',
      chart: {
        late: 'Late',
        in_progress: 'In progress',
        done: 'Done',
      }
    },
    payments: {
      payments_title: 'The Payments',
      payment_of: '{total} Payment from {totals}',
      app_off: "Payments",
      canceled: "Cancelled",
      pending: "Waiting for Confirmation",
      not_payed: "Not Paid",
      payments: "Paid",
      accepted_obligations: "Approved Obligations",
      patient_name: "Client Name",
      request_date: "Approval Date",
      labtest_test: "Lab Test",
      price: "Price",
      payment_method: "Payment Method",
      transfert_number: "Image / Transaction ID",
      status: "Status",
      notes: "Notes",
      bank_transfert_image: "Attach The Transaction Image",
      transfert_image: "Transfert Image",
      wating_for_confirmation: "Waiting for Confirmation",
      currency: "SAR",
      confirm: "Confirm",
      reject: "Reject",
      rejection_reason: "Rejection Reason",
      search: "Search by Name, Mobile, ID",
      date: "Date",
      payment_operation: "Payment Method",
      bank_transfert: "Bank Transfer",
      online_payment: "Online Payment",
      payment_done: "Payment is Done",
      bank_transfer_receipt: "Bank Transfer Receipt",
      bank_transfer_placeholder: "Please attach the transfer receipt",
      new_offline_payment: "Payment Method",
      new_offline_payment_placeholder: "Select",
      add_offline_payment: "Add another Method",
      online_payment_data: "Online Payment Information",
      finish: "Expiration",
      preview_invoice: "Preview Invoice",
      refund: "Refund",
      excel_download: "Download Excel",
      refund_confirmation: "Are you sure that you want to refund this payment?",
      status_filter: {
        all: "Payment Status",
        not_paid: "Not paid",
        not_paid_with_delay: "Not paid with delay",
        wating: "Waiting for Confirmation",
        pending: "Rejected",
        refunded: "Refunded",
        canceled: "Canceled",
        paid: "Paid",
      },
      method_filter: {
        all: "Payment Method",
        bank_transfert: "Bank Transfer",
        online: "Online Payment",
        visa: "Visa - Master Card",
        apple_pay: "Apple Pay",
        mada: "Mada",
        stc: "STC",
      } 
    },
    sampling: {
      title: 'Samples',
      app_off: "Labtest",
      set_sampling_date: 'Sampling Location',
      sampling_done: 'Sample is Deposited',
      visit_lab: 'Visit the Lab',
      sampling_region: 'Governorate',
      sampling_region_placeholder: 'Select',
      sampling_region_error: 'There is no sample collection point in your area',
      sampling_city: 'City',
      sampling_city_placeholder: 'Select',
      sampling_city_placeholder_error: 'No results found',
      sampling_city_error: 'Your city does not have a sample collection point',
      nearest_sampling_point: 'Nearest Sampling Point',
      nearest_sampling_point_placeholder: 'Select',
      sampling_date_title: 'Date of Sampling',
      sampling_date: "Date",
      sampling_date_placeholder: "Please enter your appropriate date",
      sampling_time: "Time",
      notes: "Notes",
      labSchudle: "Preview the working hours",
      working_hours_title: "Working Hours",
      working_hours_subtitle: "(Working hours may vary on special occasions and holidays)",
      back_btn: "Back",
      holiday: "Holiday",
      patient: "Patient Name",
      date: "Sampling Date",
      labtest: "Lab Test Name",
      agency: "Collection Agency",
      status: "Status",
      samplingStatus: {
        all: "Sampling Status",
        in_progress: "In Progress",
        late: "Late",
        done: "Done",
      }
    },
    shipping: {
      shipping_details: "Shipping Details",
      shipping_title: 'Shipping Data',
      shipping_done: 'Sample Shipped',
      shipping_not_done: 'Sample not Shipped',
      shipping_destination_from: 'Shipping Destination From',
      shipping_destination_from_placeholder: 'Select',
      shipping_destination_to: 'To',
      shipping_destination_to_placeholder: 'Select',
      first_journey: 'The First Journey',
      second_journey: 'The Second Journey',
      add_new_destination: 'Add a New Destination',
      add_new_arrival: 'Add a New Arrival',
    },
    agencies: {
      agency_of: '{total} Sample from {totals}',
      app_off: "Samples",
      patient_name: "Patient Name",
      appointment_date: "Sampling Date",
      sample_type: "Sample Type",
      sample_quantity: "Sample Amount",
      tube_type: "Tube Type",
      tubes_count:  "Number of Tubes",
      status: "Status",
      action: "Actions",
      shipping: "Shipping Company",
      form: {
        title: "Sampling Status",
        deposited: "Deposited",
        not_deposited: "Not Deposited",
        notes: "Notes",
        notes_placeholder: "Please enter the notes",
        confirm: "Confirm",
        cancel: "Cancel",
      }
    },
    reports: {
      requests: {
        title: "Clients Requests Report",
      }
    },
    warningMsgs:{
      warning_close_window: "Are you sure that you want to close the window without saving changes?",
      warning_delete: "Are you sure that you want to delete this task?",
      warning_finish: "Are you sure that you want to finish this task?",
      cancel: "Cancel",
      confirm: "Confirm",
    },
    noData: {
      no_data: "No data",
    },
    shared: {
      morning: "AM",
      evening: "PM",
      from: "From",
      to: "To",
      copyright: '© All rights reserved - Genome',
    },
  },
};
export default en
