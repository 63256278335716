import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = window.innerWidth > 820 ? 240 : 165;
//const drawerWidth =  '20%';
//const contentWidth = '80%';
export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 5,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  content: {
    padding: "24px 24px 0",
    width: `calc(100% - ${drawerWidth}px)`,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));
