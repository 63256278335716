import { axiosInstance } from "../../network";

//Get programs statistics
export const getProgramsStatisticsRequest = async () => {
  return await axiosInstance.get('system/v1/programs/statistics');
}
//GET Programs
export const getProgramsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/programs', {params: payload});
}
//GET Programs List
export const getProgramsListRequest = async (payload) => {
  return await axiosInstance.get('system/v1/programs/list', {params: payload});
}
//Add Program
export const addProgramRequest = async (payload) => {
  return await axiosInstance.post('system/v1/programs', payload);
}
//Edit Program
export const editProgramRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/programs/${payload.id}`, {name: {
    en: payload.name.en,
    ar: payload.name.ar,
  }});
}
//Delete Program
export const deleteProgramRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/programs/${payload}`);
}
//Active Program
export const activeProgramRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/programs/${payload}/active`);
}
//Desactive Program
export const desactiveProgramRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/programs/${payload}/deactive`);
}
