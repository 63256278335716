import * as TYPES from "./types";

//Statistics
export const usersStatisticsRequest = () => ({
  type: TYPES.USERS_STATISTICS_REQUEST,
});
export const usersStatisticsReceive = (payload) => ({
  type: TYPES.USERS_STATISTICS_RECEIVE,
  payload,
});
//Get Internal Users List
export const internalUsersRequest = (payload) => ({
  type: TYPES.INTERNAL_USERS_REQUEST,
  payload,
});
export const internalUsersReceive = (payload) => ({
  type: TYPES.INTERNAL_USERS_RECEIVE,
  payload,
});
//Get User
export const userRequest = (id) => ({
  type: TYPES.USER_REQUEST,
  id,
});
export const userReceive = (payload) => ({
  type: TYPES.USER_RECEIVE,
  payload,
});
//Add User
export const addUserRequest = (payload, history) => ({
  type: TYPES.ADD_USER_REQUEST,
  payload,
  history,
});
export const addUserReceive = (payload) => ({
  type: TYPES.ADD_USER_RECEIVE,
  payload,
});
//Edit User
export const editUserRequest = (payload, history) => ({
  type: TYPES.EDIT_USER_REQUEST,
  payload,
  history,
});
export const editUserReceive = (payload) => ({
  type: TYPES.EDIT_USER_RECEIVE,
  payload,
});
//Delete User
export const deleteUserRequest = (payload) => ({
  type: TYPES.DELETE_USER_REQUEST,
  payload,
});
export const deleteUserReceive = (payload) => ({
  type: TYPES.DELETE_USER_RECEIVE,
  payload,
});
//Block User
export const blockUserRequest = (payload) => ({
  type: TYPES.BLOCK_USER_REQUEST,
  payload,
});
export const blockUserReceive = (payload) => ({
  type: TYPES.BLOCK_USER_RECEIVE,
  payload,
});
//Unblock User
export const unblockUserRequest = (payload) => ({
  type: TYPES.UNBLOCK_USER_REQUEST,
  payload,
});
export const unblockUserReceive = (payload) => ({
  type: TYPES.UNBLOCK_USER_RECEIVE,
  payload,
});
// Validate Email
export const validateEmailRequest = (payload) => ({
  type: TYPES.VALIDATE_EMAIL_REQUEST,
  payload,
});
export const validateEmailReceive = (payload) => ({
  type: TYPES.VALIDATE_EMAIL_RECEIVE,
  payload,
});
// Validate Mobile
export const validateMobileRequest = (payload) => ({
  type: TYPES.VALIDATE_MOBILE_REQUEST,
  payload,
});
export const validateMobileReceive = (payload) => ({
  type: TYPES.VALIDATE_MOBILE_RECEIVE,
  payload,
});
//Clean Errors
export const cleanErrors = () => ({
  type: TYPES.CLEAN_ERRORS,
});