import * as TYPES from "./types";

//Get UsersLabs List
export const getUsersLabsRequest = (payload) => ({
  type: TYPES.GET_USERSLABS_REQUEST,
  payload,
});
export const getUsersLabsReceive = (payload) => ({
  type: TYPES.GET_USERSLABS_RECEIVE,
  payload,
});
//Get UsersLab
export const getUsersLabRequest = (payload) => ({
  type: TYPES.GET_USERSLAB_REQUEST,
  payload,
});
export const getUsersLabReceive = (payload) => ({
  type: TYPES.GET_USERSLAB_RECEIVE,
  payload,
});
//Delete UsersLab
export const deleteUsersLabRequest = (payload) => ({
  type: TYPES.DELETE_USERSLAB_REQUEST,
  payload,
});
export const deleteUsersLabReceive = (payload) => ({
  type: TYPES.DELETE_USERSLAB_RECEIVE,
  payload,
});
//Active UsersLab
export const activeUsersLabRequest = (payload) => ({
  type: TYPES.ACTIVE_USERSLAB_REQUEST,
  payload,
});
export const activeUsersLabReceive = (payload) => ({
  type: TYPES.ACTIVE_USERSLAB_RECEIVE,
  payload,
});
//Desactive UsersLab
export const desactiveUsersLabRequest = (payload) => ({
  type: TYPES.DESACTIVE_USERSLAB_REQUEST,
  payload,
});
export const desactiveUsersLabReceive = (payload) => ({
  type: TYPES.DESACTIVE_USERSLAB_RECEIVE,
  payload,
});
//Add UsersLab
export const addUsersLabRequest = (payload, history) => ({
  type: TYPES.ADD_USERSLAB_REQUEST,
  payload,
  history,
});
export const addUsersLabReceive = (payload) => ({
  type: TYPES.ADD_USERSLAB_RECEIVE,
  payload,
});
//Edit UsersLab
export const editUsersLabRequest = (payload, history) => ({
  type: TYPES.EDIT_USERSLAB_REQUEST,
  payload,
  history,
});
export const editUsersLabReceive = (payload) => ({
  type: TYPES.EDIT_USERSLAB_RECEIVE,
  payload,
});
//Get Cities
export const getCitiesRequest = () => ({
  type: TYPES.GET_CITIES_REQUEST,
});
export const getCitiesReceive = (payload) => ({
  type: TYPES.GET_CITIES_RECEIVE,
  payload,
});
//Get Regions
export const getRegionsRequest = () => ({
  type: TYPES.GET_REGIONS_REQUEST,
});
export const getRegionsReceive = (payload) => ({
  type: TYPES.GET_REGIONS_RECEIVE,
  payload,
});
//Get cities by region
export const getCitiesByRegionRequest = (payload, query) => ({
  type: TYPES.GET_CITIES_BY_REGION_REQUEST,
  payload,
  query,
});
export const getCitiesByRegionReceive = (payload) => ({
  type: TYPES.GET_CITIES_BY_REGION_RECEIVE,
  payload,
});
//Get Agencies List
export const getAgenciesListRequest = (payload) => ({
  type: TYPES.GET_AGENCIES_LIST_REQUEST,
  payload,
});
export const getAgenciesListReceive = (payload) => ({
  type: TYPES.GET_AGENCIES_LIST_RECEIVE,
  payload,
});
//Check City Agency
export const checkCityAgencyRequest = (payload) => ({
  type: TYPES.CHECK_CITY_AGENCY_REQUEST,
  payload,
});
export const checkCityAgencyReceive = (payload) => ({
  type: TYPES.CHECK_CITY_AGENCY_RECEIVE,
  payload,
});
