import { axiosInstance } from "../../network";

//GET Coordinator statistics
export const getCoordinatorStatisticsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/statistics/appointments', {params: payload});
}
//GET Obligations statistics
export const getObligationsStatisticsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/statistics/patient-lab-tests', {params: payload});
}
//GET Payments statistics
export const getCoordinatorPaymentsStatisticsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/statistics/payments', {params: payload});
}
//GET Sampling statistics
export const getCoordinatorSamplingStatisticsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/statistics/sampling-reservations', {params: payload});
}
//GET Sampling Data statistics
export const getCoordinatorSamplingDataStatisticsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/statistics/samples', {params: payload});
}
//GET Coordinator Tasks statistics
export const getCoordinatorTasksStatisticsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/statistics/tasks', {params: payload});
}