import * as TYPES from "./types";

const initialState = {
  programs: [],
  programsList: [],
  programsStatistics: {},
};

export default function programsReducer(state = initialState, action) {
  switch (action.type) {
    //Statistics
    case TYPES.GET_PROGRAMS_STATISTICS_RECEIVE: {
      return {
        ...state,
        programsStatistics: action.payload.statistics,
      }
    }
    case TYPES.GET_PROGRAMS_RECEIVE: {
      return {
        ...state,
        programs: action.payload,
      }
    }
    case TYPES.DELETE_PROGRAM_RECEIVE: {
      return {
        ...state,
        programs: { ...state.programs, data: state.programs?.data?.filter((user) => user.id !== action.payload) }
      }
    }
    case TYPES.ACTIVE_PROGRAM_RECEIVE: {
      return {
        ...state,
        programs: { ...state.programs, data: state.programs?.data?.map((program) => {
          if (program.id === action.payload) {
            return { ...program, is_active: 1 }
          }
          return program
        }) }
      }
    }
    case TYPES.DESACTIVE_PROGRAM_RECEIVE: {
      return {
        ...state,
        programs: { ...state.programs, data: state.programs?.data?.map((program) => {
          if (program.id === action.payload) {
            return { ...program, is_active: 0 }
          }
          return program
        }) }
      }
    }
    case TYPES.GET_PROGRAMS_LIST_RECEIVE: {
      return {
        ...state,
        programsList: action.payload?.data,
      }
    }
    default:
      return state
  }
}
