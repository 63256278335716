import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Get Statistics
export function* getRefsStatisticsSaga() {
  try {
    const { data } = yield call(APIS.getRefsStatisticsRequest);
    yield put(ACTIONS.getRefsStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
// Get Refrence Labs List for LabTests
export function* getReferenceLabsListSaga({ payload }) {
  try {
    const { data } = yield call(APIS.getReferenceLabsListRequest, payload);
    yield put(ACTIONS.getReferenceLabsListReceive(data));
  } catch (err) {
    console.log(err.response?.data);
  }
}

//Get ReferenceLabs List
export function* getReferenceLabsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getReferenceLabsRequest, payload);
    yield put(ACTIONS.getReferenceLabsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get ReferenceLab
export function* getReferenceLabSaga({payload}) {
  try {
    const { data } = yield call(APIS.getReferenceLabRequest, payload);
    yield put(ACTIONS.getReferenceLabReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add ReferenceLab
export function* addReferenceLabSaga({payload, history}) {
  try {
    yield call(APIS.addReferenceLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getReferenceLabsRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    history.push("/reference-labs");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit ReferenceLab
export function* editReferenceLabSaga({payload, history}) {
  try {
    yield call(APIS.editReferenceLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getReferenceLabsRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    history.push("/reference-labs");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete ReferenceLab
export function* deleteReferenceLabSaga({payload}) {
  try {
    yield call(APIS.deleteReferenceLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteReferenceLabReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Active ReferenceLab
export function* activeReferenceLabSaga({payload}) {
  try {
    yield call(APIS.activeReferenceLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.activeReferenceLabReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Desactive ReferenceLab
export function* desactiveReferenceLabSaga({payload}) {
  try {
    yield call(APIS.desactiveReferenceLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.desactiveReferenceLabReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Countries List
export function* getCountriesSaga() {
  try {
    const { data } = yield call(APIS.getCountriesRequest);
    yield put(ACTIONS.getCountriesReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* referenceLabsSagas() {
  yield takeLatest(TYPES.GET_REFS_STATISTICS_REQUEST, getRefsStatisticsSaga);
  yield takeLatest(TYPES.GET_REFERENCELABS_LIST_REQUEST, getReferenceLabsListSaga);
  yield takeLatest(TYPES.GET_REFERENCELABS_REQUEST, getReferenceLabsSaga);
  yield takeLatest(TYPES.GET_REFERENCELAB_REQUEST, getReferenceLabSaga);
  yield takeLatest(TYPES.DELETE_REFERENCELAB_REQUEST, deleteReferenceLabSaga);
  yield takeLatest(TYPES.ACTIVE_REFERENCELAB_REQUEST, activeReferenceLabSaga);
  yield takeLatest(TYPES.DESACTIVE_REFERENCELAB_REQUEST, desactiveReferenceLabSaga);
  yield takeLatest(TYPES.ADD_REFERENCELAB_REQUEST, addReferenceLabSaga);
  yield takeLatest(TYPES.EDIT_REFERENCELAB_REQUEST, editReferenceLabSaga);
  yield takeLatest(TYPES.GET_COUNTRIES_LIST_REQUEST, getCountriesSaga);
}


