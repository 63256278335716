import { axiosInstance } from "../../network";

//Get Patients Statistics
export const getPatientsStatisticsRequest = async () => {
  return await axiosInstance.get('system/v1/patients/count');
}
//GET Patients List
export const getPatientsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/patients', {params: payload});
}
//Get My Patients
export const getMyPatientsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/my-patients', {params: payload});
}
//Get Patient
export const getPatientRequest = async (payload) => {
  return await axiosInstance.get('system/v1/patients/' + payload);
}
//Add Patient
export const addPatientRequest = async (payload) => {
  return await axiosInstance.post('system/v1/patients', payload);
}
//Edit Patient
export const editPatientRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/patients/${payload.id}`, payload);
}
//Delete Patient
export const deletePatientRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/patients/${payload}`);
}
//Active Patient
export const activePatientRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload}/active`);
}
//Desactive Patient
export const desactivePatientRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload}/deactive`);
}
//Get Families
export const getFamiliesRequest = async () => {
  return await axiosInstance.get('system/v1/families');
}
//Add Family
export const addFamilyRequest = async (payload) => {
  return await axiosInstance.post('system/v1/validate/family-name', {name: payload});
}
//Valid ID
export const validIdRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload.patient_id}/validate/identification-number`, { identification_number: payload.identification_number } );
}
//Patient ValidID
export const patientValidIdRequest = async (payload) => {
  return await axiosInstance.post('patient/v1/validate/identification-number', payload);
}
//Sampling reservation
export const samplingReservationRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patient-lab-tests/${payload.labtestID}/sampling-reservations`, payload);
}
//Update Sampling reservation
export const updateSamplingReservationRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/patient-lab-tests/${payload.labtestID}/sampling-reservations/${payload.id && payload.id}`, payload);
}
//Sampling Force Deposited
export const samplingForceDepositedRequest = async (payload) => {
  return await axiosInstance.post('system/v1/patient-lab-tests/' + payload.id + '/force-deposited');
}
//{{base_url}}/system/v1/patient-lab-tests/{{patient_lab_test_id}}/force-deposited

//Get Patient Notes
export const getPatientNotesRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/patients/${payload.patientId}/notes`, { params: { page: payload.page} });
}
//Add Patient Notes
export const addPatientNotesRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload.patient_id}/notes`, payload);
}
