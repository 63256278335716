import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Get Programs Statistics
export function* getProgramsStatisticsSaga() {
  try {
    const { data } = yield call(APIS.getProgramsStatisticsRequest);
    yield put(ACTIONS.getProgramsStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Programs List
export function* getProgramsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getProgramsRequest, payload);
    yield put(ACTIONS.getProgramsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Programs List
export function* getProgramsListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getProgramsListRequest, payload);
    yield put(ACTIONS.getProgramsListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add Program
export function* addProgramSaga({payload}) {
  try {
    yield call(APIS.addProgramRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getProgramsRequest({
      page: 1,
      sort: "created_at,desc",
    }));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit Program
export function* editProgramSaga({payload}) {
  try {
    yield call(APIS.editProgramRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getProgramsRequest({
      page: 1,
      sort: "created_at,desc",
    }));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete Program
export function* deleteProgramSaga({payload}) {
  try {
    yield call(APIS.deleteProgramRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteProgramReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Active Program
export function* activeProgramSaga({payload}) {
  try {
    yield call(APIS.activeProgramRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.activeProgramReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Desactive Program
export function* desactiveProgramSaga({payload}) {
  try {
    yield call(APIS.desactiveProgramRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.desactiveProgramReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function*  programsSagas() {
  yield takeLatest(TYPES.GET_PROGRAMS_STATISTICS_REQUEST, getProgramsStatisticsSaga);
  yield takeLatest(TYPES.GET_PROGRAMS_REQUEST, getProgramsSaga);
  yield takeLatest(TYPES.DELETE_PROGRAM_REQUEST, deleteProgramSaga);
  yield takeLatest(TYPES.ACTIVE_PROGRAM_REQUEST, activeProgramSaga);
  yield takeLatest(TYPES.DESACTIVE_PROGRAM_REQUEST, desactiveProgramSaga);
  yield takeLatest(TYPES.ADD_PROGRAM_REQUEST, addProgramSaga);
  yield takeLatest(TYPES.EDIT_PROGRAM_REQUEST, editProgramSaga);
  yield takeLatest(TYPES.GET_PROGRAMS_LIST_REQUEST, getProgramsListSaga);
}


