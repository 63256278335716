import * as TYPES from "./types";

//Get Agency Samples List
export const getAgencySamplesListRequest = (payload) => ({
  type: TYPES.GET_AGENCY_SAMPLES_LIST_REQUEST,
  payload,
});
export const getAgencySamplesListReceive = (payload) => ({
  type: TYPES.GET_AGENCY_SAMPLES_LIST_RECEIVE,
  payload,
});
//Change Status
export const agenciesChangeStatusRequest = (payload) => ({
  type: TYPES.AGENCIES_CHANGE_STATUS_REQUEST,
  payload,
});
export const agenciesChangeStatusReceive = (payload) => ({
  type: TYPES.AGENCIES_CHANGE_STATUS_RECEIVE,
  payload,
});