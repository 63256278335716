import * as TYPES from "./types";

//Get Patient Labtests List
export const getPaymentsListRequest = (payload) => ({
  type: TYPES.GET_PAYMENTS_LIST_REQUEST,
  payload,
});
export const getPaymentsListReceive = (payload) => ({
  type: TYPES.GET_PAYMENTS_LIST_RECEIVE,
  payload,
});

//Get Payments Statistics
export const getPaymentsStatisticsRequest = (payload) => ({
  type: TYPES.GET_PAYMENTS_STATISTICS_REQUEST,
  payload,
});
export const getPaymentsStatisticsReceive = (payload) => ({
  type: TYPES.GET_PAYMENTS_STATISTICS_RECEIVE,
  payload,
});
//Accept payment
export const acceptPaymentRequest = (payload) => ({
  type: TYPES.ACCEPT_PAYMENT_REQUEST,
  payload,
});
export const acceptPaymentReceive = (payload) => ({
  type: TYPES.ACCEPT_PAYMENT_RECEIVE,
  payload,
});
//Upload Payment Image
export const uploadPaymentImageRequest = (payload, id) => ({
  type: TYPES.UPLOAD_PAYMENT_IMAGE_REQUEST,
  payload,
  id
});
export const uploadPaymentImageReceive = (payload) => ({
  type: TYPES.UPLOAD_PAYMENT_IMAGE_RECEIVE,
  payload,
});
//Get Payment Rejection Reasons
export const getPaymentRejectionReasonsRequest = (payload) => ({
  type: TYPES.GET_PAYMENT_REJECTION_REASONS_REQUEST,
  payload,
});
export const getPaymentRejectionReasonsReceive = (payload) => ({
  type: TYPES.GET_PAYMENT_REJECTION_REASONS_RECEIVE,
  payload,
});
//Reject Payment
export const rejectPaymentRequest = (payload) => ({
  type: TYPES.REJECT_PAYMENT_REQUEST,
  payload,
});
export const rejectPaymentReceive = (payload) => ({
  type: TYPES.REJECT_PAYMENT_RECEIVE,
  payload,
});
//New Count
export const newCountRequest = (payload) => ({
  type: TYPES.NEW_COUNT_REQUEST,
  payload,
});
export const newCountReceive = (payload) => ({
  type: TYPES.NEW_COUNT_RECEIVE,
  payload,
});
//Last Seen
export const lastSeenRequest = (payload) => ({
  type: TYPES.LAST_SEEN_REQUEST,
  payload,
});
export const lastSeenReceive = (payload) => ({
  type: TYPES.LAST_SEEN_RECEIVE,
  payload,
});
// Get Payment Methods
export const getPaymentMethodsRequest = (payload) => ({
  type: TYPES.GET_PAYMENT_METHODS_REQUEST,
  payload,
});
export const getPaymentMethodsReceive = (payload) => ({
  type: TYPES.GET_PAYMENT_METHODS_RECEIVE,
  payload,
});
//Hide Payment Bullet
export const hidePaymentBulletRequest = (payload) => ({
  type: TYPES.HIDE_PAYMENT_BULLET_REQUEST,
  payload,
});
export const hidePaymentBulletReceive = (payload) => ({
  type: TYPES.HIDE_PAYMENT_BULLET_RECEIVE,
  payload,
});
//Download Excel
export const downloadExcelRequest = (payload) => ({
  type: TYPES.DOWNLOAD_EXCEL_REQUEST,
  payload,
});
export const downloadExcelReceive = (payload) => ({
  type: TYPES.DOWNLOAD_EXCEL_RECEIVE,
  payload,
});
//Refund Payment
export const refundPaymentRequest = (payload) => ({
  type: TYPES.REFUND_PAYMENT_REQUEST,
  payload,
});
export const refundPaymentReceive = (payload) => ({
  type: TYPES.REFUND_PAYMENT_RECEIVE,
  payload,
});