import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";


//Get Attachments List
export function* getAttachmentsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAttachmentsRequest, payload);
    yield put(ACTIONS.getAttachmentsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Attachment
export function* getAttachmentSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAttachmentRequest, payload);
    yield put(ACTIONS.getAttachmentReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add Attachment
export function* addAttachmentSaga({payload, patientId}) {
  try {
    yield call(APIS.addAttachmentRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getAttachmentsRequest({
      patientId: patientId,
      page: 1,
      sort: "created_at,desc",
    }));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit Attachment
export function* editAttachmentSaga({payload}) {
  try {
    yield call(APIS.editAttachmentRequest, payload);
    yield put(ACTIONS.editAttachmentReceive(payload));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete Attachment
export function* deleteAttachmentSaga({payload}) {
  try {
    yield call(APIS.deleteAttachmentRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteAttachmentReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Download Attachment
export function* downloadAttachmentSaga({payload}) {
  try {
    const { data } = yield call(APIS.downloadAttachmentRequest, payload);
    dispatchSnackbarSuccess("success");
    console.log(data)
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Messages
export function* getMessagesSaga({payload}) {
  try {
    const { data } = yield call(APIS.getMessagesRequest, payload);
    yield put(ACTIONS.getMessagesReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* attachmentsSagas() {
  yield takeLatest(TYPES.GET_ATTACHMENTS_REQUEST, getAttachmentsSaga);
  yield takeLatest(TYPES.GET_ATTACHMENT_REQUEST, getAttachmentSaga);
  yield takeLatest(TYPES.DELETE_ATTACHMENT_REQUEST, deleteAttachmentSaga);
  yield takeLatest(TYPES.ADD_ATTACHMENT_REQUEST, addAttachmentSaga);
  yield takeLatest(TYPES.EDIT_ATTACHMENT_REQUEST, editAttachmentSaga);
  yield takeLatest(TYPES.DOWNLOAD_ATTACHMENT_REQUEST, downloadAttachmentSaga);
  yield takeLatest(TYPES.GET_MESSAGES_REQUEST, getMessagesSaga);
}


