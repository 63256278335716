const arabic = {
  ar: {
    hello: "مرحبا",
    lang: "English",
    common: {
      second: "ثانية",
      resend: "إعادة الارسال",
      save: "حفظ",
      edit: "تعديل",
      cancel: "إلغاء",
      close: "إغلاق",
      change: "تغيير",
      confirm: "موافق",
      back: "رجوع",
      show: "عرض",
      hide: "إخفاء",
      add_new_reason:"+ اضف سبب اخر",
      all_days: "كل الأيام",
      currency: 'ريال',
      show_more: "عرض المزيد",
      show_less: "عرض أقل",
    },
    home: {
      myTasks: "مهامي",
      taskBtn: "إضافة مهمة جديدة",
      chart: {
        newTasks: "لم تبدأ بعد",
        inProgress: "جاري العمل عليها",
        late: "متأخر",
        completed: "تم الانتهاء",
        completed_late: "تم الانتهاء متأخر",
        tasks: "مهمة",
      },
      system_accounts: 'حسابات النظام',
      patients_list: 'قائمة المرضى',
      programs_subscription: 'برامج الاشتراك',
      lab_tests: 'قائمة الفحوصات',
      ref_labs: 'قائمة المختبرات المرجعية',
      myappointments: 'مواعيد اليوم',
      tree: 'الشجرة الوراثية',
    },
    auth: {
      logout: "تسجيل الخروج",
      login: "تسجيل الدخول",
      forgetPassword: "نسيت كلمة المرور؟",
      forgetPasswordFormHint: "من فضلك أدخل أسم المستخدم الخاص بك",
      resetPassword: "استعادة كلمة المرور",
      setPassword: "تعيين كلمة المرور",
      otpTitle: "رمز التحقق",
      otpContent: "من فضلك أدخل رمز التحقق الذي تم إرساله لك في رسالة نصية",
      send: "إرسال",
      resetPasswordSuccess: "تم تغيير كلمة المرور بنجاح",
      labels: {
        userName: "إسم المستخدم",
        password: "كلمة المرور",
      },
      placeholders: {
        userName: "من فضلك ادخل إسم المستخدم",
        password: "من فضلك ادخل كلمة المرور",
        otp: "من فضلك ادخل رمز التحقق",
      },
      errors: {
        required: "هذا الحقل مطلوب",
        credentialsError:
          ".خطأ في كود الموظف أو كلمة المرور، يرجى التأكد من بيانات الحساب",
        userNameError: "إسم المستخدم يجب ان يحتوى على ارقام و احرف أو علامات",
        userNameLength: "إسم المستخدم يجب ان لا يكون اثر من 50 عنصر",
        passwordPattern: "كلمة المرور يجب ان تحتوى على ارقام و أحرف و علامات",
        passwordLength: "كلمة المرور يجب ان ﻻ تكون أقل من 8 أحرف",
        otp: "رمز التحقق يتكون من 4 ارقام",
        confirmPassword: "كلمة المرور غير متطابقة",
      },
    },
    header: {
      hello: "مرحباً ",
      notifications: "تنبيهاتي",
      title: {
        allRequests: "الطلبات",
        newRequests: 'طلبات جديدة',
        activeRequests: 'طلبات فعّالة',
        rejectedRequests: 'طلبات مرفوضة',
        tasksList: 'قائمة المهام',
        users: 'حسابات النظام',
        internal: 'المستخدمين الداخليين',
        add_user: 'مستخدم جديد',
        edit_user: 'تعديل المستخدم',
        lab_tests: 'قائمة الفحوصات',
        add_lab: 'إضافة فحص جديد',
        edit_lab: 'تعديل الفحص',
        reference_labs: 'قائمة المختبرات المرجعية',
        add_reference_lab: 'إضافة مختبر مرجعي جديد',
        edit_reference_lab: 'تعديل المختبر المرجعي',
        users_labs: 'المعامل',
        add_users_lab: 'إضافة معمل جديد',
        edit_users_lab: 'تعديل المعمل',
        users_hospitals: 'المستشفيات',
        add_users_hospital: 'إضافة مستشفى جديد',
        edit_users_hospital: 'تعديل المستشفى',
        mypatients_list: 'قائمة المرضى',
        patients_list: 'قائمة العملاء',
        add_patient: 'إضافة عميل',
        edit_patient: 'تعديل العميل',
        patient_profile: 'بيانات العميل',
        patient_preview: 'بيانات العميل',
        canceled_appointments: 'مواعيد ملغاة',
        completed_appointments: 'مواعيد منتهية',
        appointment_statistics: 'مواعيد الأخصائيات',
      }
    },
    statistics: {
      new: "طلب جديد",
      active: "طلب فعال",
      completed: "طلب مكتمل",
      canceled: "طلب ملغي",
      rejected: "طلب مرفوض",
      appointments: {
        new: "موعد جديد",
        active: "موعد فعال",
        not_completed: "موعد غير مكتمل",
        completed: "موعد مكتمل",
        canceled: "موعد ملغي",
        missed: "مقابلات فائتة",
        obligations_sent: "تم إرسال الفحوصات المقترحة",
        late: "غير مكتمل ومتأخر",
        canceled_apps: "ملغى",
      },
      payments: {
        title: "المدفوعات",
        info: "المدفوعات المطلوبة للتعهدات الموافق عليها",
        late: "متأخر",
        waiting: "في إنتظار التأكيد",
        confirmed: "تم",
        not_confirmed: "لم يتم",
        rejected: "مرفوض",
      },
      sampling: {
        title: "حجز موعد سحب العينة",
        sampling_title: "سحب العينة",
        late: "متأخر",
        done: "تم",
        in_progress: "لم يتم",
      }
    },
    usersStatistics: {
      employee_vacations: "إجازات الموظفين",
      employee_leaves: "إجازات الموظف",
    },
    profile: {
      header: "البيانات الشخصية :",
      code: "كود الموظف: ",
      job: "الوظيفة",
      mobile: "الجوال",
      editInfo: "تعديل البيانات",
      cancelChangePasswordMSGHeader: "عدم حفظ كلمة المرور الجديدة",
      cancelChangePasswordMSGContent:
        "هل انت متأكد من عدم حفظ كلمة المرور الجديدة",
    },
    formControl: {
      input: "اكتب هنا",
      select: "اختار",
      autoCompelete: "الإكمال التلقائي",
      autoCompeleteMulti: "الإكمال التلقائي متعدد",
      email: "البريد الالكترونى",
      password: "كلمة المرور الجديدة",
      confirmPassword: "تاكيد كلمة المرور الجديدة",
    },
    errors: {
      required: "هذا الحقل مطلوب",
      invalidEmail: "الايميل غير صحيح",
      invalidPassword: "كلمة السر غير صحيحة",
      invalidMatchPassword: "كلمة السر غير متطابقة",
      imageTypeErr: "الصورة يجب ان تكون من نوع (JPG او PNG او JPEG)",
      imageSizeErr: "حجم الصورة كبير جدا .اقصى حجم 1 ميجا",
      imageSizeErr2: "حجم الصورة كبير جدا .اقصى حجم 2 ميجا",
      imageSizeErr3: "نوع الصورة غير مدعوم",
      noImage: "لا يوجد صورة",
      specialist_have_not_started_yet: "الاخصائي لم يبدأ المقابلة بعد",
      specialist_error: "عليك بدء المقابلة من الصفحة الرئيسية",
    },
    buttons: {
      submit: "تسجيل",
      view: "عرض",
      details: "تفاصيل",
      back: "رجوع",
      confirm: "تأكيد",
      reject: "رفض",
      yes: "نعم",
      no: "لا",
      save: "حفظ",
      send: "إرسال",
      cancel: "إلغاء",
      change: "تغيير",
      ok: "موافق",
      accept: "قبول",
      restore: "استرجاع",
    },
    pages: {
      dashboard: "الرئيسية",
      forms: " استمارة",
      tables: "الجداول",
    },
    snackbar: {
      success: "تم بنجاح",
      sent: "تم ارسال التعهدات بنجاح"
    },
    branches: {
      name_en: "اسم الفرع بالعربية",
      name_ar: "اسم الفرع بالانجليزية",
      address: "العنوان",
      country: "البلد",
      city: "المدينة",
    },
    requests: {
      patient_name: "اسم المريض",
      request_date: "تاريخ الطلب",
      request_channel: "مصدر الطلب",
      request_status: "الحالة",
      request_reason: "سبب الرفض",
      cancel_reasons: "كل أسباب الإلغاء",
      request: "طلب",
      search_by_phone_or_name_or_id_placholder:
        "ابحث بالإسم، الجوال، أو رقم الهوية",
      request_of: "{total} من {totals} طلبات",
      all_sources: "مصادر الطلبات",
      all: "مصادر الطلبات",
      all_reasons: "أسباب الرفض",

      name: "الاسم",
      phone: "الجوال",
      text_msg: "نص الرسالة",
      new_request_to_appointment: "طلب جديد لحجز موعد",
      activeRequests: "طلب فعال",
      rejectedRequests: "طلب مرفوض",

      request_confirmation: "تم تأكيد طلب العميل بنجاح",
      request_confirmation_btn: "حساب العميل",
      request_confirmed: "الطلبات المؤكدة",
      request_restored: "طلبات جديدة",
      not_completed: "طلبات غير مكتملة البيانات",
      request_confirmation_close: "إنهاء ",
      finish_validation_msg: 'هل انت متأكد من عدم استكمال بيانات العميل؟',
      all_new_requests: "الطلبات الجديدة",
      date: "التاريخ",
      patient_profile_open: "فتح حساب العميل",
      rejection_reason: "سبب الرفض",
      rejection_reason_placeholder: "سبب الرفض",
      new_rejection_reason_placeholder: "السبب",
      new_rejection_reason_description: "وصف السبب",
      anther_reason: "سبب اخر",
      validation_reason: "يجب ان تدخل السبب",
      reject_request:"رفض الطلب",
      stages: {
        all_stages: "مراحل الطلبات",
        new_requests: "طلبات جديدة",
        not_completed: "غير مكتمل",
        active_requests: "طلبات فعالة",
        appointment_reservation: "تم حجز موعد",
        interview: "تمت المقابلة الطبية",
        no_interview: "لم تتم المقابلة",
        obligation_sending: "تم إرسال التعهد",
        obligation_no_sent: "لم يتم إرسال التعهد",
        obligation_confirmation: "تمت موافقة التعهد",
        no_obligation_confirmation: "لم تتم موافقة التعهد",
        payment: "تمت عملية الدفع",
        not_paid:"لم يتم الدفع متأخر",
        sampling_reservation: "سحب العينة",
        sampling_reserved: "تم حجز موعد سحب العينة",
        sampling_not_reserved: "لم يتم حجز موعد سحب العينة",
        sampling_deposited: "تم سحب العينة",
        sampling_not_deposited: "لم يتم سحب العينة",
        shipping: "شحن العينة",
        sample_shipped: "تم شحن العينة",
        sample_not_shipped: "لم يتم شحن العينة",
        result_explantation: "شرح النتيجة",
        delay_result_explantation: "لم يتم شرح النتيجة",
        result_received: "إرسال النتيجة",
        result_not_received: "لم يتم إستلام النتيجة",
        registered_With_no_Request: "مسجل دون طلب",
        rejected: "طلبات مرفوضة",
        canceled: "طلبات ملغية",
        completed: 'طلبات مكتملة',
      },
      cancel_reason: "سبب الإلغاء",
      add_cancel_reason: "أضف سبب إلغاء جديد",
      cancel_reason_required: "يجب ادخال سبب الإلغاء",
    },
    tasks:{
      new_tasks:'المهام المقبلة',
      inprogress_tasks:'المهام الحالية',
      completed_tasks:'المهام المكتملة',
      add_new_task:'اضافة مهمة جديدة',
      search_content: 'بحث',
      coordinator: 'اختر المنسقة',
      coordinatorsTasksTitle: 'مهام المنسقات',
      form: {
        add_title: 'اضافة مهمة',
        edit_title: 'تعديل مهمة',
        view_title: 'تفاصيل المهمة',
        date: 'التاريخ',
        editing_reason: 'سبب التعديل',
        reason_placeholder: 'من فضلك ادخل سبب التعديل',
        reason_error: 'من فضلك ادخل سبب التعديل',
        date_placeholder: 'اختر الموعد المحدد لإتمام المهمة المطلوبة',
        date_error: 'يجب اختيار الموعد المحدد لإتمام المهمة المطلوبة',
        details: 'تفاصيل المهمة',
        details_placeholder: 'ادخل تفاصيل المهمة',
        details_error: 'يجب ادخال تفاصيل المهمة',
        save: 'حفظ',
        edit: 'تعديل',
        finish: 'انهاء المهمة',
        cancel: 'إلغاء',
        success: 'تمت إضافة المهمة بنجاح',
        success_edit: 'تم تعديل المهمة بنجاح',
        success_finish: 'تم انهاء المهمة بنجاح',
        assignedBy: 'تم التعيين بواسطة',
        log: {
          creation_date: 'تاريخ الإنشاء',
          in_progress: 'جاري العمل عليها',
          in_progress_late: 'جاري العمل عليها متأخرة',
          completed: 'تم الانتهاء',
          completed_late: 'تم الانتهاء متأخرة',
        }
      }
    },
    users: {
      users: "مستخدم",
      internal: "مستخدم داخلي",
      factory: "معمل",
      hospital: "مستشفى",
      users_of: "{total} مستخدم من {totals}",
      add_user: "إضافة مستخدم جديد",
      add_lab: "إضافة معمل جديد",
      add_hospital: "إضافة مستشفى جديد",
      edit_user: "تعديل مستخدم",
      code: 'كود',
      user_name: 'اسم المستخدم',
      position: 'الوظيفة',
      email: 'البريد الإلكتروني',
      mobile: 'الجوال',
      status: 'الحالة',
      actions: '',
      search: 'بحث',
      admin: 'مدير',
      receptionist: 'موظف استقبال',
      coordinator: 'منسق',
      specialist: 'اخصائي',
      accountant: 'محاسب',
      consultant: 'إستشاري',
      position_placeholder: 'الوظيفة',
      delete_confirmation: 'هل أنت متأكد من حذف المستخدم؟',
      block_confirmation: 'هل أنت متأكد من حظر المستخدم؟',
      form : {
        title: "بيانات المستخدم",
        code: 'كود الموظف',
        code_placeholder: 'من فضلك أدخل كود الموظف',
        en_name: 'الإسم باللغة الإنجليزية',
        en_name_placeholder: 'من فضلك أدخل الإسم باللغة الإنجليزية',
        ar_name: 'الإسم باللغة العربية',
        ar_name_placeholder: 'من فضلك أدخل الإسم باللغة العربية',
        position: 'الوظيفة',
        position_placeholder: 'من فضلك اختر الوظيفة',
        email: 'البريد الإلكتروني',
        email_placeholder: 'من فضلك أدخل البريد الإلكتروني',
        mobile: 'الجوال',
        mobile_placeholder: 'من فضلك أدخل رقم الجوال',
        btn_save: 'حفظ',
        btn_next: 'التالي',
        btn_back: 'السابق',
        btn_cancel: 'إلغاء',
        email_invalid: 'البريد الإلكتروني غير صحيح',
        phone_invalid: 'رقم الجوال غير صحيح',
        scheduler_title: 'مواعيد العمل',
        monday: 'الإثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت',
        sunday: 'الأحد',
        time_from: 'من',
        time_to: 'إلى',
        all_fields_required: 'من فضلك أدخل جميع الحقول',
        code_invalid: 'كود الموظف غير صحيح',
        position_invalid: 'من فضلك اختر الوظيفة',
        en_name_invalid: 'من فضلك أدخل الإسم باللغة الإنجليزية',
        ar_name_invalid: 'من فضلك أدخل الإسم باللغة العربية',
        schedules_invalid: 'من فضلك أدخل مواعيد المقابلات صحيحة',
        schedule_invalid_2h: 'مواعيد العمل لايمكن أن تكون أقل من ساعتين',
        schedule_invalid_30m: 'مواعيد المقابلات لايمكن أن تكون أقل من 30 دقيقة',
        schedule_invalid_section: 'مواعيد المقابلات لايمكن أن تكون خارج أوقات العمل',
        time_invalid: 'من فضلك أدخل الوقت بشكل صحيح',
        time_apps_required: 'من فضلك أدخل مواعيد المقابلات',
        working_hours_invalid: 'من فضلك أدخل مواعيد العمل صحيحة',
        apps_hours_invalid: 'من فضلك أدخل مواعيد المقابلات صحيحة',
        appointments: 'مواعيد المقابلات',
      }
    },
    usersLabs: {
      add_new: "إضافة معمل جديد",
      edit_ref: "تعديل معمل",
      refs_of: "{total} معمل من {totals}",
      ref_id: "كود المعمل",
      arabic_name: "اسم المعمل عربي",
      english_name: "اسم المعمل إنجليزي",
      city: "المدينة",
      location: "الموقع",
      status: "الحالة",
      actions: "",
      desactive_confirmation: "هل أنت متأكد من تجميد المعمل؟",
      search: "بحث",
      all_labs: "المختبرات المعملية",
      showLink: "عرض الموقع",
      allcities: "المدن",
      form : {
        title: "بيانات المعمل",
        code: 'كود المعمل',
        code_placeholder: 'من فضلك أدخل كود المعمل',
        en_name: 'اسم المعمل إنجليزي',
        en_name_placeholder: 'من فضلك أدخل اسم المعمل إنجليزي',
        ar_name: 'اسم المعمل عربي',
        ar_name_placeholder: 'من فضلك أدخل اسم المعمل عربي',
        cities: 'المدينة',
        cities_placeholder: 'اختر',
        cities_error: 'من فضلك اختر المدينة',
        username: 'اسم المستخدم',
        username_placeholder: 'من فضلك أدخل اسم المستخدم',
        google: 'الموقع على خرائط الجوجل',
        google_placeholder: 'من فضلك أدخل رابط الموقع',
        user_title: 'بيانات الشخص المعني بالتواصل',
        btn_save: 'حفظ',
        btn_cancel: 'إلغاء',
        allRequired: 'من فضلك أدخل جميع الحقول',
        position_placeholder: 'من فضلك أدخل الوظيفة',
      }
    },
    usersHospitals: {
      add_new: "إضافة مستشفى جديد",
      edit_ref: "تعديل مستشفى",
      refs_of: "{total} مستشفى من {totals}",
      ref_id: "كود المستشفى",
      arabic_name: "اسم المستشفى عربي",
      english_name: "اسم المستشفى إنجليزي",
      city: "المدينة",
      location: "الموقع",
      status: "الحالة",
      actions: "",
      desactive_confirmation: "هل أنت متأكد من تجميد المستشفى؟",
      search: "بحث",
      all_labs: "المختبرات المعملية",
      showLink: "عرض الموقع",
      allcities: "المدن",
      form : {
        title: "بيانات المستشفى",
        code: 'كود المستشفى',
        code_placeholder: 'من فضلك أدخل كود المستشفى',
        en_name: 'اسم المستشفى إنجليزي',
        en_name_placeholder: 'من فضلك أدخل اسم المستشفى إنجليزي',
        ar_name: 'اسم المستشفى عربي',
        ar_name_placeholder: 'من فضلك أدخل اسم المستشفى عربي',
        cities: 'المدينة',
        cities_placeholder: 'اختر',
        cities_error: 'من فضلك اختر المدينة',
        username: 'اسم المستخدم',
        username_placeholder: 'من فضلك أدخل اسم المستخدم',
        google: 'الموقع على خرائط الجوجل',
        google_placeholder: 'من فضلك أدخل رابط الموقع',
        user_title: 'بيانات الشخص المعني بالتواصل',
        btn_save: 'حفظ',
        btn_cancel: 'إلغاء',
        allRequired: 'من فضلك أدخل جميع الحقول',
        position_placeholder: 'من فضلك أدخل الوظيفة',
      }
    },
    labTests: {
      add_new: "إضافة فحص جديد",
      edit_lab: "تعديل الفحص",
      labs_of: "{total} فحص من {totals}",
      test_id: "كود الفحص",
      arabic_name: "اسم الفحص عربي",
      english_name: "اسم الفحص إنجليزي",
      ref_lab: "المختبر المرجعي",
      price_before: "السعر قبل",
      price_after: "السعر بعد",
      status: "الحالة",
      actions: "",
      desactive_confirmation: "هل أنت متأكد من تجميد الفحص؟",
      search: "بحث",
      all_labs: "المختبرات المرجعية",
      currency: "ريال",
      delete_confirmation: "هل أنت متأكد من حذف الفحص؟",
      result_due_days: "فترة ظهور النتيجة من تاريخ سحب العينة",
      result_due_days_label: "يوم",
      result_due_days_error: "من فضلك اختر فترة ظهور النتيجة",
      form : {
        title: "بيانات الفحص",
        code: 'كود الفحص',
        code_placeholder: 'من فضلك أدخل كود الفحص',
        en_name: 'اسم الفحص إنجليزي',
        en_name_placeholder: 'من فضلك أدخل اسم الفحص إنجليزي',
        ar_name: 'اسم الفحص عربي',
        ar_name_placeholder: 'من فضلك أدخل اسم الفحص عربي',
        ref_labs: 'المختبر المرجعي',
        ref_labs_placeholder: 'اختر',
        blood: 'نوع العينة إنجليزي',
        blood_placeholder: 'عينة دم',
        blood_ar: 'نوع العينة عربي',
        ammount: 'كمية العينة',
        ammount_placeholder: 'من 3 إلى 5 مل',
        tube_type: "نوع الأنبوب",
        tube_type_placeholder: "اختر",
        tube_number: "عدد الأنابيب",
        price_before: "السعر قبل",
        price_before_placeholder: "من فضلك أدخل السعر بالريال السعودي",
        price_after: "السعر بعد",
        price_after_placeholder: "من فضلك أدخل السعر بالريال السعودي",
        btn_save: 'حفظ',
        btn_cancel: 'إلغاء',
        ref_lab_error: 'من فضلك اختر المختبر المرجعي',
        blood_error: 'من فضلك ادخل نوع العينة',
        ammount_error: 'من فضلك ادخل كمية العينة',
        en_name_invalid: 'من فضلك أدخل الإسم باللغة الإنجليزية',
        ar_name_invalid: 'من فضلك أدخل الإسم باللغة العربية',
        price_invalid: 'من فضلك أدخل السعر بالريال السعودي',
        price_error: 'قيمة السعر بعد الخصم يجب أن تكون أقل من أو تساوى السعر الأصلي',
        allRequired: 'من فضلك أدخل جميع الحقول',
        obligation_type: 'التعهد المرتبط بالفحص',
        obligation_type_placeholder: 'اختر',
        option1: 'تعهد الفرد / تعهد ولي الأمر',
        option2: 'تعهد NIPT',
        option3: 'تعهد الكروموسومات',
        obligation_type_error: 'من فضلك اختر التعهد المرتبط بالفحص',
        shipping_company: 'شركة الشحن',
        shipping_company_placeholder: 'اختر',
        company1: 'آرامكس',
        company2: 'DHL',
        company3: 'سمسا',
        shipping_company_error: 'من فضلك اختر شركة الشحن',
        location1: 'موقع المعمل ',
        location2: 'موقع جينوم',
        location3: 'موقع المختبر المرجعي',
      }
    },
    referenceLabs: {
      add_new: "إضافة مختبر مرجعي جديد",
      edit_ref: "تعديل مختبر مرجعي",
      refs_of: "{total} مختبر من {totals}",
      ref_id: "كود المختبر المرجعي",
      arabic_name: "اسم المختبر المرجعي عربي",
      english_name: "اسم المختبر المرجعي إنجليزي",
      country: "الدولة",
      city: "المدينة",
      status: "الحالة",
      actions: "",
      desactive_confirmation: "هل أنت متأكد من تجميد المختبر المرجعي؟",
      search: "بحث",
      all_labs: "اختر المختبر المرجعي",
      currency: "ريال",
      form : {
        title: "بيانات المختبر المرجعي",
        code: 'كود المختبر المرجعي',
        code_placeholder: 'من فضلك أدخل كود المختبر المرجعي',
        en_name: 'اسم الفحص إنجليزي',
        en_name_placeholder: 'من فضلك أدخل اسم المختبر المرجعي إنجليزي',
        ar_name: 'اسم المختبر المرجعي عربي',
        ar_name_placeholder: 'من فضلك أدخل اسم المختبر المرجعي عربي',
        countries: 'الدولة',
        countries_placeholder: 'اختر',
        city: 'المدينة',
        city_placeholder: 'من فضلك أدخل المدينة',
        google: 'الموقع على خرائط الجوجل',
        google_placeholder: 'من فضلك أدخل رابط الموقع',
        user_title: 'بيانات الشخص المعني بالتواصل',
        btn_save: 'حفظ',
        btn_cancel: 'إلغاء',
        allRequired: 'من فضلك أدخل جميع الحقول',
        position_placeholder: 'من فضلك أدخل الوظيفة',
        en_name_invalid: 'من فضلك أدخل اسم المختبر باللغة الإنجليزية',
      }
    },
    patients: {
      add_new: "إضافة عميل جديد",
      edit_ref: "تعديل العميل",
      refs_of: "{total} عميل من {totals}",
      client_name: "اسم العميل",
      source_name: "اسم المصدر",
      inscription_date: "تاريخ التسجيل",
      status: "الحالة",
      actions: "",
      desactive_confirmation: "هل أنت متأكد من تجميد العميل؟",
      search: "بحث",
      all_labs: "المختبرات المعملية",
      currency: "ريال",
      stage: 'المرحلة',
      not_saudi: 'غير سعودي',
      form : {
        title: "بيانات العميل",
        step1: "البيانات الشخصية",
        step2: "بيانات الاتصال",
        step3: "المقابلة الطبية",
        personal_data: "البيانات الشخصية",
        family_history: "التاريخ العائلي",
        yes: 'نعم',
        no: 'لا',
        patientId: 'رقم الهوية',
        patientId_placeholder: 'من فضلك أدخل رقم الهوية',
        patientId_feedback: 'من فضلك أدخل رقم الهوية بشكل صحيح',
        customer_type: 'نوع العميل',
        healthy: 'سليم',
        affected: 'مصاب',
        prenatal: 'فحص الأجنة',
        test_type: 'نوع الفحص',
        premarital: 'مقبل للزواج',
        pregnancy: 'مقبل للانجاب',
        individual: 'فردي',
        firstname: 'الاسم الأول',
        firstname_placeholder: 'من فضلك أدخل الاسم الأول',
        en_name: 'الاسم الكامل باللغة الإنجليزية',
        en_name_placeholder: 'الاسم الكامل باللغة الإنجليزية',
        ar_name: 'الاسم الكامل باللغة العربية',
        ar_name_placeholder: 'الاسم الكامل باللغة العربية',
        father_link: "ربط عائلة الأب",
        father_link_placeholder: 'اختر',
        father_link_feedback: 'من فضلك اختر ربط عائلة اللأب',
        mother_link: "ربط عائلة الأم",
        mother_link_placeholder: 'اختر',
        mother_link_feedback: 'من فضلك اختر ربط عائلة الأم',
        relationship: "صلة قرابة الأب والأم",
        relationship_placeholder: 'اختر',
        relationship_feedback: 'من فضلك اختر صلة قرابة الأب والأم',
        same_family: 'نفس العائلة',
        different_family: 'عائلة مختلفة',
        add_new_family: 'أضف اسم عائلة جديد',
        birthday: 'تاريخ الميلاد',
        birthday_feedback: 'من فضلك أدخل تاريخ الميلاد',
        origin_city: 'مدينة الأصل',
        origin_city_placeholder: 'اختر',
        origin_city_feedback: 'من فضلك اختر مدينة الأصل',
        birth_city: 'محل الميلاد',
        birth_city_placeholder: 'اختر',
        birth_city_feedback: 'من فضلك اختر محل الميلاد',
        gender: "النوع",
        gender_placeholder: 'اختر',
        gender_feedback: 'من فضلك اختر النوع',
        male: 'ذكر',
        female: 'أنثى',
        marital_status: "الحالة الإجتماعية",
        marital_status_placeholder: 'اختر',
        marital_status_feedback: 'من فضلك اختر الحالة الإجتماعية ',
        single: 'أعزب',
        married: 'متزوج',
        widow: 'أرمل',
        divorced: 'مطلق',
        request_channel: 'مصدر الطلب',
        request_channel_placeholder: 'اختر',
        request_channel_feedback: 'من فضلك اختر مصدر الطلب',
        website: 'الموقع الإلكتروني',
        phone_call: 'مكالمة هاتفية',
        whatsapp: 'واتس آب',
        facebook: 'فيسبوك',
        twitter: 'تويتر',
        instagram: 'انستجرام',
        mobile_apps: 'تطبيقات الهاتف',
        dealing_type: 'نوع التعامل',
        dealing_type_placeholder: 'اختر',
        dealing_type_feedback: 'من فضلك اختر نوع التعامل',
        online: 'أونلاين',
        offline: 'يدوي',
        subscription_programs: 'برامج الاشتراك',
        subscription_programs_placeholder: 'اختر',
        subscription_programs_feedback: 'من فضلك اختر برنامج الاشتراك',
        general_program: 'البرنامج العام',
        healthy_family_program: 'الأسرة الصحية',
        premarital_program: 'ما قبل الزواج',
        family_wala_program: "الولاء العائلي",
        email: 'البريد الإلكتروني',
        email_placeholder: 'من فضلك أدخل البريد الإلكتروني',
        mobile: 'الجوال',
        mobile_placeholder: 'من فضلك أدخل رقم الجوال',
        governorate: 'المنطقة',
        governorate_placeholder: 'اختر',
        governorate_feedback: 'من فضلك اختر المنطقة',
        city: 'مدينة العيش',
        city_placeholder: 'اختر',
        city_feedback: 'من فضلك اختر المدينة',
        address: 'العنوان',
        address_placeholder: 'من فضلك أدخل العنوان',
        contact_details_point: 'بيانات الشخص المعني بالتواصل',
        contact_nameen: 'الاسم الإنجليزي',
        contact_nameen_placeholder: 'من فضلك أدخل الاسم الإنجليزي',
        contact_namear: 'الاسم العربي',
        contact_namear_placeholder: 'من فضلك أدخل الاسم العربي',
        relation: 'صلة القرابة',
        relation_placeholder: 'من فضلك أدخل صلة القرابة',
        contact_reason: 'سبب التواصل',
        contact_reason_placeholder: 'من فضلك أدخل سبب التواصل',
        add_new_person: 'أضف شخص جديد',
        specialist: "الأخصائي / الإستشاري الوراثي المختص",
        specialist_placeholder: 'اختر',
        specialist_feedback: 'من فضلك اختر الأخصائي',
        time_feedback: 'من فضلك اختر الوقت',
        app_type: 'نوع المقابلة',
        app_type_feedback: 'من فضلك اختر نوع المقابلة',
        app_type_placeholder: 'اختر',
        at_office: 'حضورية',
        textual_app: 'مقابلة نصية',
        video_call: 'مقابلة مرئية',
        audio_call: 'مقابلة صوتية',
        app_status: 'حالة المقابلة',
        app_status_feedback: 'من فضلك اختر حالة المقابلة',
        app_status_placeholder: 'اختر',
        first_app: 'المقابلة الأولى',
        inquiry_app: 'مقابلة الاستعلام',
        explantation_app: 'مقابلة شرح النتيجة',
        completing_app: 'موعد تكميلي',
        btn_save: 'حفظ',
        btn_cancel: 'إلغاء',
        btn_link: 'ربط أسري',
        allRequired: 'من فضلك أدخل جميع الحقول',
        position_placeholder: 'من فضلك أدخل الوظيفة',
        en_name_invalid: 'من فضلك أدخل اسم المختبر باللغة الإنجليزية',
        messages_title: 'سجل الرسائل',
        view_profile: "استعراض الملف",
        deleteContact: "هل أنت متأكد من حذف هذا الشخص؟",
        patient_requests: "طلبات العميل",
        canceled: "ملغي",
      },
      patient_requests: {
        add: "إضافة طلب",
        request_date: "تاريخ الطلب",
        request_message: "رسالة الطلب",
        request_status: "حالة الطلب",
        request_stage: "مرحلة الطلب",
        action: "",
        details: "تفاصيل",
        cancel_request: "إلغاء الطلب",
        noData: "لا توجد طلبات حتى الآن",
        request_details: "تفاصيل الطلب",
        date: "التاريخ",
        message: "الرسالة",
        notes: "ملاحظات",
        status: "الحالة",
        stage: "المرحلة",
        cancel_reason: "سبب الإلغاء",
        add_new_request: "إضافة طلب جديد",
      },
      contacts: {
        title: 'الأشخاص المعنية بالتواصل',
        add: 'إضافة جديد',
        add_title: 'إضافة شخص معني بالتواصل',
        edit_title: 'تعديل شخص معني بالتواصل',
      },
      attachments: {
        title: 'مرفقات عامة',
        filename: 'اسم الملف',
        creation_date: 'تاريخ الإرفاق',
        created_by: 'مُرفق بواسطة',
        actions: 'الإجراءات',
        btn: 'إرفاق ملف',
        delete_confirmation: 'هل أنت متأكد من حذف المرفق؟',
      },
      appointments: {
        title: 'المقابلات الطبية',
        app_type: 'نوع المقابلة',
        specialist_name: 'اسم الأخصائي',
        app_date: 'تاريخ المقابلة',
        app_status: 'حالة المقابلة',
        status: 'الحالة',
        actions: 'الإجراءات',
        btn: 'تحديد موعد',
        edit: 'تعديل موعد',
        confirmDelete: 'هل أنت متأكد من حذف المقابلة؟',
        user_appointments: 'مقابلاتي',
        not_active: 'يمكنك بدء المقابلة يوم',
        return_to_patient: 'رجوع إلى العميل',
      },
      labtests: {
        title: 'الفحوصات الطبية',
        btn: 'إضافة فحص جديد',
        empty: 'لم يتم إقتراح أي فحص طبي بعد من قبل الأخصائية الوراثية',
        stage1: 'تقديم الطلب',
        stage2: 'تأكيد الطلب',
        stage3: 'حجز موعد',
        stage4: 'المقابلة الطبية',
        stage5: 'إرسال التعهد',
        stage6: 'موافقة التعهد',
        stage7: 'عملية الدفع',
        stage8: 'سحب العينة',
        stage9: 'شرح النتيجة',
        stage10: 'إستلام النتيجة',
        stage11: 'شحن العينة',
        accepted: 'موافق',
        rejected: 'غير موافق',
        paid: 'مدفوع',
        not_paid: 'غير مدفوع',
      },
      notes: {
        title: 'قسم الملاحظات',
        btn: 'إضافة ملاحظة',
        no_data: 'لا توجد ملاحظات حتى الآن',
      }
    },
    programs: {
      add_new: 'إضافة برنامج جديد',
      program_of: "{total} برنامج من {totals}",
      program_name: 'اسم البرنامج',
      status: 'الحالة',
      actions: '',
      delete_confirmation: 'هل أنت متأكد من حذف البرنامج؟',
      block_confirmation: 'هل أنت متأكد من حظر البرنامج؟',
      save: "حفظ",
      cancel: "إلغاء",
      en_name: "اسم البرنامج باللغة الإنجليزية",
      ar_name: "اسم البرنامج باللغة العربية",
    },
    appointments: {
      off: 'موعد',
      app_of: "{total} موعد من {totals}",
      allAppointments: 'جميع المقابلات',
      newAppointments: 'مواعيد جديدة',
      activeAppointments: 'مواعيد فعالة',
      patient_name: 'اسم العميل',
      appointment_date: 'موعد المقابلة',
      appointment_status: 'حالة المقابلة',
      appointment_type: 'نوع المقابلة',
      actions: '',
      all_status: 'حالة المقابلة',
      all_types: 'نوع المقابلة',
      btn: 'التفاصيل',
      search: 'بحث',
      next: 'الموعد القادم',
      current: 'الموعد الحالي',
      no_next: 'لا يوجد موعد قادم',
      app_tests: 'الفحوصات المقترحة',
      app_test_name: 'اسم الفحص',
      app_suggession: 'التوصيات المقترحة',
      app_actions: 'الإجراءات',
      app_add_test: 'إضافة فحص جديد',
      add_test: 'إضافة فحص جديد',
      edit_test: 'تعديل فحص',
      send_btn: 'أرسل إلى المريض',
      no_data: 'لا توجد أي فحوصات مقترحة إلى الآن',
      select: 'اختر',
      select_test: 'اختر الفحص',
      suggession_recommendation: 'التوصيات المقترحة',
      suggession_recommendation_placeholder: 'أدخل هنا التوصيات المقترحة',
      app_test_required: 'من فضلك أدخل الفحص',
      app_suggession_required: 'من فضلك أدخل التوصيات المقترحة',
      start_app: 'بدء المقابلة',
      start_conversation: 'بدء المحادثة',
      done: 'تم',
      cancel: 'ألغيت',
      next_step: 'الخطوة القادمة',
      next_step_placeholder: 'اختر',
      remarks: 'ملاحظات',
      remarks_placeholder: 'ملاحظات',
      save: 'أضف',
      edit: 'تعديل',
      copied: 'تم نسخ الرابط',
      reason: 'سبب الإلغاء',
      new_reason: 'أضف سبب جديد (هذا النص يظهر للعميل)',
      reason_placeholder: 'أضف سببًا آخر',
      genome_office: 'مكتب جينوم',
      noMessages: 'لا توجد رسائل',
      noAppointments: 'لا يوجد موعد للمقابلة الطبية',
      noContacts: 'لم يتم إضافة أشخاص معنية بالتواصل',
      noAttachments: 'لا توجد ملفات مرفقة',
      notCompletedAppointments: 'مواعيد غير مكتملة',
      all_reasons: 'أسباب الإلغاء',
      hold_reasons: 'سبب التأجيل',
      hold: 'مؤجل',
      specialistAppointments: 'مواعيد الاخصائيات',
      allSpecialist: 'الأخصائية / الإستشارية الوراثية',
      allSpecialist_placeholder: 'الأخصائيات/الإستشاريات الوراثية',
      refresh: 'مسح',
      statistics: {
        allAppointments: 'المواعيد',
        newAppointment: 'موعد جديد',
        notCompletedAppointment: 'موعد غير مكتمل',
        notCompletedAppointmentLate: 'موعد غير مكتمل متأخر',
        completedAppointment: 'موعد مكتمل',
        cancelledAppointment: 'موعد ملغي',
        missedAppointment: 'موعد فائت',
      },
      cancel_feedback: "طلب فعال في مرحلة المقابلة",
      cancel_feedback_part1: "طلب فعال في مرحلة",
      cancel_feedback_part2: "مرحلة",
      cancel_btn: 'إلغاء الطلب',
      cancel_title: 'إلغاء الطلب',
      request_status: "مرحلة الطلب",
      medical_interview: "المقابلة الطبية",
      canceling_reason: 'ملاحظات',
      canceling_reason_placeholder: "أدخل الملاحظات",
      show_recording: 'عرض التسجيل',
      specialist_appointments: "مواعيد الأخصائيات",
      medical_appointments: "المقابلات الطبية",
      date: "التاريخ",
      app_status: 'حالة المقابلة',
      app_type: 'نوع المقابلة',
      status: 'الحالة',
      appointment_user: 'الأخصائية / الإستشارية',
      title_hover: "المقابلات الأولى والإستعلام والتكميلية لهم",
    },
    obligations: {
      title: 'موافقة التعهد',
      infos: 'الفحوصات المقترحة التي تم إرسالها للعملاء',
      app_off: "تعهد ",
      status: 'الحالة',
      labtest: 'الفحص',
      sent: 'تم إرسال التعهد',
      not_sent: 'لم يتم إرسال التعهد',
      accepted: 'تمت الموافقة التعهد',
      not_accepted: 'لم يتم الموافقة التعهد',
      patient_name: 'اسم العميل',
      obligation_date: 'تاريخ إرسال التعهد',
      labtest_name: 'اسم الفحص',
      price: 'السعر',
      chart: {
        late: 'متأخر',
        in_progress: 'لم يتم',
        done: 'تم',
      }
    },
    payments: {
      payments_title: 'المدفوعات',
      payment_of: "{total} عملية دفع من {totals}",
      app_off: "عملية دفع",
      canceled: "ملغي",
      pending: "في انتظار التأكيد",
      not_payed: "غير مدفوع",
      payments: "المدفوعات",
      accepted_obligations: "التعهدات الموافق عليها",
      patient_name: "اسم العميل",
      request_date: "تاريخ الاستحقاق",
      labtest_test: "اسم الفحص",
      price: "السعر",
      payment_method: "طريقة الدفع",
      transfert_number: "صورة / رقم التحويل",
      status: "الحالة",
      notes: "ملاحظات",
      bank_transfert_image: "إرفاق صورة الحوالة",
      transfert_image: "الحوالة البنكية",
      wating_for_confirmation: "في انتظار التأكيد",
      currency: "ريال",
      confirm: "تأكيد",
      reject: "رفض",
      rejection_reason: "سبب الرفض",
      search: "بحث بالاسم، الجوال، أو رقم الهوية",
      date: "التاريخ",
      payment_operation: "عملية الدفع",
      bank_transfert: "حوالة بنكية",
      online_payment: "دفع إلكتروني",
      payment_done: "تمت عملية الدفع",
      bank_transfer_receipt: "إرفاق صورة التحويل",
      bank_transfer_placeholder: "برجاء إرفاق صورة من الحوالة",
      new_offline_payment: "طريقة الدفع",
      new_offline_payment_placeholder: "اختر",
      add_offline_payment: "طريقة أخرى",
      online_payment_data: "بيانات الدفع الإلكتروني",
      finish: "إنتهاء",
      preview_invoice: "عرض الفاتورة",
      refund: "استرجاع الفاتورة",
      excel_download: "تحميل ملف اكسل",
      refund_confirmation: "هل أنت متأكد من استرجاع الفاتورة؟",
      status_filter: {
        all: "حالة المدفوعات",
        not_paid: "غير مدفوع",
        not_paid_with_delay: "متأخر",
        wating: "في انتظار التأكيد",
        pending: "مرفوض",
        canceled: "ملغي",
        refunded: "مسترجع",
        paid: "مدفوع",
      },
      method_filter: {
        all: "طريقة الدفع",
        bank_transfert: "حوالة بنكية",
        online: "دفع إلكتروني",
        visa: "Visa - Master Card",
        apple_pay: "Apple Pay",
        mada: "Mada",
        stc: "STC",
      }
    },
    sampling: {
      title: 'سحب العينة',
      app_off: "فحص",
      set_sampling_date: 'مكان سحب العينة',
      sampling_done: 'تم سحب العينة',
      visit_lab: 'زيارة معمل',
      sampling_region: 'المنطقة',
      sampling_region_placeholder: 'اختر',
      sampling_region_error: 'لا يوجد بمنطقتك نقطة سحب عينة',
      sampling_city: 'المدينة',
      sampling_city_placeholder: 'اختر',
      sampling_city_placeholder_error: 'لا توجد نتائج',
      sampling_city_error: 'لا يوجد بمدينتك نقطة سحب عينة',
      nearest_sampling_point: 'أقرب نقطة سحب العينة',
      nearest_sampling_point_placeholder: 'اختر',
      sampling_date_title: 'موعد سحب العينة',
      sampling_date: "التاريخ",
      sampling_date_placeholder: "من فضلك ادخل التاريخ المناسب لك",
      sampling_time: "الوقت",
      notes: "ملاحظات",
      labSchudle: "استعرض مواعيد العمل",
      working_hours_title: "مواعيد العمل",
      working_hours_subtitle: "(قد تختلف مواعيد العمل في المناسبات والأعياد)",
      back_btn: "رجوع",
      holiday: "عطلة",
      patient: "اسم العميل",
      date: "موعد سحب العينة",
      labtest: "اسم الفحص",
      agency: "نقطة سحب العينة",
      status: "الحالة",
      samplingStatus: {
        all: "حالة العينة",
        in_progress: "لم يتم",
        late: "متأخر",
        done: "تم",
      }
    },
    shipping: {
      shipping_details: "عملية الشحن",
      shipping_title: 'بيانات الشحن',
      shipping_done: 'تم شحن العينة',
      shipping_not_done: 'لم يتم شحن العينة',
      shipping_destination_from: 'وجهة الشحن من',
      shipping_destination_from_placeholder: 'اختر',
      shipping_destination_to: 'إلى',
      shipping_destination_to_placeholder: 'اختر',
      first_journey: 'الرحلة الأولى',
      second_journey: 'الرحلة الثانية',
      add_new_destination: 'إضافة وجهة شحن جديدة',
      add_new_arrival: 'إضافة وجهة وصول جديدة',
    },
    agencies: {
      agency_of: "{total} عينة من {totals}",
      app_off: "عينة",
      patient_name: "اسم العميل",
      appointment_date: "موعد السحب",
      sample_type: "نوع العينة",
      sample_quantity: "كمية العينة",
      tube_type: "نوع الأنبوب",
      tubes_count:  "عدد الأنابيب",
      status: "الحالة",
      action: "الإجراءات",
      shipping: "شركة الشحن",
      form: {
        title: "حالة سحب العينة",
        deposited: "تم سحب العينة",
        not_deposited: "لم يتم سحب العينة",
        notes: "ملاحظات",
        notes_placeholder: "أدخل ملاحظات",
        confirm: "تأكيد",
        cancel: "إلغاء",
      }
    },
    reports: {
      requests: {
        title: "تقرير طلبات العملاء",
      }
    },
    warningMsgs:{
      warning_close_window: "هل أنت متأكد من غلق النافذة دون حفظ البيانات؟",
      warning_delete: "هل أنت متأكد من حذف البيانات؟",
      warning_finish: "هل أنت متأكد من انهاء المهمة؟",
      cancel: "إلغاء",
      confirm: "تأكيد",
    },
    noData: {
      no_data: "لا توجد بيانات",
    },
    shared: {
      morning: "صباحا",
      evening: "مساءا",
      from: "من",
      to: "الى",
      copyright: '©جميع الحقوق محفوظة - جينوم',
    },
  },
};

export default arabic;
