import React from "react";

export const Home = React.lazy(() => import("../containers/Dashboard/Home/Home"));
export const Login = React.lazy(() => import("../containers/Auth/Login/Login"));
export const AgenciesLogin = React.lazy(() => import("../containers/Auth/Agencies/AgenciesLogin/AgenciesLogin"));
export const OTP = React.lazy(() => import("../containers/Auth/OTPForm/OTP"));
export const AgenciesOTP = React.lazy(() => import("../containers/Auth/Agencies/AgenciesOTP/AgenciesOTP"));
export const ForgetPassword = React.lazy(() => import("../containers/Auth/ForgetPassword/ForgetPassword"));
export const AgenciesForgetPassword = React.lazy(() => import("../containers/Auth/Agencies/AgenciesForgetPassword/AgenciesForgetPassword"));
export const SetPassword = React.lazy(() => import("../containers/Auth/SetPassword/SetPassword"));
export const AgenciesSetPassword = React.lazy(() => import("../containers/Auth/Agencies/AgenciesSetPassword/AgenciesSetPassword"));
export const ResetPassword = React.lazy(() => import("../containers/Auth/ResetPassword/ResetPassword"));
export const AgenciesResetPassword = React.lazy(() => import("../containers/Auth/Agencies/AgenciesResetPassword/AgenciesResetPassword"));
export const ResetPasswordSuccessMsg = React.lazy(() => import("../containers/Auth/ResetPasswordSuccessMsg/ResetPasswordSuccess"));
export const AgenciesResetPasswordSuccessMsg = React.lazy(() => import("../containers/Auth/Agencies/AgenciesResetPasswordSuccessMsg/AgenciesResetPasswordSuccess"));
export const Forms = React.lazy(() => import("../containers/Dashboard/Forms/Forms"));
export const Tables = React.lazy(() => import("../containers/Dashboard/Tables/Table"));
export const Profile = React.lazy(() => import("../containers/profile/Profile"));
export const AllRequests = React.lazy(() => import("../containers/Requests/All"));
export const NewRequests = React.lazy(() => import("../containers/Requests/New"));
export const RejectedRequests = React.lazy(() => import("../containers/Requests/Rejected"));
export const ActiveRequests = React.lazy(() => import("../containers/Requests/Active"));
export const CompletedRequests = React.lazy(() => import("../containers/Requests/Completed"));
export const CanceledRequests = React.lazy(() => import("../containers/Requests/Canceled"));
export const Tasks = React.lazy(() => import("../containers/Tasks"));
export const Users = React.lazy(() => import("../containers/Users"));
export const AddUser = React.lazy(() => import("../containers/Users/AddUser"));
export const EditUser = React.lazy(() => import("../containers/Users/EditUser"));
export const InternalUsers = React.lazy(() => import("../containers/Users/Internal"));
export const LabUsers = React.lazy(() => import("../containers/Users/Labs"));
export const AddUsersLab = React.lazy(() => import("../containers/Users/AddLab"));
export const EditUsersLab = React.lazy(() => import("../containers/Users/EditLab"));
export const HospitalsUsers = React.lazy(() => import("../containers/Users/Hospitals"));
export const AddUsersHospital = React.lazy(() => import("../containers/Users/AddHospital"));
export const EditUsersHospital = React.lazy(() => import("../containers/Users/EditHospital"));
export const Programs = React.lazy(() => import("../containers/Programs"));
export const LabTests = React.lazy(() => import("../containers/LabTests"));
export const AddLab = React.lazy(() => import("../containers/LabTests/AddLab"));
export const EditLab = React.lazy(() => import("../containers/LabTests/EditLab"));
export const ReferenceLabs = React.lazy(() => import("../containers/ReferenceLabs"));
export const AddRef = React.lazy(() => import("../containers/ReferenceLabs/AddRef"));
export const EditRef = React.lazy(() => import("../containers/ReferenceLabs/EditRef"));
export const Patients = React.lazy(() => import("../containers/Patients"));
export const MyPatients = React.lazy(() => import("../containers/MyPatients"));
export const AddPatient = React.lazy(() => import("../containers/Patients/AddPatient"));
export const EditPatient = React.lazy(() => import("../containers/Patients/EditPatient"));
export const PatientProfile = React.lazy(() => import("../containers/Patients/PatientProfile"));
export const PatientPreview = React.lazy(() => import("../containers/Requests/Active/PatientPreview"));
export const allAppointments = React.lazy(() => import("../containers/Appointments/All"));
export const newAppointments = React.lazy(() => import("../containers/Appointments/New"));
export const activeAppointments = React.lazy(() => import("../containers/Appointments/Active"));
export const CanceledAppointments = React.lazy(() => import("../containers/Appointments/Canceled"));
export const CompletedAppointments = React.lazy(() => import("../containers/Appointments/Completed"));
export const CurrentAppointment = React.lazy(() => import("../containers/Appointments/Current"));
export const AppointmentStatistics = React.lazy(() => import("../containers/AppointmentStatistics"));
export const Payments = React.lazy(() => import("../containers/Payments"));
export const CoordinatorAppointments = React.lazy(() => import("../containers/CoordinatorAppointments"));
export const Obligations = React.lazy(() => import("../containers/Obligations"));
export const PaymentsDetails = React.lazy(() => import("../containers/PaymentsDetails"));
export const SamplingDetails = React.lazy(() => import("../containers/SamplingDetails"));
export const Samples = React.lazy(() => import("../containers/Samples"));
export const RequestsReports = React.lazy(() => import("../containers/Reports/Requests"));
export const CoordinatorsTasks = React.lazy(() => import("../containers/CoordinatorsTasks"));

export const NotFound = React.lazy(() =>
  import("../components/NotFound/NotFound")
);
