import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "./api";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import * as TYPES from "./types";
import history from "../../routes/history";
import { encodeUserNameAndPass } from "../../utils/Constants";

export function* loginWithOTP({ payload }) {
  try {
    const response = yield call(API.loginWithOTP, payload);
    yield put(ACTIONS.loginWithOTPReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem(
        "encodedString",
        encodeUserNameAndPass(payload.username, payload.password)
      );
      localStorage.setItem("expirationDate", response?.data?.expired_at);
      history.push("/otp");
    }
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* oTPLogin({ payload }) {
  try {
    const response = yield call(API.oTPLogin, payload);
    yield put(ACTIONS.oTPLoginReceive(response?.data));
    if (response?.status === 200) {
      console.log(response);
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("role", response?.data?.roleId);
      localStorage.removeItem("reset_password");
      localStorage.removeItem("reset_token");
      localStorage.removeItem("expirationDate");
      window.location.reload();
      history.push("/dashboard");
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* resendOTP({ payload }) {
  try {
    const response = yield call(API.resendOTP, payload);
    yield put(ACTIONS.resendOTPReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem(
        "encodedString",
        encodeUserNameAndPass(payload.username, payload.password)
      );
      localStorage.setItem("expirationDate", response?.data?.expired_at);
      setTimeout(() => localStorage.removeItem("expirationDate"), 70000);
      if (
        payload?.location === "login" ||
        localStorage.getItem("todo") === "forget_password"
      ) {
        history.push("/otp");
      }
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* forgetPassword({ payload }) {
  try {
    const response = yield call(API.forgetPassword, payload);
    yield put(ACTIONS.forgetPasswordReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem("reset_token", response.data.reset_token);
      if (localStorage.getItem("todo") === "forget_password") {
        history.push("/resetPassword");
      }
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* resetPassword({ payload }) {
  try {
    const response = yield call(API.resetPassword, payload);
    yield put(ACTIONS.resetPasswordReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem("reset_password", true);
      if (!payload?.from_profile) {
        history.push("/resetPassword-success");
      }
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* getUser() {
  try {
    const response = yield call(API.getUser);
    yield put(ACTIONS.getUserReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* logout({ payload }) {
  try {
    const response = yield call(API.logout);
    if (response.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("access_token");
      if (payload) {
        localStorage.removeItem("encodedString");
      }
      history.push("/login");
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

// Agencies

export function* agenciesLoginWithOTP({ payload }) {
  try {
    const response = yield call(API.agenciesLoginWithOTP, payload);
    yield put(ACTIONS.agenciesLoginWithOTPReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem(
        "encodedString",
        encodeUserNameAndPass(payload.username, payload.password)
      );
      localStorage.setItem("expirationDate", response?.data?.expired_at);
      history.push("/agencies/otp");
    }
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* agenciesOTPLogin({ payload }) {
  try {
    const response = yield call(API.agenciesOTPLogin, payload);
    yield put(ACTIONS.agenciesOTPLoginReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("role", response?.data?.roleId);
      localStorage.removeItem("reset_password");
      localStorage.removeItem("reset_token");
      localStorage.removeItem("expirationDate");
      history.push("/dashboard");
      window.location.reload();
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* agenciesResendOTP({ payload }) {
  try {
    const response = yield call(API.agenciesResendOTP, payload);
    yield put(ACTIONS.agenciesResendOTPReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem(
        "encodedString",
        encodeUserNameAndPass(payload.username, payload.password)
      );
      localStorage.setItem("expirationDate", response?.data?.expired_at);
      setTimeout(() => localStorage.removeItem("expirationDate"), 70000);
      if (
        payload?.location === "agencies/login" ||
        localStorage.getItem("todo") === "forget_password"
      ) {
        history.push("/agencies/otp");
      }
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* agenciesForgetPassword({ payload }) {
  try {
    const response = yield call(API.agenciesForgetPassword, payload);
    yield put(ACTIONS.agenciesForgetPasswordReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem("reset_token", response.data.reset_token);
      if (localStorage.getItem("todo") === "forget_password") {
        history.push("/agencies/resetPassword");
      }
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* agenciesResetPassword({ payload }) {
  try {
    const response = yield call(API.agenciesResetPassword, payload);
    yield put(ACTIONS.agenciesResetPasswordReceive(response.data));
    if (response?.status === 200) {
      localStorage.setItem("reset_password", true);
      if (!payload?.from_profile) {
        history.push("/agencies/resetPassword-success");
      }
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* getAgenciesUser() {
  try {
    const response = yield call(API.agenciesGetUser);
    yield put(ACTIONS.agenciesGetUserReceive(response?.data));
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* agenciesLogout({ payload }) {
  try {
    const response = yield call(API.agenciesLogout);
    if (response.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("access_token");
      if (payload) {
        localStorage.removeItem("encodedString");
      }
      history.push("/agencies/login");
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* authSagas() {
  yield takeLatest(TYPES.LOGIN_WITH_OTP_REQUEST, loginWithOTP);
  yield takeLatest(TYPES.OTP_LOGIN_REQUEST, oTPLogin);
  yield takeLatest(TYPES.RESEND_OTP_REQUEST, resendOTP);
  yield takeLatest(TYPES.FORGET_PASSWORD_REQUEST, forgetPassword);
  yield takeLatest(TYPES.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(TYPES.GET_USER_REQUEST, getUser);
  yield takeLatest(TYPES.LOGOUT_REQUEST, logout);
  yield takeLatest(TYPES.AGENCIES_LOGIN_WITH_OTP_REQUEST, agenciesLoginWithOTP);
  yield takeLatest(TYPES.AGENCIES_OTP_LOGIN_REQUEST, agenciesOTPLogin);
  yield takeLatest(TYPES.AGENCIES_RESEND_OTP_REQUEST, agenciesResendOTP);
  yield takeLatest(TYPES.AGENCIES_FORGET_PASSWORD_REQUEST, agenciesForgetPassword);
  yield takeLatest(TYPES.AGENCIES_RESET_PASSWORD_REQUEST, agenciesResetPassword);
  yield takeLatest(TYPES.AGENCIES_LOGOUT_REQUEST, agenciesLogout);
  yield takeLatest(TYPES.AGENCIES_GET_USER_REQUEST, getAgenciesUser);
}
