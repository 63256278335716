import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider } from "react-intl";
import Routes from "../routes";
import messages from "../assets/local/messages";
import history from "../routes/history";
import Loader from "../components/Loader/Loader";
import { MaterialSnackbar } from "../components/Snackbar/Snackbar";
import "./App.scss";
import moment from "moment";
import { arabicDays, arabicMonths } from "../utils/Constants";
import { realTimeNotificationsRequest } from "../store/notifications/actions";
import { requestStatisticsRequest } from "../store/dashboard/actions";
import { getAppointmentsStatisticsRequest } from "../store/appointments/actions";
import { tasksStatisticsRequest } from "../store/Tasks/actions";
import { oldNotificationsRequest, newNotificationsRequest } from "../store/notifications/actions";

// Real Time
 
import Echo from "laravel-echo";
import { newCountRequest } from "../store/payments/actions";

window.io = window.io || require('socket.io-client');
window.Echo = window.Echo || new Echo({
  host: process.env.REACT_APP_SOCKET_URL || "https://laravel.saudigenome.net:6001/",
  broadcaster: 'socket.io',
}); 


const App = () => {
  const loading = useSelector((state) => state.loader)
  const lang = useSelector((state) => state.lang)
  const side_menu = useSelector((state) => state?.dashboard?.side_menu?.data);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const roleId = parseInt(localStorage.getItem("role"));

  useEffect(() => {
    if (user) {
      // Event: NotificationEvent
      window.Echo.channel('notification-channel').listen('.NotificationEvent', ev => {
         if (ev?.data) {
           //console.log(ev?.data)
           if (ev?.data?.user_id === user?.data?.id) {
              dispatch(realTimeNotificationsRequest(ev?.data?.notification_count))
              const payload = roleId === 7 || roleId === 8 ? {type: "agency"} : null
              // old notifications (READED)
              dispatch(oldNotificationsRequest(payload));
              // new notifications (UNREAD) 
              dispatch(newNotificationsRequest(payload));
              if (ev?.data?.type === 'request') {
                !payload && dispatch(requestStatisticsRequest())
              } else if (ev?.data?.type === 'appointment') {
                !payload && dispatch(getAppointmentsStatisticsRequest())
              } else if (ev?.data?.type === 'task') {
                !payload && dispatch(tasksStatisticsRequest())
              }
           }

        } 
      });
    } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (lang === "ar") {
      moment.locale(lang, {
        months: arabicMonths,
        monthsShort: arabicMonths,
        weekdays: arabicDays,
        weekdaysShort: arabicDays,
        meridiem: function (hours, minutes, isLower) {
          return hours < 12
            ? messages[lang].shared.morning
            : messages[lang].shared.evening;
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (roleId === 1 || roleId === 3) {
      dispatch(newCountRequest());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [dispatch, roleId]);

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <div style={{ overflow: "hidden", minHeight: "100vh" }}
        className={`application-container ${lang === "ar" ? "rtl" : "ltr"}`}
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        <MaterialSnackbar />
        <Router history={history}>
          {loading ? <Loader /> : null}
          {Routes}
        </Router>
      </div>
      <div className="footer" style={ lang === 'ar' && side_menu ? {paddingRight: '240px'} : lang === "en" && side_menu ? {paddingLeft: '240px'} : {paddingLeft: 0, paddingRight: 0}  }>
        {`${messages[lang].shared.copyright} @${new Date().getFullYear()}`}
      </div>
    </IntlProvider>
  );
};

export default App;
