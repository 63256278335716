import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Get Statistics
export function* getLabTestsStatisticsSaga() {
  try {
    const { data } = yield call(APIS.getLabTestsStatisticsRequest);
    yield put(ACTIONS.getLabTestsStatisticsReceive(data))
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get LabTests List
export function* getLabTestsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getLabTestsRequest, payload);
    yield put(ACTIONS.getLabTestsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Labtest
export function* getLabTestSaga({payload}) {
  try {
    const { data } = yield call(APIS.getLabTestRequest, payload);
    yield put(ACTIONS.getLabTestReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add LabTest
export function* addLabTestSaga({payload, history}) {
  try {
    yield call(APIS.addLabTestRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getLabTestsRequest({
      page: 1,
      sort: "is_active,desc",
    }));
    history.push("/lab-tests");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit LabTest
export function* editLabTestSaga({payload, history}) {
  try {
    yield call(APIS.editLabTestRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getLabTestsRequest({
      page: 1,
      sort: "is_active,desc",
    }));
    history.push("/lab-tests");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete LabTest
export function* deleteLabTestSaga({payload}) {
  try {
    yield call(APIS.deleteLabTestRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteLabTestReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Active LabTest
export function* activeLabTestSaga({payload}) {
  try {
    yield call(APIS.activeLabTestRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.activeLabTestReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Desactive LabTest
export function* desactiveLabTestSaga({payload}) {
  try {
    yield call(APIS.desactiveLabTestRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.desactiveLabTestReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Change Shipment Status
export function* changeShipmentStatusSaga({payload}) {
  try {
    const { data } = yield call(APIS.changeShipmentStatusRequest, payload);
    yield put(ACTIONS.changeShipmentsStatusReceive(data));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Shipping Locations
export function* getShippingLocationsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getShippingLocationsRequest, payload);
    yield put(ACTIONS.getShippingLocationsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Create Send Labtest
export function* createSendLabTestSaga({payload, history}) {
  try {
    yield put(ACTIONS.setCreatedLabTest(false));
    yield call(APIS.createSendLabTestRequest, payload);
    yield put(ACTIONS.setCreatedLabTest(true));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    yield put(ACTIONS.setCreatedLabTest(false));
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Labtest Working Days
export function* getLabTestWorkingDaysSaga({payload}) {
  try {
    const { data } = yield call(APIS.getLabTestWorkingDaysRequest, payload);
    yield put(ACTIONS.getLabTestWorkingDaysReceive(data, payload.labtest_id));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Set Labtest Working Days
export function* setLabTestWorkingDaysSaga() {
  try {
    yield put(ACTIONS.getLabTestWorkingDaysReceive(null, null));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Obligations
export function* getObligationsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getObligationsRequest, payload);
    yield put(ACTIONS.getObligationsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Sampling Reservations List
export function* getSamplingReservationsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getSamplingReservationsRequest, payload);
    yield put(ACTIONS.getSamplingReservationsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

// Get Samples data
export function* getSamplesDataSaga({payload}) {
  try {
    const { data } = yield call(APIS.getSamplesDataRequest, payload);
    yield put(ACTIONS.getSamplesDataReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function*  labTestsSagas() {
  yield takeLatest(TYPES.GET_LABTESTS_STATISTICS_REQUEST, getLabTestsStatisticsSaga);
  yield takeLatest(TYPES.GET_LABTESTS_REQUEST, getLabTestsSaga);
  yield takeLatest(TYPES.GET_LABTEST_REQUEST, getLabTestSaga);
  yield takeLatest(TYPES.DELETE_LABTEST_REQUEST, deleteLabTestSaga);
  yield takeLatest(TYPES.ACTIVE_LABTEST_REQUEST, activeLabTestSaga);
  yield takeLatest(TYPES.DESACTIVE_LABTEST_REQUEST, desactiveLabTestSaga);
  yield takeLatest(TYPES.ADD_LABTEST_REQUEST, addLabTestSaga);
  yield takeLatest(TYPES.EDIT_LABTEST_REQUEST, editLabTestSaga);
  yield takeLatest(TYPES.CHANGE_SHIPMENTS_STATUS_REQUEST, changeShipmentStatusSaga);
  yield takeLatest(TYPES.GET_SHIPPING_LOCATIONS_REQUEST, getShippingLocationsSaga);
  yield takeLatest(TYPES.CREATE_SEND_LABTEST_REQUEST, createSendLabTestSaga);
  yield takeLatest(TYPES.GET_LABTEST_WORKING_DAYS_REQUEST, getLabTestWorkingDaysSaga);
  yield takeLatest(TYPES.SET_LABTEST_WORKING_DAYS_REQUEST, setLabTestWorkingDaysSaga);
  yield takeLatest(TYPES.GET_OBLIGATIONS_REQUEST, getObligationsSaga);
  yield takeLatest(TYPES.GET_SAMPLING_RESERVATIONS_REQUEST, getSamplingReservationsSaga);
  yield takeLatest(TYPES.GET_SAMPLES_DATA_REQUEST, getSamplesDataSaga);
}


