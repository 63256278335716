import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError ,dispatchSnackbarSuccess} from "../../utils/Shared";
import * as TYPES from "./types";

// handle new requests
function* newRequestsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.newRequestsRequest, payload);
    yield put(
      ACTIONS.newRequestsReceive({
        data,
        setTotal: !payload.search && !payload.requestSource,
      })
    );
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

function* requestSourcesSaga() {
  try {
    const { data } = yield call(APIS.requestSourcesRequest);
    yield put(ACTIONS.requestSourcesReceive(data.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}
function* showRequestSaga(payload) {
  try {
    const { data } = yield call(APIS.showRequest, payload);
    yield put(ACTIONS.showRequestRecieve(data.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//Rejection Reasons for 1 request
function* requestRejectReasonsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.requestRejectionReasons, payload);
    yield put(ACTIONS.requestRejectionReasonsReceive(data.data));
    payload.setModal && payload.setModal();
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

// Rejection Reasons
function* rejectionReasonsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.rejectionReasons);
    yield put(ACTIONS.rejectionReasonsReceive(data.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

// Reject Request
function* rejectRequestSaga({ payload }) {
  try {
    yield call(APIS.rejectRequest, payload);
    payload.setIsOpen && payload.setIsOpen(false)
    yield put(ACTIONS.newRequestsRequest({page: 1, requestStatus: 1, sort: "created_at,desc"}));
    dispatchSnackbarSuccess('success');
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//Accept request`
function* acceptRequestSaga({ payload }) {
  try {
    const { data } = yield call(APIS.acceptRequest, payload);
    yield put(ACTIONS.acceptRequestReceive(data.patient_id));
    yield put(ACTIONS.newRequestsRequest({page: 1, requestStatus: -2, sort: "created_at,desc"}));
    dispatchSnackbarSuccess('success');
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

// Active Requests
function* activeRequestsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.activeRequestsRequest, payload);
    yield put(
      ACTIONS.activeRequestsReceive({
        data,
        setTotal: !payload.search && !payload.requestSource,
      })
    );
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

// Rejected Requests
function* rejectedRequestsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.rejectedRequests, payload);
    yield put(
      ACTIONS.rejectedRequestsReceive({
        data,
        setTotal: !payload.search && !payload.requestSource,
      })
    );
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//All Requests
function* allRequestsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.allRequests, payload);
    yield put(
      ACTIONS.allRequestsReceive({
        data,
        setTotal: !payload.search && !payload.requestSource,
      })
    );
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//Completed Requests
function* completedRequestsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.completedRequests, payload);
    yield put(
      ACTIONS.completedRequestsReceive({
        data,
        setTotal: !payload.search && !payload.requestSource,
      })
    );
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//Canceled Requests
function* canceledRequestsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.canceledRequests, payload);
    yield put(
      ACTIONS.canceledRequestsReceive({
        data,
        setTotal: !payload.search && !payload.requestSource,
      })
    );
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//Patient Requests
function* patientRequestsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.patientRequests, payload);
    yield put(ACTIONS.patientRequestsReceive({ data }));
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//Cancel Request
function* cancelRequestSaga({ payload }) {
  try {
    yield put(ACTIONS.setCanceledRequest(false));
    yield call(APIS.cancelRequest, payload);
    yield put(ACTIONS.setCanceledRequest(true))
    dispatchSnackbarSuccess('success');
  } catch (err) {
    yield put(ACTIONS.setCanceledRequest(false));
    dispatchSnackbarError(err.response.data)
  }
}

//Add New Request
function* addNewRequestSaga({ payload }) {
  try {
    yield put(ACTIONS.setNewRequest(false));
    yield call(APIS.addNewRequest, payload);
    yield put(ACTIONS.addNewRequestReceive());
    dispatchSnackbarSuccess('success');
    yield put(ACTIONS.setNewRequest(false));
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

//Get requests cancel reasons
function* requestsCancelReasonsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.requestsCancelReasons);
    yield put(ACTIONS.requestsCancelReasonsReceive(data.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data)
  }
}

export function* NewRequestsSagas() {
  yield takeLatest(TYPES.NEW_REQUESTS_REQUEST, newRequestsSaga);
  yield takeLatest(TYPES.REQUEST_SOURCES_REQUEST, requestSourcesSaga);
  yield takeLatest(TYPES.SHOW_REQUEST_REQUEST, showRequestSaga);
  yield takeLatest(TYPES.REQUEST_REJECTION_REASONS_REQUEST, requestRejectReasonsSaga);
  yield takeLatest(TYPES.REJECTION_REASONS_REQUEST, rejectionReasonsSaga);
  yield takeLatest(TYPES.REJECT_REQUEST_REQUEST, rejectRequestSaga);
  yield takeLatest(TYPES.ACCEPT_REQUEST_REQUEST, acceptRequestSaga);
  yield takeLatest(TYPES.ACTIVE_REQUESTS_REQUEST, activeRequestsSaga);
  yield takeLatest(TYPES.REJECTED_REQUESTS_REQUEST, rejectedRequestsSaga);
  yield takeLatest(TYPES.ALL_REQUESTS_REQUEST, allRequestsSaga);
  yield takeLatest(TYPES.COMPLETED_REQUESTS_REQUEST, completedRequestsSaga);
  yield takeLatest(TYPES.CANCELED_REQUESTS_REQUEST, canceledRequestsSaga);
  yield takeLatest(TYPES.PATIENT_REQUESTS_REQUEST, patientRequestsSaga);
  yield takeLatest(TYPES.CANCEL_REQUEST_REQUEST, cancelRequestSaga);
  yield takeLatest(TYPES.ADD_NEW_REQUEST_REQUEST, addNewRequestSaga);
  yield takeLatest(TYPES.REQUESTS_CANCEL_REASONS_REQUEST, requestsCancelReasonsSaga);
}
