import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  //dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";


//Get Coordinator statistics
export function* getCoordinatorStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getCoordinatorStatisticsRequest, payload);
    yield put(ACTIONS.getCoordinatorStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Obligations statistics
export function* getObligationsStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getObligationsStatisticsRequest, payload);
    yield put(ACTIONS.getObligationsStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Payments statistics
export function* getCoordinatorPaymentsStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getCoordinatorPaymentsStatisticsRequest, payload);
    yield put(ACTIONS.getCoordinatorPaymentsStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Sampling statistics
export function* getCoordinatorSamplingStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getCoordinatorSamplingStatisticsRequest, payload);
    yield put(ACTIONS.getCoordinatorSamplingStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Sampling Data statistics
export function* getCoordinatorSamplingDataStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getCoordinatorSamplingDataStatisticsRequest, payload);
    yield put(ACTIONS.getCoordinatorSamplingDataStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Coordinator Tasks statistics
export function* getCoordinatorTasksStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getCoordinatorTasksStatisticsRequest, payload);
    yield put(ACTIONS.getCoordinatorTasksStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* statisticsSagas() {
  yield takeLatest(TYPES.GET_COORDINATOR_STATISTICS_REQUEST, getCoordinatorStatisticsSaga);
  yield takeLatest(TYPES.GET_OBLIGATIONS_STATISTICS_REQUEST, getObligationsStatisticsSaga);
  yield takeLatest(TYPES.GET_COORDINATOR_PAYMENTS_STATISTICS_REQUEST, getCoordinatorPaymentsStatisticsSaga);
  yield takeLatest(TYPES.GET_COORDINATOR_SAMPLING_STATISTICS_REQUEST, getCoordinatorSamplingStatisticsSaga);
  yield takeLatest(TYPES.GET_COORDINATOR_SAMPLING_DATA_STATISTICS_REQUEST, getCoordinatorSamplingDataStatisticsSaga);
  yield takeLatest(TYPES.GET_COORDINATOR_TASKS_STATISTICS_REQUEST, getCoordinatorTasksStatisticsSaga);
}


