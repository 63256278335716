//Get Programs Statistics
export const GET_PROGRAMS_STATISTICS_REQUEST = "GET_PROGRAMS_STATISTICS_REQUEST";
export const GET_PROGRAMS_STATISTICS_RECEIVE = "GET_PROGRAMS_STATISTICS_RECEIVE";
//Get Programs
export const GET_PROGRAMS_REQUEST = "GET_PROGRAMS_REQUEST";
export const GET_PROGRAMS_RECEIVE = "GET_PROGRAMS_RECEIVE";
//Get Programs List
export const GET_PROGRAMS_LIST_REQUEST = "GET_PROGRAMS_LIST_REQUEST";
export const GET_PROGRAMS_LIST_RECEIVE = "GET_PROGRAMS_LIST_RECEIVE";
//Add Program
export const ADD_PROGRAM_REQUEST = "ADD_PROGRAM_REQUEST";
export const ADD_PROGRAM_RECEIVE = "ADD_PROGRAM_RECEIVE";
//Edit Program
export const EDIT_PROGRAM_REQUEST = "EDIT_PROGRAM_REQUEST";
export const EDIT_PROGRAM_RECEIVE = "EDIT_PROGRAM_RECEIVE";
//Delete Program
export const DELETE_PROGRAM_REQUEST = "DELETE_PROGRAM_REQUEST";
export const DELETE_PROGRAM_RECEIVE = "DELETE_PROGRAM_RECEIVE";
//Active Program
export const ACTIVE_PROGRAM_REQUEST = "ACTIVE_PROGRAM_REQUEST";
export const ACTIVE_PROGRAM_RECEIVE = "ACTIVE_PROGRAM_RECEIVE";
//Desactive Program
export const DESACTIVE_PROGRAM_REQUEST = "DESACTIVE_PROGRAM_REQUEST";
export const DESACTIVE_PROGRAM_RECEIVE = "DESACTIVE_PROGRAM_RECEIVE";
