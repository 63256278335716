// Get Users List
export const GET_USERS_APPOINTMENTS_REQUEST = "GET_USERS_APPOINTMENTS_REQUEST";
export const GET_USERS_APPOINTMENTS_RECEIVE = "GET_USERS_APPOINTMENTS_RECEIVE";
//Get Appointments List
export const GET_APPOINTMENTS_LIST_REQUEST = "GET_APPOINTMENTS_LIST_REQUEST";
export const GET_APPOINTMENTS_LIST_RECEIVE = "GET_APPOINTMENTS_LIST_RECEIVE";
//Get MY Appointments
export const GET_MY_APPOINTMENTS_REQUEST = "GET_MY_APPOINTMENTS_REQUEST";
export const GET_MY_APPOINTMENTS_RECEIVE = "GET_MY_APPOINTMENTS_RECEIVE";
//Get My Today Appointments
export const GET_MY_TODAY_APPOINTMENTS_REQUEST = "GET_MY_TODAY_APPOINTMENTS_REQUEST";
export const GET_MY_TODAY_APPOINTMENTS_RECEIVE = "GET_MY_TODAY_APPOINTMENTS_RECEIVE";
//Get APPOINTMENTS
export const GET_APPOINTMENTS_REQUEST = "GET_APPOINTMENTS_REQUEST";
export const GET_APPOINTMENTS_RECEIVE = "GET_APPOINTMENTS_RECEIVE";
//Get Upcomming Appointment
export const GET_UPCOMMING_APPOINTMENT_REQUEST = "GET_UPCOMMING_APPOINTMENT_REQUEST";
export const GET_UPCOMMING_APPOINTMENT_RECEIVE = "GET_UPCOMMING_APPOINTMENT_RECEIVE";
//Get Receptionist Appointments List
export const GET_RECEPTIONIST_APPOINTMENTS_LIST_REQUEST = "GET_RECEPTIONIST_APPOINTMENTS_LIST_REQUEST";
export const GET_RECEPTIONIST_APPOINTMENTS_LIST_RECEIVE = "GET_RECEPTIONIST_APPOINTMENTS_LIST_RECEIVE";
//Get APPOINTMENT
export const GET_APPOINTMENT_REQUEST = "GET_APPOINTMENT_REQUEST";
export const GET_APPOINTMENT_RECEIVE = "GET_APPOINTMENT_RECEIVE";
//Add APPOINTMENT
export const ADD_APPOINTMENT_REQUEST = "ADD_APPOINTMENT_REQUEST";
export const ADD_APPOINTMENT_RECEIVE = "ADD_APPOINTMENT_RECEIVE";
//Edit APPOINTMENT
export const EDIT_APPOINTMENT_REQUEST = "EDIT_APPOINTMENT_REQUEST";
export const EDIT_APPOINTMENT_RECEIVE = "EDIT_APPOINTMENT_RECEIVE";
//Delete APPOINTMENT
export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST";
export const DELETE_APPOINTMENT_RECEIVE = "DELETE_APPOINTMENT_RECEIVE";
//Active APPOINTMENT
export const ACTIVE_APPOINTMENT_REQUEST = "ACTIVE_APPOINTMENT_REQUEST";
export const ACTIVE_APPOINTMENT_RECEIVE = "ACTIVE_APPOINTMENT_RECEIVE";
//Change Status Appointment
export const CHANGE_STATUS_APPOINTMENT_REQUEST = "CHANGE_STATUS_APPOINTMENT_REQUEST";
export const CHANGE_STATUS_APPOINTMENT_RECEIVE = "CHANGE_STATUS_APPOINTMENT_RECEIVE";
//Update Change Status Appointment
export const UPDATE_CHANGE_STATUS_APPOINTMENT_REQUEST = "UPDATE_CHANGE_STATUS_APPOINTMENT_REQUEST";
export const UPDATE_CHANGE_STATUS_APPOINTMENT_RECEIVE = "UPDATE_CHANGE_STATUS_APPOINTMENT_RECEIVE";
//Send To Patient
export const SEND_TO_PATIENT_REQUEST = "SEND_TO_PATIENT_REQUEST";
export const SEND_TO_PATIENT_RECEIVE = "SEND_TO_PATIENT_RECEIVE";
//Get Appointment Reasons
export const GET_APPOINTMENT_REASONS_REQUEST = "GET_APPOINTMENT_REASONS_REQUEST";
export const GET_APPOINTMENT_REASONS_RECEIVE = "GET_APPOINTMENT_REASONS_RECEIVE";
//Get Labtests List
export const GET_LABTESTS_LIST_REQUEST = "GET_LABTESTS_LIST_REQUEST";
export const GET_LABTESTS_LIST_RECEIVE = "GET_LABTESTS_LIST_RECEIVE";
//Get Patient Labtests
export const GET_PATIENT_LABTESTS_REQUEST = "GET_PATIENT_LABTESTS_REQUEST";
export const GET_PATIENT_LABTESTS_RECEIVE = "GET_PATIENT_LABTESTS_RECEIVE";
//Get Patient Labtests List
export const GET_PATIENT_LABTESTS_LIST_REQUEST = "GET_PATIENT_LABTESTS_LIST_REQUEST";
export const GET_PATIENT_LABTESTS_LIST_RECEIVE = "GET_PATIENT_LABTESTS_LIST_RECEIVE";
//Add Labtest
export const ADD_APPOINTMENT_LABTEST_REQUEST = "ADD_APPOINTMENT_LABTEST_REQUEST";
export const ADD_APPOINTMENT_LABTEST_RECEIVE = "ADD_APPOINTMENT_LABTEST_RECEIVE";
//Edit Labtest
export const EDIT_APPOINTMENT_LABTEST_REQUEST = "EDIT_APPOINTMENT_LABTEST_REQUEST";
export const EDIT_APPOINTMENT_LABTEST_RECEIVE = "EDIT_APPOINTMENT_LABTEST_RECEIVE";
//Delete Labtest
export const DELETE_APPOINTMENT_LABTEST_REQUEST = "DELETE_APPOINTMENT_LABTEST_REQUEST";
export const DELETE_APPOINTMENT_LABTEST_RECEIVE = "DELETE_APPOINTMENT_LABTEST_RECEIVE";
//Appointments Statistics
export const GET_APPOINTMENTS_STATISTICS_REQUEST = "GET_APPOINTMENTS_STATISTICS_REQUEST";
export const GET_APPOINTMENTS_STATISTICS_RECEIVE = "GET_APPOINTMENTS_STATISTICS_RECEIVE";
//Appointments Statistics block
export const GET_APPOINTMENTS_STATISTICS_BLOCK_REQUEST = "GET_APPOINTMENTS_STATISTICS_BLOCK_REQUEST";
export const GET_APPOINTMENTS_STATISTICS_BLOCK_RECEIVE = "GET_APPOINTMENTS_STATISTICS_BLOCK_RECEIVE";
//Appointments Users List
export const GET_APPOINTMENTS_USERS_LIST_REQUEST = "GET_APPOINTMENTS_USERS_LIST_REQUEST";
export const GET_APPOINTMENTS_USERS_LIST_RECEIVE = "GET_APPOINTMENTS_USERS_LIST_RECEIVE";
//Set Added Reason
export const SET_ADDED_REASON = "SET_ADDED_REASON";
//set added appointment
export const SET_ADDED_APPOINTMENT = "SET_ADDED_APPOINTMENT";
//Set Sent To Patient
export const SET_SENT_TO_PATIENT = "SET_SENT_TO_PATIENT";


