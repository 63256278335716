export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;
export const tableContent = {
  paging: {
    next: true,
    prev: false,
    total_pages: 2,
    total_records: 11,
  },
  data: [
    {
      city_name_ar: "الرياض",
      city_name_en: "Riyadh",
      country_name_ar: "المملكة العربية السعودية",
      country_name_en: "Saudi Arabia",
      id: 1,
      location_name_ar: "العنوان الاول",
      location_name_en: "Address 1 11",
      name_ar: "الفرع الاول",
      name_en: "Branch 1",
    },
    {
      city_name_ar: "الرياض",
      city_name_en: "Riyadh",
      country_name_ar: "المملكة العربية السعودية",
      country_name_en: "Saudi Arabia",
      id: 1,
      location_name_ar: "العنوان الثانى",
      location_name_en: "Address 222",
      name_ar: "الفرع الثانى",
      name_en: "Branch 2",
    },
    {
      city_name_ar: "الرياض",
      city_name_en: "Riyadh",
      country_name_ar: "المملكة العربية السعودية",
      country_name_en: "Saudi Arabia",
      id: 1,
      location_name_ar: "العنوان الثالث",
      location_name_en: "Address 333",
      name_ar: "الفرع الثالث",
      name_en: "Branch 3",
    },
  ],
};

export const moviesArr = [
  { title_en: "The Shawshank Redemption", title_ar: "شوشنك", year: 1994 },
  { title_en: "The Godfather", title_ar: "الاب الروحى", year: 1972 },
  { title_en: "The Godfather: Part II", title_ar: "الاب الروحى 2", year: 1974 },
  { title_en: "The Dark Knight", title_ar: "الليلة السوداء", year: 2008 },
  { title_en: "12 Angry Men", title_ar: "الرجل الغاضب", year: 1957 },
  { title_en: "Schindler's List", title_ar: "ليست", year: 1993 },
  { title_en: "Pulp Fiction", title_ar: "فيكشن", year: 1994 },
];

export const encodeUserNameAndPass = (userName, password) => {
  var decodedStringBtoA = `[userName]${userName}[password]${password}`;
  return btoa(decodedStringBtoA);
};

export const decodeUserNameAndPass = (str) => {
  return atob(str);
};

export const fileSupportedFormats = [
  "image/JPEG",
  "image/JPG",
  "image/jpg",
  "image/png",
  "image/PNG",
  "image/jpeg",
];

export const arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "يونيو",
  "يوليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const arabicDays = [
  "الاحد",
  "الاثنين",
  "الثلاثاء",
  "الاربعاء",
  "الخميس",
  "الجمعة",
  "السبت",
];