import * as TYPES from "./types";
//Get Programs Statistics
export const getProgramsStatisticsRequest = () => ({
  type: TYPES.GET_PROGRAMS_STATISTICS_REQUEST,
});
export const getProgramsStatisticsReceive = (payload) => ({
  type: TYPES.GET_PROGRAMS_STATISTICS_RECEIVE,
  payload,
});
//Get Programs
export const getProgramsRequest = (payload) => ({
  type: TYPES.GET_PROGRAMS_REQUEST,
  payload,
});
export const getProgramsReceive = (payload) => ({
  type: TYPES.GET_PROGRAMS_RECEIVE,
  payload,
});
//Get Programs List
export const getProgramsListRequest = (payload) => ({
  type: TYPES.GET_PROGRAMS_LIST_REQUEST,
  payload,
});
export const getProgramsListReceive = (payload) => ({
  type: TYPES.GET_PROGRAMS_LIST_RECEIVE,
  payload,
});
//Delete Program
export const deleteProgramRequest = (payload) => ({
  type: TYPES.DELETE_PROGRAM_REQUEST,
  payload,
});
export const deleteProgramReceive = (payload) => ({
  type: TYPES.DELETE_PROGRAM_RECEIVE,
  payload,
});
//Active Program
export const activeProgramRequest = (payload) => ({
  type: TYPES.ACTIVE_PROGRAM_REQUEST,
  payload,
});
export const activeProgramReceive = (payload) => ({
  type: TYPES.ACTIVE_PROGRAM_RECEIVE,
  payload,
});
//Desactive Program
export const desactiveProgramRequest = (payload) => ({
  type: TYPES.DESACTIVE_PROGRAM_REQUEST,
  payload,
});
export const desactiveProgramReceive = (payload) => ({
  type: TYPES.DESACTIVE_PROGRAM_RECEIVE,
  payload,
});
//Add Program
export const addProgramRequest = (payload) => ({
  type: TYPES.ADD_PROGRAM_REQUEST,
  payload,
});
export const addProgramReceive = (payload) => ({
  type: TYPES.ADD_PROGRAM_RECEIVE,
  payload,
});
//Edit Program
export const editProgramRequest = (payload) => ({
  type: TYPES.EDIT_PROGRAM_REQUEST,
  payload,
});
export const editProgramReceive = (payload) => ({
  type: TYPES.EDIT_PROGRAM_RECEIVE,
  payload,
});