import * as TYPES from "./types";

const initialState = {
  referenceLabsList: [],
  referenceLabs: [],
  referenceLab: {},
  countries: [],
  refsStatistics: {},
};

export default function referenceLabsReducer(state = initialState, action) {
  switch (action.type) {
    //Statistics
    case TYPES.GET_REFS_STATISTICS_RECEIVE: {
      return {
        ...state,
        refsStatistics: action.payload.statistics,
      }
    }
    // Get ReferenceLabs List for LabTests
    case TYPES.GET_REFERENCELABS_LIST_RECEIVE: {
      return {
        ...state,
        referenceLabsList: action.payload.data,
      }
    }
    //Statistics
    case TYPES.GET_REFERENCELABS_RECEIVE: {
      return {
        ...state,
        referenceLabs: action.payload,
      }
    }
    //Get ReferenceLab
    case TYPES.GET_REFERENCELAB_RECEIVE: {
      return {
        ...state,
        referenceLab: action.payload.data,
      }
    }
   /*  case TYPES.ADD_REFERENCELAB_RECEIVE: {
      return {
        ...state,
        referenceLabs: [...state.referenceLabs, action.payload],
      }
    }
    case TYPES.EDIT_REFERENCELAB_RECEIVE: {
      return {
        ...state,
        referenceLabs: state.referenceLabs.map((referenceLab) => referenceLab.id === action.payload.id ? referenceLab = action.payload : referenceLab),
      }
    } */
    case TYPES.DELETE_REFERENCELAB_RECEIVE: {
      return {
        ...state,
        referenceLabs: { ...state.referenceLabs, data: state.referenceLabs?.data?.filter((user) => user.id !== action.payload) }
      }
    }
    case TYPES.ACTIVE_REFERENCELAB_RECEIVE: {
      return {
        ...state,
        referenceLabs: { ...state.referenceLabs, data: state.referenceLabs?.data?.map((referenceLab) => {
          if (referenceLab.id === action.payload) {
            return { ...referenceLab, is_active: 1 }
          }
          return referenceLab
        }) }
      }
    }
    case TYPES.DESACTIVE_REFERENCELAB_RECEIVE: {
      return {
        ...state,
        referenceLabs: { ...state.referenceLabs, data: state.referenceLabs?.data?.map((referenceLab) => {
          if (referenceLab.id === action.payload) {
            return { ...referenceLab, is_active: 0 }
          }
          return referenceLab
        }) }
      }
    }
    //Get Countries List
    case TYPES.GET_COUNTRIES_LIST_RECEIVE: {
      return {
        ...state,
        countries: action.payload.data,
      }
    }
    default:
      return state
  }
}
