import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";


//Get Users List
export function* getAppointmentsByUsersSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAppointmentsByUsersRequest, payload);
    yield put(ACTIONS.getAppointmentsByUsersReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get My Appointments
export function* getMyAppointmentsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getMyAppointmentsRequest, payload);
    yield put(ACTIONS.getMyAppointmentsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get My Today Appointments
export function* getMyTodayAppointmentsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getMyTodayAppointmentsRequest, payload);
    yield put(ACTIONS.getMyTodayAppointmentsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Appointments List
export function* getAppointmentsListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAppointmentsListRequest, payload);
    yield put(ACTIONS.getAppointmentsListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Appointments
export function* getAppointmentsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAppointmentsRequest, payload);
    yield put(ACTIONS.getAppointmentsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Upcomming Appointment
export function* getUpcommingAppointmentSaga() {
  try {
    const { data } = yield call(APIS.getUpcommingAppointmentRequest);
    yield put(ACTIONS.getUpcommingAppointmentReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Receptionist Appointments List
export function* getReceptionistAppointmentsListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getReceptionistAppointmentsListRequest, payload);
    yield put(ACTIONS.getReceptionistAppointmentsListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Appointment
export function* getAppointmentSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAppointmentRequest, payload);
    yield put(ACTIONS.getAppointmentReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add Appointment
export function* addAppointmentSaga({payload, history, patientId}) {
  try {
    yield put(ACTIONS.setAddedAppointment(false));
    yield call(APIS.addAppointmentRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getAppointmentsRequest({
      page: 1,
      sort: "created_at,desc",
      patientId: payload.patient_id,
    }));
    yield put(ACTIONS.setAddedAppointment(true));
    history && history.push("/newRequests")
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit Appointment
export function* editAppointmentSaga({payload, history}) {
  try {
    yield put(ACTIONS.setAddedAppointment(false));
    yield call(APIS.editAppointmentRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getAppointmentsRequest({
      page: 1,
      patientId: payload.patient_id,
      sort: "created_at,desc",
    }));
    yield put(ACTIONS.setAddedAppointment(true));
    history.push("/clients");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete Appointment
export function* deleteAppointmentSaga({payload}) {
  try {
    yield call(APIS.deleteAppointmentRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteAppointmentReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Active Appointment
export function* activeAppointmentSaga({payload, history}) { 
  try {
    if (payload.status === 3) {
      history.push(`/appointment/${payload}`);
    } else {
      yield call(APIS.activeAppointmentRequest, payload);
      history.push(`/appointment/${payload}`);
    }
    // dispatchSnackbarSuccess("success");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Change Status Appointment
export function* changeStatusAppointmentSaga({payload}) {
  try {
    yield put(ACTIONS.setAddedReason(false));
    yield call(APIS.changeStatusAppointmentRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.setAddedReason(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Update Change Status Appointment
export function* updateChangeStatusAppointmentSaga({payload}) {
  try {
    yield put(ACTIONS.setAddedReason(false));
    yield call(APIS.updateChangeStatusAppointmentRequest, payload);
    dispatchSnackbarSuccess("success"); 
    yield put(ACTIONS.setAddedReason(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Send To Patient
export function* sendToPatientSaga({payload}) {
  try {
    yield put(ACTIONS.setSentToPatient(false));
    yield call(APIS.sendToPatientRequest, payload);
    dispatchSnackbarSuccess("sent");
    yield put(ACTIONS.setSentToPatient(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Appointment Reasons
export function* getAppointmentReasonsSaga() {
  try {
    const { data } = yield call(APIS.getAppointmentReasonsRequest);
    yield put(ACTIONS.getAppointmentReasonsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Labtests List
export function* getLabtestsListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getLabtestsListRequest, payload);
    yield put(ACTIONS.getLabtestsListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Patient Labtests
export function* getPatientLabtestsSaga({payload, patient_id}) {
  try {
    const { data } = yield call(APIS.getPatientLabtestsRequest, payload, patient_id);
    yield put(ACTIONS.getPatientLabtestsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Patient Labtests List
export function* getPatientLabtestsListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPatientLabtestsListRequest, payload); 
    yield put(ACTIONS.getPatientLabtestsListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add Labtest
export function* addAppointmentLabtestSaga({payload}) {
  try {
    yield put(ACTIONS.setAddedAppointment(false));
    yield call(APIS.addAppointmentLabtestRequest, payload);
    yield put(ACTIONS.getPatientLabtestsRequest({
      page: 1,
      sort: "created_at,desc",
      requestId: payload.request_id,
    }, payload.patient_id));
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.setAddedAppointment(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit Labtest
export function* editAppointmentLabtestSaga({payload}) {
  try {
    yield put(ACTIONS.setAddedAppointment(false));
    yield call(APIS.editAppointmentLabtestRequest, payload);
    yield put(ACTIONS.getPatientLabtestsRequest({
      page: 1,
      sort: "created_at,desc",
      requestId: payload.request_id,
    }, payload.patient_id));   
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.setAddedAppointment(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete Labtest
export function* deleteAppointmentLabtestSaga({payload, patient_id}) {
  try {
    yield call(APIS.deleteAppointmentLabtestRequest, payload, patient_id);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getPatientLabtestsRequest({
      page: 1,
      sort: "created_at,desc",
      requestId: payload.request_id,
    }, patient_id));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Appointments Statistics
export function* getAppointmentsStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAppointmentsStatisticsRequest, payload);
    yield put(ACTIONS.getAppointmentsStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Appointments Statistics Block
export function* getAppointmentsStatisticsBlockSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAppointmentsStatisticsBlockRequest, payload);
    yield put(ACTIONS.getAppointmentsStatisticsBlockReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Appointments Users List
export function* getAppointmentsUsersListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAppointmentsUsersListRequest, payload);
    yield put(ACTIONS.getAppointmentsUsersListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* appointmentsSagas() {
  yield takeLatest(TYPES.GET_USERS_APPOINTMENTS_REQUEST, getAppointmentsByUsersSaga);
  yield takeLatest(TYPES.GET_APPOINTMENTS_LIST_REQUEST, getAppointmentsListSaga);
  yield takeLatest(TYPES.GET_MY_APPOINTMENTS_REQUEST, getMyAppointmentsSaga);
  yield takeLatest(TYPES.GET_MY_TODAY_APPOINTMENTS_REQUEST, getMyTodayAppointmentsSaga);
  yield takeLatest(TYPES.GET_APPOINTMENTS_REQUEST, getAppointmentsSaga);
  yield takeLatest(TYPES.GET_UPCOMMING_APPOINTMENT_REQUEST, getUpcommingAppointmentSaga);
  yield takeLatest(TYPES.GET_APPOINTMENT_REQUEST, getAppointmentSaga);
  yield takeLatest(TYPES.DELETE_APPOINTMENT_REQUEST, deleteAppointmentSaga);
  yield takeLatest(TYPES.ACTIVE_APPOINTMENT_REQUEST, activeAppointmentSaga);
  yield takeLatest(TYPES.CHANGE_STATUS_APPOINTMENT_REQUEST, changeStatusAppointmentSaga);
  yield takeLatest(TYPES.UPDATE_CHANGE_STATUS_APPOINTMENT_REQUEST, updateChangeStatusAppointmentSaga);
  yield takeLatest(TYPES.SEND_TO_PATIENT_REQUEST, sendToPatientSaga);
  yield takeLatest(TYPES.GET_APPOINTMENT_REASONS_REQUEST, getAppointmentReasonsSaga);
  yield takeLatest(TYPES.ADD_APPOINTMENT_REQUEST, addAppointmentSaga);
  yield takeLatest(TYPES.EDIT_APPOINTMENT_REQUEST, editAppointmentSaga);
  yield takeLatest(TYPES.GET_LABTESTS_LIST_REQUEST, getLabtestsListSaga);
  yield takeLatest(TYPES.GET_PATIENT_LABTESTS_REQUEST, getPatientLabtestsSaga);
  yield takeLatest(TYPES.GET_PATIENT_LABTESTS_LIST_REQUEST, getPatientLabtestsListSaga);
  yield takeLatest(TYPES.ADD_APPOINTMENT_LABTEST_REQUEST, addAppointmentLabtestSaga);
  yield takeLatest(TYPES.EDIT_APPOINTMENT_LABTEST_REQUEST, editAppointmentLabtestSaga);
  yield takeLatest(TYPES.DELETE_APPOINTMENT_LABTEST_REQUEST, deleteAppointmentLabtestSaga);
  yield takeLatest(TYPES.GET_APPOINTMENTS_STATISTICS_REQUEST, getAppointmentsStatisticsSaga);
  yield takeLatest(TYPES.GET_APPOINTMENTS_STATISTICS_BLOCK_REQUEST, getAppointmentsStatisticsBlockSaga);
  yield takeLatest(TYPES.GET_APPOINTMENTS_USERS_LIST_REQUEST, getAppointmentsUsersListSaga);
  yield takeLatest(TYPES.GET_RECEPTIONIST_APPOINTMENTS_LIST_REQUEST, getReceptionistAppointmentsListSaga);
}


