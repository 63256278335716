import * as TYPES from "./types";

export const newRequestsRequest = (payload) => ({
  type: TYPES.NEW_REQUESTS_REQUEST,
  payload,
});
export const newRequestsReceive = (payload) => ({
  type: TYPES.NEW_REQUESTS_RECEIVE,
  payload,
});
export const requestSourcesRequest = () => ({
  type: TYPES.REQUEST_SOURCES_REQUEST,
});
export const requestSourcesReceive = (payload) => ({
  type: TYPES.REQUEST_SOURCES_RECEIVE,
  payload,
});
export const showRequestRequest = (payload) => ({
  type: TYPES.SHOW_REQUEST_REQUEST,
  payload,
});
export const showRequestRecieve = (payload) => ({
  type: TYPES.SHOW_REQUEST_RECEIVE,
  payload,
});
export const requestRejectionReasonsRequest = (payload) => ({
  type: TYPES.REQUEST_REJECTION_REASONS_REQUEST,
  payload,
});
export const requestRejectionReasonsReceive = (payload) => ({
  type: TYPES.REQUEST_REJECTION_REASONS_RECEIVE,
  payload,
});
//Rejection Reasons
export const rejectionReasonsRequest = (payload) => ({
  type: TYPES.REJECTION_REASONS_REQUEST,
  payload,
});
export const rejectionReasonsReceive = (payload) => ({
  type: TYPES.REJECTION_REASONS_RECEIVE,
  payload,
});
//Reject Request
export const rejectRequestRequest = (payload) => ({
  type: TYPES.REJECT_REQUEST_REQUEST,
  payload,
});
//Accept request
export const acceptRequestRequest = (payload) => ({
  type: TYPES.ACCEPT_REQUEST_REQUEST,
  payload,
});
export const acceptRequestReceive = (payload) => ({
  type: TYPES.ACCEPT_REQUEST_RECEIVE,
  payload,
});
//Active Requests
export const activeRequestsRequest = (payload) => ({
  type: TYPES.ACTIVE_REQUESTS_REQUEST,
  payload,
});
export const activeRequestsReceive = (payload) => ({
  type: TYPES.ACTIVE_REQUESTS_RECEIVE,
  payload,
});
//Rejected Requests
export const rejectedRequestsRequest = (payload) => ({
  type: TYPES.REJECTED_REQUESTS_REQUEST,
  payload,
})
export const rejectedRequestsReceive = (payload) => ({
  type: TYPES.REJECTED_REQUESTS_RECEIVE,
  payload,
})
//All Requests
export const allRequestsRequest = (payload) => ({
  type: TYPES.ALL_REQUESTS_REQUEST,
  payload,
});
export const allRequestsReceive = (payload) => ({
  type: TYPES.ALL_REQUESTS_RECEIVE,
  payload,
});
//Completed Requests
export const completedRequestsRequest = (payload) => ({
  type: TYPES.COMPLETED_REQUESTS_REQUEST,
  payload,
});
export const completedRequestsReceive = (payload) => ({
  type: TYPES.COMPLETED_REQUESTS_RECEIVE,
  payload,
});
//Canceled requests
export const canceledRequestsRequest = (payload) => ({  
  type: TYPES.CANCELED_REQUESTS_REQUEST,
  payload,
});
export const canceledRequestsReceive = (payload) => ({
  type: TYPES.CANCELED_REQUESTS_RECEIVE,
  payload,
});
//Patient Requests
export const patientRequestsRequest = (payload) => ({
  type: TYPES.PATIENT_REQUESTS_REQUEST,
  payload,
});
export const patientRequestsReceive = (payload) => ({
  type: TYPES.PATIENT_REQUESTS_RECEIVE,
  payload,
});
//Cancel Request
export const cancelRequestRequest = (payload) => ({
  type: TYPES.CANCEL_REQUEST_REQUEST,
  payload,
});
export const cancelRequestReceive = (payload) => ({
  type: TYPES.CANCEL_REQUEST_RECEIVE,
  payload,
});
//Set Canceled Request
export const setCanceledRequest = (payload) => ({
  type: TYPES.SET_CANCELED_REQUEST,
  payload,
});
// Add New Request
export const addNewRequestRequest = (payload) => ({
  type: TYPES.ADD_NEW_REQUEST_REQUEST,
  payload,
});
export const addNewRequestReceive = () => ({
  type: TYPES.ADD_NEW_REQUEST_RECEIVE,
});
// Set New Request
export const setNewRequest = (payload) => ({
  type: TYPES.SET_REQUEST_ADDED,
  payload,
});
//Get Requests cancel reasons
export const requestsCancelReasonsRequest = (payload) => ({
  type: TYPES.REQUESTS_CANCEL_REASONS_REQUEST,
  payload,
});
export const requestsCancelReasonsReceive = (payload) => ({
  type: TYPES.REQUESTS_CANCEL_REASONS_RECEIVE,
  payload,
});