import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "./api";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import * as TYPES from "./types";

export function* oldNotificationsRequest({ payload }) {
  try {
    const response = yield call(API.oldNotificationsRequest, payload);
    yield put(ACTIONS.oldNotificationsReceive(response?.data));
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* newNotificationsRequest({ payload }) {
  try {
    const response = yield call(API.newNotificationsRequest, payload);
    yield put(ACTIONS.newNotificationsReceive(response?.data));
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* countNotificationsRequest({ payload }) {
  try {
    const response = yield call(API.countNotificationsRequest, payload);
    yield put(ACTIONS.countNotificationsReceive(response?.data));
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* markAsReadedRequest({ payload }) {
  try {
    yield call(API.markAsReadedRequest, payload);
    yield put(ACTIONS.markAsReadedReceive(payload));
    yield put(ACTIONS.countNotificationsRequest());
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

// Real Time Receive
export function* realTimeNotificationsRequest({ payload }) {
  try {
    console.log("realTimeNotificationsRequest", payload);
    yield put(ACTIONS.realTimeNotificationsReceive(payload));
  } catch (err) {
    console.log(err?.response?.data);
  }
}

export function* notificationsSagas() {
  yield takeLatest(TYPES.OLD_NOTIFICATIONS_REQUEST, oldNotificationsRequest);
  yield takeLatest(TYPES.NEW_NOTIFICATIONS_REQUEST, newNotificationsRequest);
  yield takeLatest(TYPES.COUNT_NOTIFICATIONS_REQUEST, countNotificationsRequest);
  yield takeLatest(TYPES.MARK_ASREADED_REQUEST, markAsReadedRequest);
  yield takeLatest(TYPES.REALTIME_NOTIFICATIONS_REQUEST, realTimeNotificationsRequest);
}
