import * as TYPES from "./types";

const initialState = {
  labTest: {},
  labTests: [],
  labTestsStatistics: {},
  shipmentStatus: null,
  shippingLocations: [],
  labtestCreated: false,
  labtestWorkingDays: null,
  obligationsList: {},
  samplingReservationsList: {},
  samplesList: {},
};

export default function labTestsReducer(state = initialState, action) {
  switch (action.type) {
    //Statistics
    case TYPES.GET_LABTESTS_STATISTICS_RECEIVE: {
      return {
        ...state,
        labTestsStatistics: action.payload.statistics,
      }
    }
    case TYPES.GET_LABTESTS_RECEIVE: {
      return {
        ...state,
        labTests: action.payload,
      }
    }
    case TYPES.GET_LABTEST_RECEIVE: {
      return {
        ...state,
        labTest: action.payload.data,
      }
    }
    case TYPES.DELETE_LABTEST_RECEIVE: {
      return {
        ...state,
        labTests: { ...state.labTests, data: state.labTests?.data?.filter((user) => user.id !== action.payload) }
      }
    }
    case TYPES.ACTIVE_LABTEST_RECEIVE: {
      return {
        ...state,
        labTests: { ...state.labTests, data: state.labTests?.data?.map((labTest) => {
          if (labTest.id === action.payload) {
            return { ...labTest, is_active: 1 }
          }
          return labTest
        }) }
      }
    }
    case TYPES.DESACTIVE_LABTEST_RECEIVE: {
      return {
        ...state,
        labTests: { ...state.labTests, data: state.labTests?.data?.map((labTest) => {
          if (labTest.id === action.payload) {
            return { ...labTest, is_active: 0 }
          }
          return labTest
        }) }
      }
    }
    //Shipment Status
    case TYPES.CHANGE_SHIPMENTS_STATUS_RECEIVE: {
      return {
        ...state,
        shipmentStatus: action.payload,
      }
    }
    //Shipping Locations
    case TYPES.GET_SHIPPING_LOCATIONS_RECEIVE: {
      return {
        ...state,
        shippingLocations: action.payload.data,
      }
    }
    //Create send labtest
    case TYPES.CREATE_SEND_LABTEST_RECEIVE: { 
      return {
        ...state,
        labtestCreated: true, 
      }
    }
    //Set created labtest
    case TYPES.SET_CREATED_LABTEST: {
      return {
        ...state,
        labtestCreated: action.payload,
      }
    }
    //Get labtest working days
    case TYPES.GET_LABTEST_WORKING_DAYS_RECEIVE: {
      return {
        ...state,
        labtestWorkingDays: {...action.payload.data, labtestID: action.labtestID},
      }
    }
    //Get obligations
    case TYPES.GET_OBLIGATIONS_RECEIVE: {
      return {
        ...state,
        obligationsList: action.payload,
      }
    }
    //Get sampling reservations
    case TYPES.GET_SAMPLING_RESERVATIONS_RECEIVE: {
      return {
        ...state,
        samplingReservationsList: action.payload,
      }
    }
    //Get samples Data
    case TYPES.GET_SAMPLES_DATA_RECEIVE: {
      return {
        ...state,
        samplesList: action.payload,
      }
    }
    default:
      return state
  }
}
