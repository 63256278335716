import * as TYPES from "./types";

//Get Statistics
export const getLabTestsStatisticsRequest = () => ({
  type: TYPES.GET_LABTESTS_STATISTICS_REQUEST,
});
export const getLabTestsStatisticsReceive = (payload) => ({
  type: TYPES.GET_LABTESTS_STATISTICS_RECEIVE,
  payload,
});
//Get LabTests List
export const getLabTestsRequest = (payload) => ({
  type: TYPES.GET_LABTESTS_REQUEST,
  payload,
});
export const getLabTestsReceive = (payload) => ({
  type: TYPES.GET_LABTESTS_RECEIVE,
  payload,
});
//Get Labtest
export const getLabTestRequest = (payload) => ({
  type: TYPES.GET_LABTEST_REQUEST,
  payload,
});
export const getLabTestReceive = (payload) => ({
  type: TYPES.GET_LABTEST_RECEIVE,
  payload,
});
//Delete LabTest
export const deleteLabTestRequest = (payload) => ({
  type: TYPES.DELETE_LABTEST_REQUEST,
  payload,
});
export const deleteLabTestReceive = (payload) => ({
  type: TYPES.DELETE_LABTEST_RECEIVE,
  payload,
});
//Active LabTest
export const activeLabTestRequest = (payload) => ({
  type: TYPES.ACTIVE_LABTEST_REQUEST,
  payload,
});
export const activeLabTestReceive = (payload) => ({
  type: TYPES.ACTIVE_LABTEST_RECEIVE,
  payload,
});
//Desactive LabTest
export const desactiveLabTestRequest = (payload) => ({
  type: TYPES.DESACTIVE_LABTEST_REQUEST,
  payload,
});
export const desactiveLabTestReceive = (payload) => ({
  type: TYPES.DESACTIVE_LABTEST_RECEIVE,
  payload,
});
//Add LabTest
export const addLabTestRequest = (payload, history) => ({
  type: TYPES.ADD_LABTEST_REQUEST,
  payload,
  history,
});
export const addLabTestReceive = (payload) => ({
  type: TYPES.ADD_LABTEST_RECEIVE,
  payload,
});
//Edit LabTest
export const editLabTestRequest = (payload, history) => ({
  type: TYPES.EDIT_LABTEST_REQUEST,
  payload,
  history,
});
export const editLabTestReceive = (payload) => ({
  type: TYPES.EDIT_LABTEST_RECEIVE,
  payload,
});
// Change Shipments Status
export const changeShipmentsStatusRequest = (payload) => ({
  type: TYPES.CHANGE_SHIPMENTS_STATUS_REQUEST,
  payload,
});
export const changeShipmentsStatusReceive = (payload) => ({
  type: TYPES.CHANGE_SHIPMENTS_STATUS_RECEIVE,
  payload,
});
// Get Shipping Locations
export const getShippingLocationsRequest = (payload) => ({
  type: TYPES.GET_SHIPPING_LOCATIONS_REQUEST,
  payload,
});
export const getShippingLocationsReceive = (payload) => ({
  type: TYPES.GET_SHIPPING_LOCATIONS_RECEIVE,
  payload,
});
//Create Send Labtest
export const createSendLabTestRequest = (payload) => ({
  type: TYPES.CREATE_SEND_LABTEST_REQUEST,
  payload,
});
export const createSendLabTestReceive = (payload) => ({
  type: TYPES.CREATE_SEND_LABTEST_RECEIVE,
  payload,
});
//Set created labtest
export const setCreatedLabTest = (payload) => ({
  type: TYPES.SET_CREATED_LABTEST,
  payload,
});
//Get Labtest Working Days
export const getLabTestWorkingDaysRequest = (payload) => ({
  type: TYPES.GET_LABTEST_WORKING_DAYS_REQUEST,
  payload,
});
export const getLabTestWorkingDaysReceive = (payload, labtestID) => ({
  type: TYPES.GET_LABTEST_WORKING_DAYS_RECEIVE,
  payload,
  labtestID,
});
//Set Labtest Working Days
export const setLabTestWorkingDaysRequest = () => ({
  type: TYPES.SET_LABTEST_WORKING_DAYS_REQUEST,
});
//Get Obligations
export const getObligationsRequest = (payload) => ({
  type: TYPES.GET_OBLIGATIONS_REQUEST,
  payload,
});
export const getObligationsReceive = (payload) => ({
  type: TYPES.GET_OBLIGATIONS_RECEIVE,
  payload,
});
//Get Sampling Reservations
export const getSamplingReservationsRequest = (payload) => ({
  type: TYPES.GET_SAMPLING_RESERVATIONS_REQUEST,
  payload,
});
export const getSamplingReservationsReceive = (payload) => ({
  type: TYPES.GET_SAMPLING_RESERVATIONS_RECEIVE,
  payload,
});
// Get Samples Data
export const getSamplesDataRequest = (payload) => ({
  type: TYPES.GET_SAMPLES_DATA_REQUEST,
  payload,
});
export const getSamplesDataReceive = (payload) => ({
  type: TYPES.GET_SAMPLES_DATA_RECEIVE,
  payload,
});