import React, { useRef ,useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import history from "../../routes/history";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
//import PersonIcon from "@material-ui/icons/Person";
import { agenciesGetUserRequest, getUserRequest } from "../../store/authentication/actions";
import { calendarIcon } from "../../utils/images";
import "./header.scss";
import LanguageBtn from "../LanguageBtn/LanguageBtn";
import Notifications from "./Notifications";
import { countNotificationsRequest } from "../../store/notifications/actions";
import { useLocation } from "react-router-dom";
import { profilePhoto } from "../../utils/images";

const Header = ({ intl: { messages } }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state?.auth?.user?.data);
  const { countNotifications } = useSelector((state) => state?.notifications);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationsRef = useRef();
  const [headerTitle, setHeaderTitle] = useState(`${messages.header.hello} ${user?.name} !`)
  const roleId = parseInt(localStorage.getItem("role"));
  const lang = localStorage.getItem("lang")

  useEffect(() => {
    if (roleId === 7 || roleId === 8) {
      dispatch(agenciesGetUserRequest());
    } else {
      dispatch(getUserRequest());
      dispatch(countNotificationsRequest(roleId === 7 || roleId === 8 ? { type: "agency" } : null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, roleId]);

  // Hook to check if the user is clicking outside notifications list to close it
  useEffect(() => {
    function handleClickOutside(event) {
        if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
            setShowNotifications(false);
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Set Header Title
  useEffect(() => {
    if (location && location.pathname && user) {
      location.pathname === '/newRequests' ? setHeaderTitle(`${messages.header.title.newRequests}`)
      : location.pathname === '/allRequests' ? setHeaderTitle(`${messages.header.title.allRequests}`)
      : location.pathname === '/activeRequests' ? setHeaderTitle(`${messages.header.title.activeRequests}`)
      : location.pathname === '/rejectedRequests' ? setHeaderTitle(`${messages.header.title.rejectedRequests}`)
      : location.pathname === '/tasks' ? setHeaderTitle(`${messages.header.title.tasksList}`)
      : location.pathname === '/users' ? setHeaderTitle(`${messages.header.title.users}`)
      : location.pathname === '/users/internal' ? setHeaderTitle(`${messages.header.title.internal}`)
      : location.pathname === '/users/internal/add' ? setHeaderTitle(`${messages.header.title.add_user}`)
      : location.pathname.includes('/users/internal/edit') ? setHeaderTitle(`${messages.header.title.edit_user}`)
      : location.pathname === '/users/labs' ? setHeaderTitle(`${messages.header.title.users_labs}`)
      : location.pathname === '/users/labs/add' ? setHeaderTitle(`${messages.header.title.add_users_lab}`)
      : location.pathname.includes('/users/labs/edit') ? setHeaderTitle(`${messages.header.title.edit_users_lab}`)
      : location.pathname === '/users/hospitals' ? setHeaderTitle(`${messages.header.title.users_hospitals}`)
      : location.pathname === '/users/hospitals/add' ? setHeaderTitle(`${messages.header.title.add_users_hospital}`)
      : location.pathname.includes('/users/hospitals/edit') ? setHeaderTitle(`${messages.header.title.edit_users_hospital}`)
      : location.pathname === '/programs' ? setHeaderTitle(`${messages.home.programs_subscription}`)
      : location.pathname === '/lab-tests' ? setHeaderTitle(`${messages.header.title.lab_tests}`)
      : location.pathname === '/lab-tests/add' ? setHeaderTitle(`${messages.header.title.add_lab}`)
      : location.pathname.includes('/lab-tests/edit') ? setHeaderTitle(`${messages.header.title.edit_lab}`)
      : location.pathname === '/reference-labs' ? setHeaderTitle(`${messages.header.title.reference_labs}`)
      : location.pathname === '/reference-labs/add' ? setHeaderTitle(`${messages.header.title.add_reference_lab}`)
      : location.pathname.includes('/reference-labs/edit') ? setHeaderTitle(`${messages.header.title.edit_reference_lab}`)
      : location.pathname === '/myPatients' ? setHeaderTitle(`${messages.header.title.mypatients_list}`)
      : location.pathname === '/clients' ? setHeaderTitle(`${messages.header.title.patients_list}`)
      : location.pathname === '/clients/add' ? setHeaderTitle(`${messages.header.title.add_patient}`)
      : location.pathname.includes('/clients/edit') ? setHeaderTitle(`${messages.header.title.edit_patient}`)
      : location.pathname.includes('/patient-profile') ? setHeaderTitle(`${messages.header.title.patient_profile}`)
      : location.pathname.includes('/patient-preview') ? setHeaderTitle(`${messages.header.title.patient_preview}`)
      : location.pathname === '/allAppointments' ? setHeaderTitle(`${messages.appointments.allAppointments}`)
      : location.pathname === '/newAppointments' ? setHeaderTitle(`${messages.appointments.newAppointments}`)
      : location.pathname === '/activeAppointments' ? setHeaderTitle(`${messages.appointments.notCompletedAppointments}`)
      : location.pathname === '/canceledAppointments' ? setHeaderTitle(`${messages.header.title.canceled_appointments}`)
      : location.pathname === '/completedAppointments' ? setHeaderTitle(`${messages.header.title.completed_appointments}`)
      : location.pathname.includes('/appointmentStatistics') ? setHeaderTitle(`${messages.header.title.appointment_statistics}`)
      : location.pathname === '/payments' ? setHeaderTitle(`${messages.payments.payments_title}`)
      : location.pathname === '/appointments' ? setHeaderTitle(`${messages.appointments.medical_appointments}`)
      : location.pathname === '/obligations' ? setHeaderTitle(`${messages.obligations.title}`)
      : location.pathname === '/paymentsDetails' ? setHeaderTitle(`${messages.statistics.payments.title}`)
      : location.pathname === '/samplingDetails' ? setHeaderTitle(`${messages.statistics.sampling.title}`)
      : location.pathname === '/samples' ? setHeaderTitle(`${messages.sampling.title}`)
      : location.pathname === '/reports/requests' ? setHeaderTitle(`${messages.reports.requests.title}`)
      : location.pathname === '/coordinatorsTasks' ? setHeaderTitle(`${messages.tasks.coordinatorsTasksTitle}`)

      //: location.pathname === '/notCompletedAppointments' ? setHeaderTitle(`${messages.appointments.notCompletedAppointments}`)
      : roleId === 7 || roleId === 8 ? setHeaderTitle(`${messages.header.hello} ${lang === 'ar' ? user?.name?.ar : user?.name?.en} !`) : setHeaderTitle(`${messages.header.hello} ${user?.name} !`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user] )

  // Hook to check if the user is clicking outside notifications list to close it
  useEffect(() => {
    function handleClickOutside(event) {
        if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
            setShowNotifications(false);
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header-wrapper w-100">
      <h5 className="mb-0 align-self-center">
        {headerTitle}
      </h5>
      <LanguageBtn color={"text-dark"} />
      <div className="header-wrapper_content">
        <div className="header-wrapper_content_date">
          <img
            className="header-wrapper_content_date_icon"
            src={calendarIcon}
            alt="date"
          />
          <p className="header-wrapper_content_date_text">
            {moment()
              .format("LLLL")
              .split(" ")
              .slice(0, 4)
              .map((i, index) => (
                <span key={index} className="ml-1">
                  {i}
                </span>
              ))}
          </p>
        </div>
        <div className="header-wrapper_content_notifications" ref={notificationsRef} onClick={() => setShowNotifications(!showNotifications)} >
          <NotificationsNoneIcon className="header-wrapper_content_notifications_icon" />
          <p className="header-wrapper_content_notifications_count">{parseInt(countNotifications?.count) < 100 ? countNotifications?.count : "99+"}</p>
          <Notifications show={showNotifications} close={() => setShowNotifications(false)} />
        </div>
        <div
          className="header-wrapper_content_profile-link"
          onClick={() => user?.role_id !== 7 && user?.role_id !== 8 && history.push("/profile")}
        >
          <img src={user?.profile_image ? `${process.env.REACT_APP_BASE_URL}${user?.profile_image}` : profilePhoto} alt="Profile" className="header-wrapper_content_profile-link_icon" />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Header);
