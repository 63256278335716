import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "./api";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import * as TYPES from "./types";

export function* sideMenuRequest({ payload }) {
  try {
    const response = yield call(API.sideMenuRequest, payload);
    yield put(ACTIONS.sideMenuReceive(response?.data));
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* requestStatisticsRequest({ payload }) {
  try {
    const response = yield call(API.requestStatisticsRequest, payload);
    yield put(ACTIONS.requestStatisticsReceive(response?.data));
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* dashboardSagas() {
  yield takeLatest(TYPES.SIDE_MENU_REQUEST, sideMenuRequest);
  yield takeLatest(TYPES.REQUEST_STATISTICS_REQUEST, requestStatisticsRequest);
}
