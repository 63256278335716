// Get Coordinator Appointments statistics
export const GET_COORDINATOR_STATISTICS_REQUEST = 'GET_COORDINATOR_STATISTICS_REQUEST';
export const GET_COORDINATOR_STATISTICS_RECEIVE = 'GET_COORDINATOR_STATISTICS_RECEIVE';
// Get Obligations statistics
export const GET_OBLIGATIONS_STATISTICS_REQUEST = 'GET_OBLIGATIONS_STATISTICS_REQUEST';
export const GET_OBLIGATIONS_STATISTICS_RECEIVE = 'GET_OBLIGATIONS_STATISTICS_RECEIVE';
// Get Payments statistics
export const GET_COORDINATOR_PAYMENTS_STATISTICS_REQUEST = 'GET_COORDINATOR_PAYMENTS_STATISTICS_REQUEST';
export const GET_COORDINATOR_PAYMENTS_STATISTICS_RECEIVE = 'GET_COORDINATOR_PAYMENTS_STATISTICS_RECEIVE';
// Get Sampling Reservation statistics
export const GET_COORDINATOR_SAMPLING_STATISTICS_REQUEST = 'GET_COORDINATOR_SAMPLING_STATISTICS_REQUEST';
export const GET_COORDINATOR_SAMPLING_STATISTICS_RECEIVE = 'GET_COORDINATOR_SAMPLING_STATISTICS_RECEIVE';
// Get Sampling Data statistics
export const GET_COORDINATOR_SAMPLING_DATA_STATISTICS_REQUEST = 'GET_COORDINATOR_SAMPLING_DATA_STATISTICS_REQUEST';
export const GET_COORDINATOR_SAMPLING_DATA_STATISTICS_RECEIVE = 'GET_COORDINATOR_SAMPLING_DATA_STATISTICS_RECEIVE';
// Get Coordinator Tasks statistics
export const GET_COORDINATOR_TASKS_STATISTICS_REQUEST = 'GET_COORDINATOR_TASKS_STATISTICS_REQUEST';
export const GET_COORDINATOR_TASKS_STATISTICS_RECEIVE = 'GET_COORDINATOR_TASKS_STATISTICS_RECEIVE';
