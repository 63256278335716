import * as TYPES from "./types";

const initialState = {
  appointmentsByUsers: [],
  appointmentsList: {},
  myAppointments: [],
  myTodayAppointments: {},
  appointments: [],
  upcomingAppointment: [],
  appointment: {},
  addedApp: false,
  appointmentsStatistics: {},
  appointmentsStatisticsBlock: {},
  labtests: [],
  patientLabtests: {},
  patientLabtestsList: [],
  appointmentReasons: [],
  addedReason: false,
  sentToPatient: false,
  usersList: [],
  receptionistAppsList: [],
};

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    //Get Appointments By Users
    case TYPES.GET_USERS_APPOINTMENTS_RECEIVE: {
      return {
        ...state,
        appointmentsByUsers: action.payload.data,
      }
    }
    //Get Appointments List
    case TYPES.GET_APPOINTMENTS_LIST_RECEIVE: {
      return {
        ...state,
        appointmentsList: action.payload.data,
      }
    }
    //Get MY Appointments
    case TYPES.GET_MY_APPOINTMENTS_RECEIVE: {
      return {
        ...state,
        myAppointments: action.payload,
      };
    }
    //Get My Today Appointments
    case TYPES.GET_MY_TODAY_APPOINTMENTS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      if (pagination?.current_page === 1) {
        return {
          ...state,
          myTodayAppointments: { data, ...pagination },
        };
      } else {
        return {
          ...state,
          myTodayAppointments: {
            data: [...state.myTodayAppointments.data, ...data],
            ...pagination,
          },
        };
      }
    }
    //Get Appointments
    case TYPES.GET_APPOINTMENTS_RECEIVE: {
      return {
        ...state,
        appointments: action.payload,
      }
    }
    //Get Upcomming Appointment
    case TYPES.GET_UPCOMMING_APPOINTMENT_RECEIVE: {
      return {
        ...state,
        upcomingAppointment: action.payload.data,
      }
    }
    //Get Recepetionist Appointments List
    case TYPES.GET_RECEPTIONIST_APPOINTMENTS_LIST_RECEIVE: {
      return {
        ...state,
        receptionistAppsList: action.payload.data,
      }
    }
    //Get appointment
    case TYPES.GET_APPOINTMENT_RECEIVE: {
      return {
        ...state,
        appointment: action.payload.data,
      }
    }
    case TYPES.DELETE_APPOINTMENT_RECEIVE: {
      return {
        ...state,
        appointments: { ...state.appointments, data: state.appointments?.data?.filter((app) => app.id !== action.payload) }
      }
    }
    //Set added appointment
    case TYPES.SET_ADDED_APPOINTMENT: {
      return {
        ...state,
        addedApp: action.payload,
      }
    }
    //Set Added Reason
    case TYPES.SET_ADDED_REASON: {
      return {
        ...state,
        addedReason: action.payload,
      }
    }
    //Get Appointment Reasons
    case TYPES.GET_APPOINTMENT_REASONS_RECEIVE: {
      return {
        ...state,
        appointmentReasons: action.payload.data,
      }
    }
    //Get Labtests List
    case TYPES.GET_LABTESTS_LIST_RECEIVE: {
      return {
        ...state,
        labtests: action.payload.data,
      }
    }
    //Get Patient labtests
    case TYPES.GET_PATIENT_LABTESTS_RECEIVE: {
      return {
        ...state,
        patientLabtests: action.payload,
      }
    }
    //Get patient labtests List
    case TYPES.GET_PATIENT_LABTESTS_LIST_RECEIVE: {
      return {
        ...state,
        patientLabtestsList: action.payload.data,
      }
    }
    //Delete Labtest
    case TYPES.DELETE_APPOINTMENT_LABTEST_RECEIVE: {
      return {
        ...state,
        patientLabtests: state.patientLabtests?.data?.filter((labtest) => labtest.id !== action.payload),
      }
    }
    //Appointments Statistics
    case TYPES.GET_APPOINTMENTS_STATISTICS_RECEIVE: {
      return {
        ...state,
        appointmentsStatistics: action.payload.statistics,
      }
    }
    //Appointments Statistics Block
    case TYPES.GET_APPOINTMENTS_STATISTICS_BLOCK_RECEIVE: {
      return {
        ...state,
        appointmentsStatisticsBlock: action.payload.statistics,
      }
    }
    //Set Sent To Patient
    case TYPES.SET_SENT_TO_PATIENT: {
      return {
        ...state,
        sentToPatient: action.payload,
      }
    }
    //Appointments Users List
    case TYPES.GET_APPOINTMENTS_USERS_LIST_RECEIVE: {
      return {
        ...state,
        usersList: action.payload.data,
      }
    }
    default:
      return state
  }
}
