//Get CONTACTS
export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_RECEIVE = "GET_CONTACTS_RECEIVE";
//Get CONTACT
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_RECEIVE = "GET_CONTACT_RECEIVE";
//Add CONTACT
export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_RECEIVE = "ADD_CONTACT_RECEIVE";
//Edit CONTACT
export const EDIT_CONTACT_REQUEST = "EDIT_CONTACT_REQUEST";
export const EDIT_CONTACT_RECEIVE = "EDIT_CONTACT_RECEIVE";
//Delete CONTACT
export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_RECEIVE = "DELETE_CONTACT_RECEIVE";
//set added CONTACT
export const SET_ADDED_CONTACT = "SET_ADDED_CONTACT";

