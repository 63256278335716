import * as TYPES from "./types";

//Get Attachments List
export const getAttachmentsRequest = (payload) => ({
  type: TYPES.GET_ATTACHMENTS_REQUEST,
  payload,
});
export const getAttachmentsReceive = (payload) => ({
  type: TYPES.GET_ATTACHMENTS_RECEIVE,
  payload,
});
//Get Attachment
export const getAttachmentRequest = (payload) => ({
  type: TYPES.GET_ATTACHMENT_REQUEST,
  payload,
});
export const getAttachmentReceive = (payload) => ({
  type: TYPES.GET_ATTACHMENT_RECEIVE,
  payload,
});
//Delete Attachment
export const deleteAttachmentRequest = (payload) => ({
  type: TYPES.DELETE_ATTACHMENT_REQUEST,
  payload,
});
export const deleteAttachmentReceive = (payload) => ({
  type: TYPES.DELETE_ATTACHMENT_RECEIVE,
  payload,
});
//Add Attachment
export const addAttachmentRequest = (payload, patientId) => ({
  type: TYPES.ADD_ATTACHMENT_REQUEST,
  payload,  
  patientId,
});
export const addAttachmentReceive = (payload) => ({
  type: TYPES.ADD_ATTACHMENT_RECEIVE,
  payload,
});
//Edit Attachment
export const editAttachmentRequest = (payload) => ({
  type: TYPES.EDIT_ATTACHMENT_REQUEST,
  payload,
});
export const editAttachmentReceive = (payload) => ({
  type: TYPES.EDIT_ATTACHMENT_RECEIVE,
  payload,
});
//Download Attachment
export const downloadAttachmentRequest = (payload) => ({
  type: TYPES.DOWNLOAD_ATTACHMENT_REQUEST,
  payload,
});
export const downloadAttachmentReceive = (payload) => ({
  type: TYPES.DOWNLOAD_ATTACHMENT_RECEIVE,
  payload,
});
//Get Messages
export const getMessagesRequest = (payload) => ({
  type: TYPES.GET_MESSAGES_REQUEST,
  payload,
});
export const getMessagesReceive = (payload) => ({
  type: TYPES.GET_MESSAGES_RECEIVE,
  payload,
});