import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Get Patient Labtests List
export function* getPaymentsListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPaymentsListRequest, payload);
    yield put(ACTIONS.getPaymentsListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Payments Statistics
export function* getPaymentsStatisticsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPaymentsStatisticsRequest, payload);
    yield put(ACTIONS.getPaymentsStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Accept Payment
export function* acceptPaymentSaga({payload}) {
  try {
    yield call(APIS.acceptPaymentRequest, payload);
    yield put(ACTIONS.acceptPaymentReceive());
    yield put(ACTIONS.getPaymentsListRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    yield put(ACTIONS.getPaymentsStatisticsRequest());
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Upload Payment Image
export function* uploadPaymentImageSaga({payload, id}) {
  try {
    yield put(ACTIONS.uploadPaymentImageReceive(false));
    yield call(APIS.uploadPaymentImageRequest, payload, id);
    yield put(ACTIONS.uploadPaymentImageReceive(true));
    yield put(ACTIONS.getPaymentsListRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    yield put(ACTIONS.getPaymentsStatisticsRequest());
    dispatchSnackbarSuccess("success");
  } catch (err) {
    yield put(ACTIONS.uploadPaymentImageReceive(false));
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Payment Rejection Reasons
export function* getPaymentRejectionReasonsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPaymentRejectionReasonsRequest, payload);
    yield put(ACTIONS.getPaymentRejectionReasonsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Reject Payment
export function* rejectPaymentSaga({payload}) {
  try {
    yield call(APIS.rejectPaymentRequest, payload);
    yield put(ACTIONS.rejectPaymentReceive(true));
    yield put(ACTIONS.getPaymentsListRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    yield put(ACTIONS.getPaymentsStatisticsRequest());
  } catch (err) {
    yield put(ACTIONS.rejectPaymentReceive(false));
    dispatchSnackbarError(err.response?.data);
  }
}

//New Count
export function* newCountSaga({payload}) {
  try {
    const { data } = yield call(APIS.newCountRequest, payload);
    yield put(ACTIONS.newCountReceive(data?.count));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Last Seen
export function* lastSeenSaga({payload}) {
  try {
    yield call(APIS.lastSeenRequest, payload);
    yield put(ACTIONS.lastSeenReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Payment Methods
export function* getPaymentMethodsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPaymentMethodsRequest, payload);
    yield put(ACTIONS.getPaymentMethodsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Hide Payment Bullet
export function* hidePaymentBulletSaga({payload}) {
  try {
    yield put(ACTIONS.hidePaymentBulletReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Download Excel
export function* downloadExcelSaga({payload}) {
  try {
    const { data } = yield call(APIS.downloadExcelRequest, payload);
    yield put(ACTIONS.downloadExcelReceive(data));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Refund Payment
export function* refundPaymentSaga({payload}) {
  try {
    yield put(ACTIONS.refundPaymentReceive(false));
    yield call(APIS.refundPaymentRequest, payload);
    yield put(ACTIONS.refundPaymentReceive(true));
    yield put(ACTIONS.getPaymentsListRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    yield put(ACTIONS.getPaymentsStatisticsRequest());
  } catch (err) {
    yield put(ACTIONS.refundPaymentReceive(false));
    dispatchSnackbarError(err.response?.data);
  }
}

export function* paymentsSagas() {
  yield takeLatest(TYPES.GET_PAYMENTS_LIST_REQUEST, getPaymentsListSaga);
  yield takeLatest(TYPES.GET_PAYMENTS_STATISTICS_REQUEST, getPaymentsStatisticsSaga);
  yield takeLatest(TYPES.ACCEPT_PAYMENT_REQUEST, acceptPaymentSaga);
  yield takeLatest(TYPES.UPLOAD_PAYMENT_IMAGE_REQUEST, uploadPaymentImageSaga);
  yield takeLatest(TYPES.GET_PAYMENT_REJECTION_REASONS_REQUEST, getPaymentRejectionReasonsSaga);
  yield takeLatest(TYPES.REJECT_PAYMENT_REQUEST, rejectPaymentSaga);
  yield takeLatest(TYPES.NEW_COUNT_REQUEST, newCountSaga);
  yield takeLatest(TYPES.LAST_SEEN_REQUEST, lastSeenSaga);
  yield takeLatest(TYPES.GET_PAYMENT_METHODS_REQUEST, getPaymentMethodsSaga);
  yield takeLatest(TYPES.HIDE_PAYMENT_BULLET_REQUEST, hidePaymentBulletSaga);
  yield takeLatest(TYPES.DOWNLOAD_EXCEL_REQUEST, downloadExcelSaga);
  yield takeLatest(TYPES.REFUND_PAYMENT_REQUEST, refundPaymentSaga);
}


