import { axiosInstance } from "../../network";

// login step 1 "request OTP"
export const loginWithOTP = async (payload) => {
  return await axiosInstance.post("api/v1/system/login-auth", payload);
};

// login step 2 "with OTP"
export const oTPLogin = async (payload) => {
  return await axiosInstance.post("api/v1/system/login-token", payload);
};

// resend OTP payload:{username}
export const resendOTP = async (payload) => {
  return await axiosInstance.post("api/v1/system/otp-code", payload);
};

// forget password payload: {username:"__" , otp:"__"}
export const forgetPassword = async (payload) => {
  return await axiosInstance.post("api/v1/system/forgot-password", payload);
};

// reset password payload: {token:"__" , password:"__"}
export const resetPassword = async (payload) => {
  return await axiosInstance.post("api/v1/system/reset-password", payload);
};

// get user
export const getUser = async () => {
  return await axiosInstance.get("system/v1/users/me");
};

// logout
export const logout = async () => {
  return await axiosInstance.post("api/v1/system/logout");
};

// Agencies

export const agenciesLoginWithOTP = async (payload) => {
  return await axiosInstance.post("agency/v1/login-auth", payload);
};

export const agenciesOTPLogin = async (payload) => {
  return await axiosInstance.post("agency/v1/login-token", payload);
};

export const agenciesResendOTP = async (payload) => {
  return await axiosInstance.post("agency/v1/otp-code", payload);
};

export const agenciesForgetPassword = async (payload) => {
  return await axiosInstance.post("agency/v1/forgot-password", payload);
};

export const agenciesResetPassword = async (payload) => {
  return await axiosInstance.post("agency/v1/reset-password", payload);
};

export const agenciesGetUser = async () => {
  return await axiosInstance.get("agency/v1/me");
}

export const agenciesLogout = async () => {
  return await axiosInstance.post("agency/v1/logout");
}