import * as TYPES from "./types";

// login step 1 "request OTP"
export const loginWithOTPRequest = (payload) => ({
  type: TYPES.LOGIN_WITH_OTP_REQUEST,
  payload,
});
export const loginWithOTPReceive = (payload) => ({
  type: TYPES.LOGIN_WITH_OTP_RECEIVE,
  payload,
});

// login step 2  "with OTP"
export const oTPLoginRequest = (payload) => ({
  type: TYPES.OTP_LOGIN_REQUEST,
  payload,
});
export const oTPLoginReceive = (payload) => ({
  type: TYPES.OTP_LOGIN_RECEIVE,
  payload,
});

// resend OTP "payload:{username}"
export const resendOTPRequest = (payload) => ({
  type: TYPES.RESEND_OTP_REQUEST,
  payload,
});
export const resendOTPReceive = (payload) => ({
  type: TYPES.RESEND_OTP_RECEIVE,
  payload,
});

// forget password "payload: {username: "__" , otp:"__"}"
export const forgetPasswordRequest = (payload) => ({
  type: TYPES.FORGET_PASSWORD_REQUEST,
  payload,
});
export const forgetPasswordReceive = (payload) => ({
  type: TYPES.FORGET_PASSWORD_RECEIVE,
  payload,
});

// reset password payload: {token: "__" , password:"__"}
export const resetPasswordRequest = (payload) => ({
  type: TYPES.RESET_PASSWORD_REQUEST,
  payload,
});
export const resetPasswordReceive = (payload) => ({
  type: TYPES.RESET_PASSWORD_RECEIVE,
  payload,
});

// get user
export const getUserRequest = () => ({
  type: TYPES.GET_USER_REQUEST,
});
export const getUserReceive = (payload) => ({
  type: TYPES.GET_USER_RECEIVE,
  payload,
});

// logout
export const logoutRequest = () => ({
  type: TYPES.LOGOUT_REQUEST,
});
export const logoutReceive = (payload) => ({
  type: TYPES.LOGOUT_RECEIVE,
  payload,
});

// Agencies

export const agenciesLoginWithOTPRequest = (payload) => ({
  type: TYPES.AGENCIES_LOGIN_WITH_OTP_REQUEST,
  payload,
});
export const agenciesLoginWithOTPReceive = (payload) => ({
  type: TYPES.AGENCIES_LOGIN_WITH_OTP_RECEIVE,
  payload,
});

export const agenciesOTPLoginRequest = (payload) => ({
  type: TYPES.AGENCIES_OTP_LOGIN_REQUEST,
  payload,
});
export const agenciesOTPLoginReceive = (payload) => ({
  type: TYPES.AGENCIES_OTP_LOGIN_RECEIVE,
  payload,
});

export const agenciesResendOTPRequest = (payload) => ({
  type: TYPES.AGENCIES_RESEND_OTP_REQUEST,
  payload,
});
export const agenciesResendOTPReceive = (payload) => ({
  type: TYPES.AGENCIES_RESEND_OTP_RECEIVE,
  payload,
});

export const agenciesForgetPasswordRequest = (payload) => ({
  type: TYPES.AGENCIES_FORGET_PASSWORD_REQUEST,
  payload,
});
export const agenciesForgetPasswordReceive = (payload) => ({
  type: TYPES.AGENCIES_FORGET_PASSWORD_RECEIVE,
  payload,
});

export const agenciesResetPasswordRequest = (payload) => ({
  type: TYPES.AGENCIES_RESET_PASSWORD_REQUEST,
  payload,
});
export const agenciesResetPasswordReceive = (payload) => ({
  type: TYPES.AGENCIES_RESET_PASSWORD_RECEIVE,
  payload,
});

export const agenciesGetUserRequest = () => ({
  type: TYPES.AGENCIES_GET_USER_REQUEST,
});
export const agenciesGetUserReceive = (payload) => ({
  type: TYPES.AGENCIES_GET_USER_RECEIVE,
  payload,  
});

export const agenciesLogoutRequest = () => ({
  type: TYPES.AGENCIES_LOGOUT_REQUEST,
});
export const agenciesLogoutReceive = (payload) => ({
  type: TYPES.AGENCIES_LOGOUT_RECEIVE,
  payload,
});