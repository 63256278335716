import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

function* getTasksSaga({ payload }) {
  try {
    const { data } = yield call(APIS.tasksRequest, payload);
    if (payload.status === 1) {
      //new
      yield put(ACTIONS.newTasksReceive(data));
    } else if (payload.status === 2) {
      //inprogress
      yield put(ACTIONS.inprogressTasksReceive(data));
    } else if (payload.status === 4) {
      //completed
      yield put(ACTIONS.completedTasksReceive(data));
    } else {
      yield put(ACTIONS.allTasksReceive(data));
    }
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
    console.log(err);
  }
}

//Add
export function* addTaskSaga({ payload }) {
  try {
    const {data} = yield call(APIS.addTaskRequest, payload);
    if (!payload.location.includes("/appointment")) {
      yield put(ACTIONS.addTaskReceive(data.task));
    }
    yield put(ACTIONS.newTasksRequest({sort:'complete_date,asc',status: 1 }));
    yield put(ACTIONS.inprogressTasksRequest({sort:'complete_date,asc',status: 2 }));
    yield put(ACTIONS.completedTasksRequest({sort:'complete_date,asc',status: 4 }));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
    console.log(err);
  }
}

//Edit
export function* editTaskSaga({ payload }) {
  try {
    yield call(APIS.editTaskRequest, payload);
    yield put(ACTIONS.editTaskReceive(payload));
    dispatchSnackbarSuccess('success');
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
    console.log(err);
  }
}

//Delete
export function* deleteTaskSaga({ payload }) {
  try {
    yield call(APIS.deleteTaskRequest, payload);
    yield put(ACTIONS.deleteTaskReceive(payload));
    dispatchSnackbarSuccess('success');
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Finish
export function* finishTaskSaga({ payload }) {
  try {
    yield call(APIS.finishTaskRequest, payload);
    yield put(ACTIONS.finishTaskReceive(payload));
    yield put(ACTIONS.newTasksRequest({sort:'complete_date,asc',status: 1 }));
    yield put(ACTIONS.inprogressTasksRequest({sort:'complete_date,asc',status: 2 }));
    yield put(ACTIONS.completedTasksRequest({sort:'complete_date,asc',status: 4 }));
    dispatchSnackbarSuccess('success');
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Statistics
export function* tasksStatisticsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.tasksStatisticsRequest, payload);
    yield put(ACTIONS.tasksStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
    console.log(err);
  }
}

//Coordinators Tasks 
export function* coordinatorsTasksSaga({ payload }) {
  try {
    const { data } = yield call(APIS.coordinatorsTasksRequest, payload);
    if (payload.status === 1) {
      //new
      yield put(ACTIONS.coordinatorsNewTasksReceive(data));
    } else if (payload.status === 2) {
      //inprogress
      yield put(ACTIONS.coordinatorsInprogressTasksReceive(data));
    } else if (payload.status === 4) {
      //completed
      yield put(ACTIONS.coordinatorsCompletedTasksReceive(data));
    } else {
      yield put(ACTIONS.coordinatorsTasksReceive(data));
    }
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
    console.log(err);
  }
}

export function* tasksSagas() {
  yield takeLatest(TYPES.NEW_TASKS_REQUEST, getTasksSaga);
  yield takeLatest(TYPES.INPROGRESS_TASKS_REQUEST, getTasksSaga);
  yield takeLatest(TYPES.COMPLETED_TASKS_REQUEST, getTasksSaga);
  yield takeLatest(TYPES.ALL_TASKS_REQUEST, getTasksSaga);
  yield takeLatest(TYPES.ADD_TASK_REQUEST, addTaskSaga);
  yield takeLatest(TYPES.EDIT_TASK_REQUEST, editTaskSaga);
  yield takeLatest(TYPES.DELETE_TASK_REQUEST, deleteTaskSaga);
  yield takeLatest(TYPES.FINISH_TASK_REQUEST, finishTaskSaga);
  yield takeLatest(TYPES.TASKS_STATISTICS_REQUEST, tasksStatisticsSaga);
  yield takeLatest(TYPES.COORDINATORS_TASKS_REQUEST, coordinatorsTasksSaga);
  yield takeLatest(TYPES.COORDINATORS_NEW_TASKS_REQUEST, coordinatorsTasksSaga);
  yield takeLatest(TYPES.COORDINATORS_INPROGRESS_TASKS_REQUEST, coordinatorsTasksSaga);
  yield takeLatest(TYPES.COORDINATORS_COMPLETED_TASKS_REQUEST, coordinatorsTasksSaga);
}


