import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "./api";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import * as TYPES from "./types";

export function* editProfileImageRequest({ payload }) {
  try {
    const response = yield call(API.editImageRequest, payload);
    yield put(ACTIONS.editProfileImageReceive(response?.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* profileSagas() {
  yield takeLatest(TYPES.EDIT_PROFILE_IMAGE_REQUEST, editProfileImageRequest);
}
