import * as TYPES from "./types";

const initialState = {
  usersLabs: [],
  usersLab: {},
  allCities: [],
  cities: [],
  regions: [],
  agenciesList: [],
  cityAgenciesCount: -1,
};

export default function usersLabsReducer(state = initialState, action) {
  switch (action.type) {
    //Statistics
    case TYPES.GET_USERSLABS_RECEIVE: {
      return {
        ...state,
        usersLabs: action.payload,
      }
    }
    //Get usersLab
    case TYPES.GET_USERSLAB_RECEIVE: {
      return {
        ...state,
        usersLab: action.payload.data,
      }
    }
    case TYPES.DELETE_USERSLAB_RECEIVE: {
      return {
        ...state,
        usersLabs: { ...state.usersLabs, data: state.usersLabs?.data?.filter((user) => user.id !== action.payload) }
      }
    }
    case TYPES.ACTIVE_USERSLAB_RECEIVE: {
      return {
        ...state,
        usersLabs: { ...state.usersLabs, data: state.usersLabs?.data?.map((ul) => {
          if (ul.id === action.payload) {
            return { ...ul, is_blocked: false }
          }
          return ul
        }) }
      }
    }
    case TYPES.DESACTIVE_USERSLAB_RECEIVE: {
      return {
        ...state,
        usersLabs: { ...state.usersLabs, data: state.usersLabs?.data?.map((ul) => {
          if (ul.id === action.payload) {
            return { ...ul, is_blocked: true }
          }
          return ul
        }) }
      }
    }
    //Get Cities
    case TYPES.GET_CITIES_RECEIVE: {
      return {
        ...state,
        allCities: action.payload.data,
        cities: action.payload.data,
      }
    }
    //Get Regions
    case TYPES.GET_REGIONS_RECEIVE: {
      return {
        ...state,
        regions: action.payload.data,
      }
    }
    //Get Cities By Region
    case TYPES.GET_CITIES_BY_REGION_RECEIVE: {
      return {
        ...state,
        cities: action.payload.data,
      }
    }
    //Get Agencies List
    case TYPES.GET_AGENCIES_LIST_RECEIVE: {
      return {
        ...state,
        agenciesList: action.payload.data,
      }
    }
    //Check City Agency
    case TYPES.CHECK_CITY_AGENCY_RECEIVE: {
      return {
        ...state,
        cityAgenciesCount: action.payload?.data?.agencies_count,
      }
    }
    default:
      return state
  }
}
