import * as TYPES from "./types";

const initialNotificationsState = {
  oldNotifications: [],
  newNotifications: [],
  countNotifications: 0,
};

export default function notificationsReducer(
  state = initialNotificationsState,
  action
) {
  switch (action.type) {
    case TYPES.OLD_NOTIFICATIONS_RECEIVE:
      return {
        ...state,
        oldNotifications: action.payload.notifications,
      };
    case TYPES.NEW_NOTIFICATIONS_RECEIVE:
      return {
        ...state,
        newNotifications: action.payload.notifications,
      };
    case TYPES.COUNT_NOTIFICATIONS_RECEIVE:
      return {
        ...state,
        countNotifications: action.payload,
      };
    case TYPES.MARK_ASREADED_RECEIVE:
        return {
          ...state,
          oldNotifications: [ ...state.oldNotifications, state.newNotifications.find(note => note.id === action.payload) ],
          newNotifications: state.newNotifications.filter(note => note.id !== action.payload),
          // countNotifications: { ...state.countNotifications, count: state.countNotifications.count - 1 },
        };
    case TYPES.REALTIME_NOTIFICATIONS_RECEIVE:
      return {
        ...state,
        countNotifications: { ...state.countNotifications, count: action.payload},
      };
    default:
      return state;
  }
}
