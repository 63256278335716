import * as TYPES from "./types";

const initialState = {
  usersStatistics: [],
  internalUsers: [],
  valideEmail: true,
  emailError: "",
  valideMobile: true,
  mobileError: "",
  user: {},
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    //Statistics
    case TYPES.USERS_STATISTICS_RECEIVE: {
      return {
        ...state,
        usersStatistics: action.payload.statistics,
      }
    }
    //Get Internal Users List
    case TYPES.INTERNAL_USERS_RECEIVE: {
      return {
        ...state,
        internalUsers: action.payload,
      }
    }
    //Get User
    case TYPES.USER_RECEIVE: {
      return {
        ...state,
        user: action.payload.data,
      }
    }
    //Delete User
    case TYPES.DELETE_USER_RECEIVE: {
      return {
        ...state,
        internalUsers: { ...state.internalUsers, data: state.internalUsers?.data?.filter((user) => user.id !== action.payload) },
        usersStatistics: { ...state.usersStatistics, data: parseInt(state.usersStatistics?.internal_users) - 1 },
      }
    }
    //Block User
    case TYPES.BLOCK_USER_RECEIVE: {
      return {
        ...state,
        internalUsers: { ...state.internalUsers, data: state.internalUsers?.data?.map((user) => {
          if (user.id === action.payload) {
            return { ...user, is_blocked: true }
          }
          return user
        }) }
      }
    }
    //Unblock User
    case TYPES.UNBLOCK_USER_RECEIVE: {
      return {
        ...state,
        internalUsers: { ...state.internalUsers, data: state.internalUsers?.data?.map((user) => {
          if (user.id === action.payload) {
            return { ...user, is_blocked: false }
          }
          return user
        }) }
      }
    }
    //Validate Email
    case TYPES.VALIDATE_EMAIL_RECEIVE: {
      return {
        ...state,
        valideEmail: action.payload.val,
        emailError: action.payload.msg,
      }
    }
    //Validate Mobile
    case TYPES.VALIDATE_MOBILE_RECEIVE: {
      return {
        ...state,
        valideMobile: action.payload.val,
        mobileError: action.payload.msg,
      }
    }
    case TYPES.CLEAN_ERRORS: {
      return {
        ...state,
        valideEmail: true,
        emailError: "",
        valideMobile: true,
        mobileError: "",
      }
    }
    default:
      return state
  }
  
}
