import { axiosInstance } from "../../network";
import moment from 'moment';

export const tasksRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/tasks`, { params: payload });
};
//ADD
export const addTaskRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/tasks`, { 
    complete_date: moment(payload.complete_date).format("YYYY-MM-DD HH:mm:ss"), 
    description: payload.description });
};
//EDIT
export const editTaskRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/tasks/${payload.id}`, {
    complete_date: moment(payload.complete_date).format("YYYY-MM-DD HH:mm:ss"),
    description: payload.description,
    reason: payload.reason,
  });
};
//DELETE
export const deleteTaskRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/tasks/${payload}`);
}
//FINISH
export const finishTaskRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/tasks/${payload.id}/complete`);
}
//Statistics
export const tasksStatisticsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/tasks/statistics');
}
// Coordinators Tasks
export const coordinatorsTasksRequest = async (payload) => {
  return await axiosInstance.get('system/v1/general/tasks', { params: payload });
}