import * as TYPES from "./types";

const initialState = {
  messagesList: [],
  attachments: [],
  attachment: {},
};

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    //Statistics
    case TYPES.GET_ATTACHMENTS_RECEIVE: {
      return {
        ...state,
        attachments: action.payload,
      }
    }
    //Get appointment
    case TYPES.GET_ATTACHMENT_RECEIVE: {
      return {
        ...state,
        attachment: action.payload.data,
      }
    }
    //Edit Attachment
    case TYPES.EDIT_ATTACHMENT_RECEIVE: {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          data: state.attachments.data.map(att => {
            if (att.id === action.payload.id) {
              return {
                ...att,
                name: action.payload.name,
              }
            }
            return att
        }),
      }
    }
  }
    case TYPES.DELETE_ATTACHMENT_RECEIVE: {
      return {
        ...state,
        attachments: { 
          ...state.attachments, 
          data: state.attachments?.data?.filter((app) => app.id !== action.payload) 
        }
      }
    }
    //Get Messages:
    case TYPES.GET_MESSAGES_RECEIVE: {
      if (action.payload.current_page === 1) {
        return {
          ...state,
          messagesList: action.payload,
        }
      } else {
        return {
          ...state,
          messagesList: {
            ...state.messagesList,
            current_page: action.payload.current_page,
            data: [...state.messagesList.data, ...action.payload.data],
          },
        }
      }
    }
    default:
      return state
  }
}
