import React, { useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import { AppBar, Toolbar, CssBaseline } from "@material-ui/core";
import Loader from "../Loader/Loader";
import SideMenu from "../SideMenu/SideMenu";
import Header from "../Header/Header";
import { useStyles } from "./MainLayoutStyles";
import "./layout.scss";

function MainLayout(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  
  return (
    <div className="main_contant">
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })+ " main_contant_header"}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>
      <div className="desktop_side_menu">
        <SideMenu
          open={open}
          theme={theme}
          handleCloseDrawer={() => setOpen(false)}
          classes={classes}
        />
      </div>
      <div className="mobile_side_menu">
        <SideMenu
          open={open}
          theme={theme}
          handleCloseDrawer={() => setOpen(false)}
          classes={classes}
        />
      </div>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.loading ? <Loader /> : props.children}
      </main>
    </div>
  );
}

const mapStateToProps = ({ loading }) => ({
  loading,
});

export default connect(mapStateToProps, null)(MainLayout);
