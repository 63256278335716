import { axiosInstance } from "../../network";

//GET UsersLabs List
export const getUsersLabsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/agencies', {params: payload});
}
//Get UsersLab
export const getUsersLabRequest = async (payload) => {
  return await axiosInstance.get('system/v1/agencies/' + payload);
}
//Add UsersLab
export const addUsersLabRequest = async (payload) => {
  return await axiosInstance.post('system/v1/agencies', payload);
}
//Edit UsersLab
export const editUsersLabRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/agencies/${payload.id}`, payload);
}
//Delete UsersLab
export const deleteUsersLabRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/agencies/${payload}`);
}
//Active UsersLab
export const activeUsersLabRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/agencies/${payload}/unlock`);
}
//Desactive UsersLab
export const desactiveUsersLabRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/agencies/${payload}/block`);
}
//Get Cities
export const getCitiesRequest = async () => {
  return await axiosInstance.get('api/v1/cities');
}
//Get Regions
export const getRegionsRequest = async () => {
  return await axiosInstance.get('api/v1/regions');
}
//Get cities by region
export const getCitiesByRegionRequest = async (payload, query) => {
  return await axiosInstance.get(`api/v1/regions/${payload}/cities`, query && {params: query});
}
//Get Agencies list
export const getAgenciesListRequest = async (payload) => {
 // return await axiosInstance.get('api/v1/agencies/list', {params: payload?.city_id ? {orderBy: `city_id,${payload.city_id}`} : null});
  return await axiosInstance.get('api/v1/agencies/list', {params: payload?.city_id ? {cityId: payload.city_id} : null});

}
//Check City Agency
export const checkCityAgencyRequest = async (payload) => {
  return await axiosInstance.get(`api/v1/cities/${payload}/agencies-count`);
}
