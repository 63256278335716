import { axiosInstance } from "../../network";

//Statistics
export const usersStatisticsRequest = async () => {
  return await axiosInstance.get('system/v1/users/statistics');
}
//Get User
export const userRequest = async (id) => {
  return await axiosInstance.get(`system/v1/users/${id}`);
}
//GET Internal Users List
export const internalUsersRequest = async (payload) => {
  return await axiosInstance.get('system/v1/users', {params: payload});
}
//Add User
export const addUserRequest = async (payload) => {
  return await axiosInstance.post('system/v1/users', payload);
}
//Edit User
export const editUserRequest = async (payload) => {
  return await axiosInstance.put('system/v1/users/' + payload.id, payload);
}
//Delete User
export const deleteUserRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/users/${payload}`);
}
//Block User
export const blockUserRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/users/${payload}/block`);
}
//Unblock User
export const unblockUserRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/users/${payload}/unlock`);
}
// Validate Email
export const validateEmailRequest = async (payload) => {
  return await axiosInstance.post('system/v1/validate/email', {email: payload});
}
//Validate Mobile
export const validateMobileRequest = async (payload) => {
  return await axiosInstance.post('system/v1/validate/phone-number', {phone_number: payload.trim()});
}
