import { all } from "redux-saga/effects";
import { authSagas } from "../authentication/sagas";
import { dashboardSagas } from "../dashboard/sagas";
import { notificationsSagas } from "../notifications/sagas";
import { profileSagas } from "../profile/sagas";
import { NewRequestsSagas } from "../requests/sagas";
import {tasksSagas} from "../Tasks/sagas";
import {usersSagas} from "../users/sagas";
import {programsSagas} from "../programs/sagas";
import {labTestsSagas} from "../labTests/sagas";
import {referenceLabsSagas} from "../referenceLabs/sagas";
import {usersLabsSagas} from "../usersLabs/sagas";
import {patientsSagas} from "../patients/sagas";
import {appointmentsSagas} from "../appointments/sagas";
import {attachmentsSagas} from "../attachments/sagas";
import {contactsSagas} from "../contacts/sagas";
import {paymentsSagas} from "../payments/sagas";
import { agenciesSagas } from "../agencies/sagas";
import { statisticsSagas } from "../statistics/sagas";

export function* watchSagas() {
  yield all([
    authSagas(),
    dashboardSagas(),
    profileSagas(),
    NewRequestsSagas(),
    notificationsSagas(),
    tasksSagas(),
    usersSagas(),
    programsSagas(),
    labTestsSagas(),
    referenceLabsSagas(),
    usersLabsSagas(),
    patientsSagas(),
    appointmentsSagas(),
    attachmentsSagas(),
    contactsSagas(),
    paymentsSagas(),
    agenciesSagas(),
    statisticsSagas(),
  ]);
}
