//Get Statistics
export const GET_LABTESTS_STATISTICS_REQUEST = "GET_LABTESTS_STATISTICS_REQUEST";
export const GET_LABTESTS_STATISTICS_RECEIVE = "GET_LABTESTS_STATISTICS_RECEIVE";
//Get LABTESTS List
export const GET_LABTESTS_REQUEST = "GET_LABTESTS_REQUEST";
export const GET_LABTESTS_RECEIVE = "GET_LABTESTS_RECEIVE";
// Get Labtest
export const GET_LABTEST_REQUEST = "GET_LABTEST_REQUEST";
export const GET_LABTEST_RECEIVE = "GET_LABTEST_RECEIVE";
//Add Program
export const ADD_LABTEST_REQUEST = "ADD_LABTEST_REQUEST";
export const ADD_LABTEST_RECEIVE = "ADD_LABTEST_RECEIVE";
//Edit LABTEST
export const EDIT_LABTEST_REQUEST = "EDIT_LABTEST_REQUEST";
export const EDIT_LABTEST_RECEIVE = "EDIT_LABTEST_RECEIVE";
//Delete LABTEST
export const DELETE_LABTEST_REQUEST = "DELETE_LABTEST_REQUEST";
export const DELETE_LABTEST_RECEIVE = "DELETE_LABTEST_RECEIVE";
//Active LABTEST
export const ACTIVE_LABTEST_REQUEST = "ACTIVE_LABTEST_REQUEST";
export const ACTIVE_LABTEST_RECEIVE = "ACTIVE_LABTEST_RECEIVE";
//Desactive LABTEST
export const DESACTIVE_LABTEST_REQUEST = "DESACTIVE_LABTEST_REQUEST";
export const DESACTIVE_LABTEST_RECEIVE = "DESACTIVE_LABTEST_RECEIVE";
//Shipments status
export const CHANGE_SHIPMENTS_STATUS_REQUEST = "CHANGE_SHIPMENTS_STATUS_REQUEST";
export const CHANGE_SHIPMENTS_STATUS_RECEIVE = "CHANGE_SHIPMENTS_STATUS_RECEIVE";
//Shipping Locations
export const GET_SHIPPING_LOCATIONS_REQUEST = "GET_SHIPPING_LOCATIONS_REQUEST";
export const GET_SHIPPING_LOCATIONS_RECEIVE = "GET_SHIPPING_LOCATIONS_RECEIVE";
//Create send labtest
export const CREATE_SEND_LABTEST_REQUEST = "CREATE_SEND_LABTEST_REQUEST";
export const CREATE_SEND_LABTEST_RECEIVE = "CREATE_SEND_LABTEST_RECEIVE";
//set created labtest
export const SET_CREATED_LABTEST = "SET_CREATED_LABTEST";
//Get Labtest Working Days
export const GET_LABTEST_WORKING_DAYS_REQUEST = "GET_LABTEST_WORKING_DAYS_REQUEST";
export const GET_LABTEST_WORKING_DAYS_RECEIVE = "GET_LABTEST_WORKING_DAYS_RECEIVE";
//Set Labtest Working Days
export const SET_LABTEST_WORKING_DAYS_REQUEST = "SET_LABTEST_WORKING_DAYS_REQUEST";
//Get Obligations
export const GET_OBLIGATIONS_REQUEST = "GET_OBLIGATIONS_REQUEST";
export const GET_OBLIGATIONS_RECEIVE = "GET_OBLIGATIONS_RECEIVE";
// Get Sampling Reservations
export const GET_SAMPLING_RESERVATIONS_REQUEST = "GET_SAMPLING_RESERVATIONS_REQUEST";
export const GET_SAMPLING_RESERVATIONS_RECEIVE = "GET_SAMPLING_RESERVATIONS_RECEIVE";
// Get Samples Data
export const GET_SAMPLES_DATA_REQUEST = "GET_SAMPLES_DATA_REQUEST";
export const GET_SAMPLES_DATA_RECEIVE = "GET_SAMPLES_DATA_RECEIVE";