import React from "react";
import { injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../store/lang/actions";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "./languageBtn.scss";

function LanguageBtn() {
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    if (newValue) {
      dispatch(setLanguage(newValue));
      window.location.reload();
    }
  };
  return (
    <ToggleButtonGroup
      value={lang}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
      className="toggle-wrapper"
    >
      <ToggleButton
        value="ar"
        aria-label="ar lang"
        className="toggle-wrapper_btn"
      >
        AR
      </ToggleButton>
      <ToggleButton
        value="en"
        aria-label="en lang"
        className="toggle-wrapper_btn"
      >
        EN
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default injectIntl(LanguageBtn);
