import * as TYPES from "./types";
import moment from "moment";

const initialState = {
  allTasks: {},
  newTasks: {},
  inprogressTasks: {},
  completedTasks: {},
  taskStatistics: [],
  coordinatorsTasks: {},
  coordinatorsNewTasks: {},
  coordinatorsInprogressTasks: {},
  coordinatorsCompletedTasks: {},
};
export default function tasksReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.ALL_TASKS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      return {
        ...state,
        allTasks: { data, pagination },
      };
    }
    case TYPES.NEW_TASKS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      if (pagination.current_page === 1) {
        return {
          ...state,
          newTasks: { data, pagination },
        };
      } else {
        return {
          ...state,
          newTasks: {
            data: [...state.newTasks.data, ...data],
            pagination,
          }
        }
      }
    }
    case TYPES.INPROGRESS_TASKS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      if (pagination.current_page === 1) {
        return {
          ...state,
          inprogressTasks: { data, pagination },
        }
      } else {
        return {
          ...state,
          inprogressTasks: {
            data: [...state.inprogressTasks.data, ...data],
            pagination,
          }
        }
      }
    }
    case TYPES.COMPLETED_TASKS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      if (pagination.current_page === 1) {
        return {
          ...state,
          completedTasks: { data, pagination },
        }
      } else {
        return {
          ...state,
          completedTasks: {
            data: [...state.completedTasks.data, ...data],
            pagination,
          }
        }
      }
    }
    case TYPES.ADD_TASK_RECEIVE: {
      const now = moment();
      const today = moment().format("YYYY-MM-DD");
      const taskDay = moment(action.payload.complete_date).format("YYYY-MM-DD"); 
      if (moment(taskDay, 'YYYY-MM-DD').diff(moment(today, 'YYYY-MM-DD'), "days") === 0 ) {
          return { 
            ...state, 
            inprogressTasks: { ...state.inprogressTasks, 
              data: [
                ...state.inprogressTasks.data, 
                { ...action.payload, 
                  status_id: 3, 
                  status: "Inprogress", 
                  history: [ {status_id: 1, status: "New", date: now }, { status_id: 2, status: "Inprogress", date: now }] 
                }
              ],
              pagination: {
                ...state.inprogressTasks.pagination,
                total: state.inprogressTasks.pagination.total + 1,
              }
            }
          };
      } else {
          return { 
            ...state, 
            newTasks: { ...state.newTasks, 
              data: [
                ...state.newTasks?.data, 
                { ...action.payload, status_id: 1, status: "New" }
              ],
              pagination: {
                ...state.newTasks?.pagination,
                total: state.newTasks?.pagination.total + 1,
              }
            }
          };
      }
    }
    case TYPES.EDIT_TASK_RECEIVE: {
      if (state.newTasks.data.find(task => task.id === action.payload.id)) {
        return { 
          ...state, 
          newTasks: { 
            ...state.newTasks, 
            data: [ ...state.newTasks.data.map(task => task.id === action.payload.id ? action.payload : task)] }
        };
      } else if (state.inprogressTasks.data.find(task => task.id === action.payload.id)) {
        if (moment(action.payload?.complete_date).diff(moment(), "days") === 0) {
          return { 
            ...state, 
            inprogressTasks: { 
              ...state.inprogressTasks, 
              data: [ ...state.inprogressTasks.data.map(task => task.id === action.payload.id ? action.payload : task)] }
          };
        } else {
          return { 
            ...state, 
            inprogressTasks: { ...state.inprogressTasks, 
              data: [ ...state.inprogressTasks.data.filter(task => task.id !== action.payload.id)],
              pagination: {
                ...state.inprogressTasks.pagination,
                total: state.inprogressTasks.pagination.total - 1,
              }
            },
            newTasks: { ...state.newTasks, 
              data: [ ...state.newTasks.data, { ...action.payload, status_id: 1, status: "New" }],
              pagination: { 
                ...state.newTasks.pagination,
                total: state.newTasks.pagination.total + 1,
              }
            }
          }
        }
      }
      break;
    }
    case TYPES.DELETE_TASK_RECEIVE: {
      return {
        ...state,
        newTasks: { ...state.newTasks, 
          data: state.newTasks?.data?.filter((task) => task.id !== action.payload),
          pagination: {
            ...state.newTasks.pagination,
            total: state.newTasks.pagination.total - 1,
          }
        },
      };
    }
    case TYPES.FINISH_TASK_RECEIVE: {
      return {
        ...state,
        newTasks: { 
          ...state.newTasks,
          pagination: {
            ...state.newTasks.pagination,
            total: state.newTasks?.data?.find((task) => task.id === action.payload.id) ? state.newTasks.pagination.total - 1 : state.newTasks.pagination.total,
          }, 
          data: state.newTasks?.data?.filter((task) => task.id !== action.payload.id)},
        inprogressTasks: { 
          ...state.inprogressTasks,
          pagination: {
            ...state.inprogressTasks.pagination,
            total: state.inprogressTasks?.data?.find((task) => task.id === action.payload.id) ? state.inprogressTasks.pagination.total - 1 : state.inprogressTasks.pagination.total,
          }, 
          data: state.inprogressTasks?.data?.filter((task) => task.id !== action.payload.id)},
        completedTasks: { ...state.completedTasks, 
          data: [...state.completedTasks.data, { ...action.payload, status_id: 4, status: "Completed" }],
          pagination: {
            ...state.completedTasks.pagination,
            total: state.completedTasks.pagination.total + 1,
          }
        },
      };
    }
    //Statistics
    case TYPES.TASKS_STATISTICS_RECEIVE: {
      return {
        ...state,
        taskStatistics: action?.payload?.statistics,
      };
    }
    //Coordinators Tasks
    case TYPES.COORDINATORS_TASKS_RECEIVE: {
      return {
        ...state,
        coordinatorsTasks: action?.payload,
      };
    }
    //Coordinators New Tasks
    case TYPES.COORDINATORS_NEW_TASKS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      if (pagination.current_page === 1) {
        return {
          ...state,
          coordinatorsNewTasks: { data, pagination },
        };
      } else {
        return {
          ...state,
          coordinatorsNewTasks: {
            data: [...state.coordinatorsNewTasks?.data, ...data],
            pagination,
          }
        }
      }
    }
    //Coordinators Inprogress Tasks
    case TYPES.COORDINATORS_INPROGRESS_TASKS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      if (pagination.current_page === 1) {
        return {
          ...state,
          coordinatorsInprogressTasks: { data, pagination },
        };
      } else {
        return {
          ...state,
          coordinatorsInprogressTasks: {
            data: [...state.coordinatorsInprogressTasks?.data, ...data],
            pagination,
          }
        }
      }
    }
    //Coordinators Completed Tasks
    case TYPES.COORDINATORS_COMPLETED_TASKS_RECEIVE: {
      const { data, ...pagination } = action.payload;
      if (pagination.current_page === 1) {
        return {
          ...state,
          coordinatorsCompletedTasks: { data, pagination },
        };
      } else {
        return {
          ...state,
          coordinatorsCompletedTasks: {
            data: [...state.coordinatorsCompletedTasks?.data, ...data],
            pagination,
          }
        }
      }
    }
    default:
      return state;
  }
}
