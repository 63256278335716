import * as TYPES from "./types";

const initialProfileState = {
  update_profile_img_success_msg: null,
};

export default function profileReducer(state = initialProfileState, action) {
  switch (action.type) {
    case TYPES.EDIT_PROFILE_IMAGE_RECEIVE:
      return {
        ...state,
        update_profile_img_success_msg: action.payload,
      };

    default:
      return state;
  }
}
