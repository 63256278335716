import { axiosInstance } from "../../network";

//Get Statistics
export const getLabTestsStatisticsRequest = async () => {
  return await axiosInstance.get('system/v1/lab-tests/statistics');
}
//GET LabTests List
export const getLabTestsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/lab-tests', {params: payload});
}
//GET Labtest
export const getLabTestRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/lab-tests/${payload}`);
}
//Add LabTest
export const addLabTestRequest = async (payload) => {
  return await axiosInstance.post('system/v1/lab-tests', payload);
}
//Edit LabTest
export const editLabTestRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/lab-tests/${payload.id}`, payload);
}
//Delete LabTest
export const deleteLabTestRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/lab-tests/${payload}`);
}
//Active LabTest
export const activeLabTestRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/lab-tests/${payload}/active`);
}
//Desactive LabTest
export const desactiveLabTestRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/lab-tests/${payload}/deactive`);
}
//Change Shipment Status
export const changeShipmentStatusRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patient-lab-tests/${payload.labtestId}/shipment-status`, payload);
}
//Get Shipping Locations
export const getShippingLocationsRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/shipping-locations`, {params: payload});
}
//Create Send LabTest
export const createSendLabTestRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload.patient_id}/patient-lab-tests/create-send`, payload);
}
//Get Labtest Working Days
export const getLabTestWorkingDaysRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patient-lab-tests/${payload.labtest_id}/sampling-reservations/working-days`, {agency_id: parseInt(payload.agency_id)});
}
//Get Obligations
export const getObligationsRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/patient-lab-tests`, {params: payload});
}
//Get Sampling Reservations
export const getSamplingReservationsRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/patient-lab-tests/sampling-reservations`, {params: payload});
}
// Get Samples Data
export const getSamplesDataRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/patient-lab-tests/samples`, {params: payload});
}
