import * as TYPES from "./types";

const initialState = {
  paymentsList: [],
  paymentsStatistics: [],
  paymentAccepted: false,
  successUpload: false,
  paymentRejectionReasons: [],
  paymentRejected: false,
  newCount: 0,
  paymentMethods: [],
  excelDownloaded: null,
  refundedPayment: false,
};

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    //Get Payments list
    case TYPES.GET_PAYMENTS_LIST_RECEIVE: {
      return {
        ...state,
        paymentsList: action.payload,
      }
    }
    case TYPES.GET_PAYMENTS_STATISTICS_RECEIVE: {
      return {
        ...state,
        paymentsStatistics: action.payload.statistics,
      }
    }
    //Accept Payment
    case TYPES.ACCEPT_PAYMENT_RECEIVE: {
      return {
        ...state,
        paymentAccepted: true,
      }
    }
    //Upload Payment Image
    case TYPES.UPLOAD_PAYMENT_IMAGE_RECEIVE: {
      return {
        ...state,
        successUpload: action.payload,
      }
    }
    //Get Payment Rejection Reasons
    case TYPES.GET_PAYMENT_REJECTION_REASONS_RECEIVE: {
      return {
        ...state,
        paymentRejectionReasons: action.payload.data,
      }
    }
    //Reject Payment
    case TYPES.REJECT_PAYMENT_RECEIVE: {
      return {
        ...state,
        paymentRejected: action.payload,
      }
    }
    //New Count
    case TYPES.NEW_COUNT_RECEIVE: {
      return {
        ...state,
        newCount: action.payload,
      }
    }
    //last seen
    case TYPES.LAST_SEEN_RECEIVE: {
      return {
        ...state,
        newCount: 0,
      }
    }
    //Get Payment Methods
    case TYPES.GET_PAYMENT_METHODS_RECEIVE: {
      return {
        ...state,
        paymentMethods: action.payload.data,
      }
    }
    //Hide Payment Bullet
    case TYPES.HIDE_PAYMENT_BULLET_RECEIVE: {
      return {
        ...state,
        paymentsList: {
          ...state.paymentsList,
          data: state.paymentsList?.data?.map(item => {
            if (item.is_new) {
              return {
                ...item,
                is_new: false,
              }
            }
            return item;
          })
        }
      }
    }
    //Download Excel
    case TYPES.DOWNLOAD_EXCEL_RECEIVE: {
      return {
        ...state,
        excelDownloaded: action.payload,
      }
    }
    //Refund Payment
    case TYPES.REFUND_PAYMENT_RECEIVE: {
      return {
        ...state,
        refundedPayment: action.payload,
      }
    }
    default:
      return state
  }
}
