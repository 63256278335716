import * as TYPES from "./types";

const initialState = {
  allRequests: [],
  newRequests: [],
  activeRequests: [],
  rejectedRequests: [],
  completedRequests: [],
  canceledRequests: [],
  totalNoOfAllRequests: 0,
  totalNoOfNewRequests: 0,
  totalNoOfActiveRequests: 0,
  totalNoOfRejectedRequests: 0,
  totalNoOfCompletedRequests: 0,
  totalNoOfCanceledRequests: 0,
  requestResources: [],
  requestRejectionReasons: [],
  rejectionReasons: [],
  request: {},
  patient_id: 0,
  patientRequests: [],
  canceledRequest: false,
  requestAdded: false,
  requestsCancelReasons: [],
};

export default function requestsReducer(state = initialState, action) {
  switch (action.type) {
    //All Requests
    case TYPES.ALL_REQUESTS_RECEIVE: {
      return {
        ...state,
        allRequests: action.payload.data,
        totalNoOfAllRequests: action.payload.setTotal ? action.payload.data.total : state.totalNoOfAllRequests,
      };
    }
    //Completed Requests
    case TYPES.COMPLETED_REQUESTS_RECEIVE: {
      return {
        ...state,
        completedRequests: action.payload.data,
        totalNoOfCompletedRequests: action.payload.setTotal ? action.payload.data.total : state.totalNoOfCompletedRequests,
      };
    }
    //Canceled requests
    case TYPES.CANCELED_REQUESTS_RECEIVE: {
      return {
        ...state,
        canceledRequests: action.payload.data,
        totalNoOfCanceledRequests: action.payload.setTotal ? action.payload.data.total : state.totalNoOfCanceledRequests,
      };
    }
    // New Requests List
    case TYPES.NEW_REQUESTS_RECEIVE: {
      return {
        ...state,
        newRequests: action.payload.data,
        totalNoOfNewRequests: action.payload.setTotal ? action.payload.data.total : state.totalNoOfNewRequests,
      };
    }
    // Active Requests List
    case TYPES.ACTIVE_REQUESTS_RECEIVE: {
      return { 
        ...state, 
        activeRequests: action.payload.data,
        totalNoOfActiveRequests: action.payload.setTotal ? action.payload.data.total : state.totalNoOfActiveRequests,
      };
    }
    // Rejected Requests List
    case TYPES.REJECTED_REQUESTS_RECEIVE: {
      return { 
        ...state, 
        rejectedRequests: action.payload.data,
        totalNoOfRejectedRequests: action.payload.setTotal ? action.payload.data.total : state.totalNoOfRejectedRequests 
      };
    }
    // Request Resources
    case TYPES.REQUEST_SOURCES_RECEIVE: {
      return { ...state, requestResources: action.payload };
    }
    // show 1 Request
    case TYPES.SHOW_REQUEST_RECEIVE: {
      return { ...state, request: action.payload };
    }
    // Request Rejection Reasons
    case TYPES.REQUEST_REJECTION_REASONS_RECEIVE: {
      return { ...state, requestRejectionReasons: action.payload };
    }
    // Rejection Reasons
    case TYPES.REJECTION_REASONS_RECEIVE: {
      return { ...state, rejectionReasons: action.payload };
    }
    // Accept Request
    case TYPES.ACCEPT_REQUEST_RECEIVE: {
      return { ...state, patient_id: action.payload };
    }
    //Patient Requests
    case TYPES.PATIENT_REQUESTS_RECEIVE: {
      return { ...state, patientRequests: action.payload?.data };
    }
    case TYPES.SET_CANCELED_REQUEST: {
      return { ...state, canceledRequest: action.payload }
    }
    case TYPES.ADD_NEW_REQUEST_RECEIVE: {
      return { ...state, requestAdded: true }
    }
    case TYPES.SET_REQUEST_ADDED: {
      return { ...state, requestAdded: action.payload }
    }
    case TYPES.REQUESTS_CANCEL_REASONS_RECEIVE: {
      return { ...state, requestsCancelReasons: action.payload }
    }
    default:
      return state;
  }
}
