import * as TYPES from "./types";

const initialState = {
  coordinatorAppointmentStatistics: {},
  coordinatorObligationStatistics: {},
  coordinatorPaymentStatistics: {},
  coordinatorSamplingStatistics: {},
  coordinatorSamplingDataStatistics: {},
  coordinatorTaskStatistics: {},
};

export default function statisticssReducer(state = initialState, action) {
  switch (action.type) {
    //Get Coordinator statistics
    case TYPES.GET_COORDINATOR_STATISTICS_RECEIVE: {
      return {
        ...state,
        coordinatorAppointmentStatistics: action.payload.statistics,
      }
    }
   //Get Obligations statistics
    case TYPES.GET_OBLIGATIONS_STATISTICS_RECEIVE: {
      return {
        ...state,
        coordinatorObligationStatistics: action.payload.statistics,
      }
    }
    // Get Payments statistics
    case TYPES.GET_COORDINATOR_PAYMENTS_STATISTICS_RECEIVE: {
      return {
        ...state,
        coordinatorPaymentStatistics: action.payload.statistics,
      }
    }
    // Get Sampling statistics
    case TYPES.GET_COORDINATOR_SAMPLING_STATISTICS_RECEIVE: {
      return {
        ...state,
        coordinatorSamplingStatistics: action.payload.statistics,
      }
    }
    // Get Sampling Data statistics
    case TYPES.GET_COORDINATOR_SAMPLING_DATA_STATISTICS_RECEIVE: {
      return {
        ...state,
        coordinatorSamplingDataStatistics: action.payload.statistics,
      }
    }
    // Get Coordinator Tasks statistics
    case TYPES.GET_COORDINATOR_TASKS_STATISTICS_RECEIVE: {
      return {
        ...state,
        coordinatorTaskStatistics: action.payload.statistics,
      }
    }
    default:
      return state
  }
}
