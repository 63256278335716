import { axiosInstance } from "../../network";

export const newRequestsRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/requests`, { params: payload });
};

export const requestSourcesRequest = async () => {
  return await axiosInstance.get(`system/v1/request-sources`);
};
export const showRequest= async (payload) => {
  return await axiosInstance.get(`system/v1/requests/${payload.payload}`);
};
// Rejection Reasons for 1 request
export const requestRejectionReasons = async (payload) => {
  return await axiosInstance.get(`system/v1/requests/${payload.id}/reject-reasons`);
};
// Rejection Reasons
export const rejectionReasons = async (payload) => {
  return await axiosInstance.get(`system/v1/request-reject-reasons`)
};
//Reject request
export const rejectRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/requests/${payload.id}/reject`, {
     ...payload.data,
  });
};
//Accept request`
export const acceptRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/requests/${payload.id}/accept`);
}
//Active Requests
export const activeRequestsRequest = async (payload) => {
  return await axiosInstance.get(`system/v1/requests`, { params: payload });
};
//Rejected Requests
export const rejectedRequests = async (payload) => {
  return await axiosInstance.get(`system/v1/requests`, { params: payload });
}
//All Requests
export const allRequests = async (payload) => {
  return await axiosInstance.get(`system/v1/requests`, { params: payload });
}
//Completed Requests
export const completedRequests = async (payload) => {
  return await axiosInstance.get(`system/v1/requests`, { params: payload });
}
//Canceled requests
export const canceledRequests = async (payload) => {
  return await axiosInstance.get(`system/v1/requests`, { params: payload });
}
//Patient Requests
export const patientRequests = async (payload) => {
  return await axiosInstance.get(`system/v1/requests`, { params: payload });
}
//Cancel Request
export const cancelRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/requests/${payload.id}/cancel`, payload);
}
//Add new request
export const addNewRequest = async (payload) => {
  return await axiosInstance.post(`system/v1/patients/${payload.patientId}/requests`, {message_text: payload.message_text});
}
//Get requests cancel reasons
export const requestsCancelReasons = async (payload) => {
  return await axiosInstance.get(`system/v1/request-cancel-reasons`);
}