import * as TYPES from "./types";

const initialDashboardState = {
  side_menu: null,
  requestStatistics: null,
};

export default function dashboardReducer(
  state = initialDashboardState,
  action
) {
  switch (action.type) {
    case TYPES.SIDE_MENU_RECEIVE:
      return {
        ...state,
        side_menu: action.payload,
      };
    case TYPES.REQUEST_STATISTICS_RECEIVE:
      return {
        ...state,
        requestStatistics: action.payload.statistics,
      };
    default:
      return state;
  }
}
