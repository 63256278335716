import * as TYPES from "./types";

// EDIT  PROFILE IMAGE
export const editProfileImageRequest = (payload) => ({
  type: TYPES.EDIT_PROFILE_IMAGE_REQUEST,
  payload,
});
export const editProfileImageReceive = (payload) => ({
  type: TYPES.EDIT_PROFILE_IMAGE_RECEIVE,
  payload,
});
