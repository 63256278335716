import * as TYPES from "./types";

// Get Coordinator statistics
export const getCoordinatorStatisticsRequest = (payload) => ({
  type: TYPES.GET_COORDINATOR_STATISTICS_REQUEST,
  payload,
});
export const getCoordinatorStatisticsReceive = (payload) => ({
  type: TYPES.GET_COORDINATOR_STATISTICS_RECEIVE,
  payload,
});
// Get Obligations statistics
export const getObligationsStatisticsRequest = (payload) => ({
  type: TYPES.GET_OBLIGATIONS_STATISTICS_REQUEST,
  payload,
});
export const getObligationsStatisticsReceive = (payload) => ({
  type: TYPES.GET_OBLIGATIONS_STATISTICS_RECEIVE,
  payload,
});
// Get Payments statistics
export const getCoordinatorPaymentsStatisticsRequest = (payload) => ({
  type: TYPES.GET_COORDINATOR_PAYMENTS_STATISTICS_REQUEST,
  payload,
});
export const getCoordinatorPaymentsStatisticsReceive = (payload) => ({
  type: TYPES.GET_COORDINATOR_PAYMENTS_STATISTICS_RECEIVE,
  payload,
});
// Get Sampling statistics
export const getCoordinatorSamplingStatisticsRequest = (payload) => ({
  type: TYPES.GET_COORDINATOR_SAMPLING_STATISTICS_REQUEST,
  payload,
});
export const getCoordinatorSamplingStatisticsReceive = (payload) => ({
  type: TYPES.GET_COORDINATOR_SAMPLING_STATISTICS_RECEIVE,
  payload,
});
// Get Sampling Data statistics
export const getCoordinatorSamplingDataStatisticsRequest = (payload) => ({
  type: TYPES.GET_COORDINATOR_SAMPLING_DATA_STATISTICS_REQUEST,
  payload,
});
export const getCoordinatorSamplingDataStatisticsReceive = (payload) => ({
  type: TYPES.GET_COORDINATOR_SAMPLING_DATA_STATISTICS_RECEIVE,
  payload,
});
// Get Coordinator Tasks statistics
export const getCoordinatorTasksStatisticsRequest = (payload) => ({
  type: TYPES.GET_COORDINATOR_TASKS_STATISTICS_REQUEST,
  payload,
});
export const getCoordinatorTasksStatisticsReceive = (payload) => ({
  type: TYPES.GET_COORDINATOR_TASKS_STATISTICS_RECEIVE,
  payload,
});