//Patient Statistics
export const GET_PATIENTS_STATISTICS_REQUEST = "GET_PATIENTS_STATISTICS_REQUEST";
export const GET_PATIENTS_STATISTICS_RECEIVE = "GET_PATIENTS_STATISTICS_RECEIVE";
//Get PATIENTS List
export const GET_PATIENTS_REQUEST = "GET_PATIENTS_REQUEST";
export const GET_PATIENTS_RECEIVE = "GET_PATIENTS_RECEIVE";
//Get My PATIENTS
export const GET_MY_PATIENTS_REQUEST = "GET_MY_PATIENTS_REQUEST";
export const GET_MY_PATIENTS_RECEIVE = "GET_MY_PATIENTS_RECEIVE";
//Get PATIENT
export const GET_PATIENT_REQUEST = "GET_PATIENT_REQUEST";
export const GET_PATIENT_RECEIVE = "GET_PATIENT_RECEIVE";
//Add PATIENT
export const ADD_PATIENT_REQUEST = "ADD_PATIENT_REQUEST";
export const ADD_PATIENT_RECEIVE = "ADD_PATIENT_RECEIVE";
//Edit PATIENT
export const EDIT_PATIENT_REQUEST = "EDIT_PATIENT_REQUEST";
export const EDIT_PATIENT_RECEIVE = "EDIT_PATIENT_RECEIVE";
//Delete PATIENT
export const DELETE_PATIENT_REQUEST = "DELETE_PATIENT_REQUEST";
export const DELETE_PATIENT_RECEIVE = "DELETE_PATIENT_RECEIVE";
//Active PATIENT
export const ACTIVE_PATIENT_REQUEST = "ACTIVE_PATIENT_REQUEST";
export const ACTIVE_PATIENT_RECEIVE = "ACTIVE_PATIENT_RECEIVE";
//Desactive PATIENT
export const DESACTIVE_PATIENT_REQUEST = "DESACTIVE_PATIENT_REQUEST";
export const DESACTIVE_PATIENT_RECEIVE = "DESACTIVE_PATIENT_RECEIVE";
//Get families
export const GET_FAMILIES_REQUEST = "GET_FAMILIES_REQUEST";
export const GET_FAMILIES_RECEIVE = "GET_FAMILIES_RECEIVE";
//Add Family
export const ADD_FAMILY_REQUEST = "ADD_FAMILY_REQUEST";
export const ADD_FAMILY_RECEIVE = "ADD_FAMILY_RECEIVE";
//Set Valide to False
export const SET_VALIDE_FALSE = "SET_VALIDE_FALSE";
//ValidStep
export const VALID_STEP = "VALID_STEP";
//ValidID
export const VALID_ID_REQUEST = "VALID_ID_REQUEST";
export const VALID_ID_RECEIVE = "VALID_ID_RECEIVE";
//Patient ValidID
export const PATIENT_VALID_ID_REQUEST = "PATIENT_VALID_ID_REQUEST";
export const PATIENT_VALID_ID_RECEIVE = "PATIENT_VALID_ID_RECEIVE";
//Reset ValidId
export const RESET_VALID_ID = "RESET_VALID_ID";
//Sampling Reservation
export const SAMPLING_RESERVATION_REQUEST = "SAMPLING_RESERVATION_REQUEST";
export const SAMPLING_RESERVATION_RECEIVE = "SAMPLING_RESERVATION_RECEIVE";
//Update Sampling Reservation
export const UPDATE_SAMPLING_RESERVATION_REQUEST = "UPDATE_SAMPLING_RESERVATION_REQUEST";
export const UPDATE_SAMPLING_RESERVATION_RECEIVE = "UPDATE_SAMPLING_RESERVATION_RECEIVE";
//Sampling Force Deposited
export const SAMPLING_FORCE_DEPOSITED_REQUEST = "SAMPLING_FORCE_DEPOSITED_REQUEST";
export const SAMPLING_FORCE_DEPOSITED_RECEIVE = "SAMPLING_FORCE_DEPOSITED_RECEIVE";
//Set Sample Reserved
export const SET_SAMPLE_RESERVED = "SET_SAMPLE_RESERVED";
//Get Patient Notes
export const GET_PATIENT_NOTES_REQUEST = "GET_PATIENT_NOTES_REQUEST";
export const GET_PATIENT_NOTES_RECEIVE = "GET_PATIENT_NOTES_RECEIVE";
//Add Patient Note
export const ADD_PATIENT_NOTE_REQUEST = "ADD_PATIENT_NOTE_REQUEST";
export const ADD_PATIENT_NOTE_RECEIVE = "ADD_PATIENT_NOTE_RECEIVE";