import React, { Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import history from "./history";
import * as LazyComponent from "../utils/LazyLoaded";
import Loader from "../components/Loader/Loader";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";

const Routes = (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
        <PrivateRoute component={LazyComponent.Home} path="/" exact />
        <PrivateRoute component={LazyComponent.Home} path="/dashboard" exact />
        <PrivateRoute component={LazyComponent.Login} path="/login" exact />
        <PrivateRoute component={LazyComponent.AgenciesLogin} path="/agencies/login" exact />
        <PrivateRoute component={LazyComponent.OTP} path="/otp" exact />
        <PrivateRoute component={LazyComponent.AgenciesOTP} path="/agencies/otp" exact />
        <PrivateRoute component={LazyComponent.ForgetPassword} path="/forgetPassword" exact />
        <PrivateRoute component={LazyComponent.ResetPassword} path="/resetPassword" exact />
        <PrivateRoute component={LazyComponent.SetPassword} path="/setPassword" exact />
        <PrivateRoute component={LazyComponent.AgenciesForgetPassword} path="/agencies/forgetPassword" exact />
        <PrivateRoute component={LazyComponent.AgenciesResetPassword} path="/agencies/resetPassword" exact />
        <PrivateRoute component={LazyComponent.AgenciesSetPassword} path="/agencies/setPassword" exact />
        <PrivateRoute component={LazyComponent.ResetPasswordSuccessMsg} path="/resetPassword-success" exact />
        <PrivateRoute component={LazyComponent.AgenciesResetPasswordSuccessMsg} path="/agencies/resetPassword-success" exact />
        <PrivateRoute component={LazyComponent.Profile} path="/profile" exact />
        <PrivateRoute component={LazyComponent.Forms} path="/forms" exact />
        <PrivateRoute component={LazyComponent.Tables} path="/tables" exact />
        <PrivateRoute component={LazyComponent.AllRequests} path="/allRequests" exact />
        <PrivateRoute component={LazyComponent.NewRequests} path="/newRequests" exact />
        <PrivateRoute component={LazyComponent.RejectedRequests} path="/rejectedRequests" exact />
        <PrivateRoute component={LazyComponent.ActiveRequests} path="/activeRequests" exact />
        <PrivateRoute component={LazyComponent.CompletedRequests} path="/completedRequests" exact />
        <PrivateRoute component={LazyComponent.CanceledRequests} path="/canceledRequests" exact />
        <PrivateRoute component={LazyComponent.Tasks} path="/tasks" exact />
        <PrivateRoute component={LazyComponent.Users} path="/users" exact />
        <PrivateRoute component={LazyComponent.InternalUsers} path="/users/internal" exact />
        <PrivateRoute component={LazyComponent.AddUser} path="/users/internal/add" exact />
        <PrivateRoute component={LazyComponent.EditUser} path="/users/internal/edit/:id" exact />
        <PrivateRoute component={LazyComponent.LabUsers} path="/users/labs" exact />
        <PrivateRoute component={LazyComponent.AddUsersLab} path="/users/labs/add" exact />
        <PrivateRoute component={LazyComponent.EditUsersLab} path="/users/labs/edit/:id" exact />
        <PrivateRoute component={LazyComponent.HospitalsUsers} path="/users/hospitals" exact />
        <PrivateRoute component={LazyComponent.AddUsersHospital} path="/users/hospitals/add" exact />
        <PrivateRoute component={LazyComponent.EditUsersHospital} path="/users/hospitals/edit/:id" exact />
        <PrivateRoute component={LazyComponent.LabTests} path="/lab-tests" exact />
        <PrivateRoute component={LazyComponent.AddLab} path="/lab-tests/add" exact />
        <PrivateRoute component={LazyComponent.EditLab} path="/lab-tests/edit/:id" exact />
        <PrivateRoute component={LazyComponent.ReferenceLabs} path="/reference-labs" exact />
        <PrivateRoute component={LazyComponent.AddRef} path="/reference-labs/add" exact />
        <PrivateRoute component={LazyComponent.EditRef} path="/reference-labs/edit/:id" exact />
        <PrivateRoute component={LazyComponent.Programs} path="/programs" exact />
        <PrivateRoute component={LazyComponent.PatientProfile} path="/patient-profile/:id" exact />
        <PrivateRoute component={LazyComponent.PatientPreview} path="/patient-preview/:id" exact />
        <PrivateRoute component={LazyComponent.Patients} path="/clients" exact />
        <PrivateRoute component={LazyComponent.MyPatients} path="/myPatients" exact />
        <PrivateRoute component={LazyComponent.AddPatient} path="/clients/add" exact />
        <PrivateRoute component={LazyComponent.EditPatient} path="/clients/edit/:id" exact />
        <PrivateRoute component={LazyComponent.allAppointments} path="/allAppointments" exact />
        <PrivateRoute component={LazyComponent.newAppointments} path="/newAppointments" exact />
        <PrivateRoute component={LazyComponent.activeAppointments} path="/activeAppointments" exact />
        <PrivateRoute component={LazyComponent.CanceledAppointments} path="/canceledAppointments" exact />
        <PrivateRoute component={LazyComponent.CompletedAppointments} path="/completedAppointments" exact />
        <PrivateRoute component={LazyComponent.CurrentAppointment} path="/appointment/:id" exact />
        <PrivateRoute component={LazyComponent.AppointmentStatistics} path="/appointmentStatistics" exact />
        <PrivateRoute component={LazyComponent.Payments} path="/payments" exact />
        <PrivateRoute component={LazyComponent.CoordinatorAppointments} path="/appointments" exact />
        <PrivateRoute component={LazyComponent.Obligations} path="/obligations" exact />
        <PrivateRoute component={LazyComponent.PaymentsDetails} path="/paymentsDetails" exact />
        <PrivateRoute component={LazyComponent.SamplingDetails} path="/samplingDetails" exact />
        <PrivateRoute component={LazyComponent.Samples} path="/samples" exact />
        <PrivateRoute component={LazyComponent.RequestsReports} path="/reports/requests" exact />
        <PrivateRoute component={LazyComponent.CoordinatorsTasks} path="/coordinatorsTasks" exact />
        
        <LazyComponent.NotFound path="**" title="This page doesn't exist..." exact />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
