import * as TYPES from "./types";

const initialState = {
  contacts: {},
  contact: {},
  addedContact: false,
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    //Get Contacts
    case TYPES.GET_CONTACTS_RECEIVE: {
      return {
        ...state,
        contacts: action.payload,
      }
    }
    //Get Contact
    case TYPES.GET_CONTACT_RECEIVE: {
      return {
        ...state,
        contact: action.payload.data,
      }
    }
    //Delete Contact
    case TYPES.DELETE_CONTACT_RECEIVE: {
      return {
        ...state,
        contacts: { ...state.contacts, data: state.contacts?.data?.filter((contact) => contact.id !== action.payload) }
      }
    }
    //Set added contact
    case TYPES.SET_ADDED_CONTACT: {
      return {
        ...state,
        addedContact: action.payload,
      }
    }
    default:
      return state
  }
}
