//Get USERSLABS List
export const GET_USERSLABS_REQUEST = "GET_USERSLABS_REQUEST";
export const GET_USERSLABS_RECEIVE = "GET_USERSLABS_RECEIVE";
//Get USERSLAB
export const GET_USERSLAB_REQUEST = "GET_USERSLAB_REQUEST";
export const GET_USERSLAB_RECEIVE = "GET_USERSLAB_RECEIVE";
//Add USERSLAB
export const ADD_USERSLAB_REQUEST = "ADD_USERSLAB_REQUEST";
export const ADD_USERSLAB_RECEIVE = "ADD_USERSLAB_RECEIVE";
//Edit USERSLAB
export const EDIT_USERSLAB_REQUEST = "EDIT_USERSLAB_REQUEST";
export const EDIT_USERSLAB_RECEIVE = "EDIT_USERSLAB_RECEIVE";
//Delete USERSLAB
export const DELETE_USERSLAB_REQUEST = "DELETE_USERSLAB_REQUEST";
export const DELETE_USERSLAB_RECEIVE = "DELETE_USERSLAB_RECEIVE";
//Active USERSLAB
export const ACTIVE_USERSLAB_REQUEST = "ACTIVE_USERSLAB_REQUEST";
export const ACTIVE_USERSLAB_RECEIVE = "ACTIVE_USERSLAB_RECEIVE";
//Desactive USERSLAB
export const DESACTIVE_USERSLAB_REQUEST = "DESACTIVE_USERSLAB_REQUEST";
export const DESACTIVE_USERSLAB_RECEIVE = "DESACTIVE_USERSLAB_RECEIVE";
//Get Cities
export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_RECEIVE = "GET_CITIES_RECEIVE";
//Get Regions
export const GET_REGIONS_REQUEST = "GET_REGIONS_REQUEST";
export const GET_REGIONS_RECEIVE = "GET_REGIONS_RECEIVE";
//Get cities by region
export const GET_CITIES_BY_REGION_REQUEST = "GET_CITIES_BY_REGION_REQUEST";
export const GET_CITIES_BY_REGION_RECEIVE = "GET_CITIES_BY_REGION_RECEIVE";
//Get Agencies List
export const GET_AGENCIES_LIST_REQUEST = "GET_AGENCIES_LIST_REQUEST";
export const GET_AGENCIES_LIST_RECEIVE = "GET_AGENCIES_LIST_RECEIVE";
//Check City Agency
export const CHECK_CITY_AGENCY_REQUEST = "CHECK_CITY_AGENCY_REQUEST";
export const CHECK_CITY_AGENCY_RECEIVE = "CHECK_CITY_AGENCY_RECEIVE";