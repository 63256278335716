import axios from "axios";
import { requestHandler, successHandler, errorHandler } from "./interceptors";

//add your BASE_URL to Constants file
const lang = localStorage.getItem("lang");
const token = localStorage.getItem("token");
export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": lang,
    Authorization: `Bearer ${token}`,
  },
});

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
