import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import clsx from "clsx";
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { useStyles } from "./SideMenuStyles";
import history from "../../routes/history";
import "./sideMenu.scss";
import { white_logo } from "../../utils/images";
import { useDispatch, useSelector } from "react-redux";
import { sideMenuRequest } from "../../store/dashboard/actions";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { agenciesLogoutRequest, logoutRequest } from "../../store/authentication/actions";
import { ReactComponent as MenuHomeIcon } from "../../assets/images/icons/main.svg";
import { ReactComponent as MenuClientsIcon } from "../../assets/images/icons/people.svg";
import { ReactComponent as MenuMessagesIcon } from "../../assets/images/icons/message.svg";
import { ReactComponent as MenuRequestsIcon } from "../../assets/images/icons/client.svg";
import { ReactComponent as MenuTasksIcon } from "../../assets/images/icons/tasks.svg";
import MenuSubscriptionIcon from "../../assets/images/icons/subscription.png";
import { ReactComponent as MenuDiseasesIcon } from "../../assets/images/icons/diseases.svg";
import { ReactComponent as MenuReportsIcon } from "../../assets/images/icons/reports.svg";
import MenuRefLabsIcon from "../../assets/images/icons/reference_labs.png";
import { ReactComponent as MyAppointmentsIcon } from "../../assets/images/icons/my_appointments.svg";
import { ReactComponent as PatientsListIcon } from "../../assets/images/icons/patients_list.svg";
import MenuLabtests from "../../assets/images/icons/menu-labtests.png";
import MenuPayement from "../../assets/images/icons/menu-payment.png";

const IconsRender = ({itemID}) => {
  switch (itemID) {
    case 1:
      return <MenuHomeIcon />;
    case 2:
      return <MenuClientsIcon />;
    case 3:
      return <MenuRequestsIcon />;
    case 4:
      return <MenuMessagesIcon />;
    case 5:
      return <MenuTasksIcon />;
    case 6:
      return <MenuRequestsIcon  style={{ width: '20px'}} />;
    case 7:
      return <img src={MenuSubscriptionIcon} alt="icon" width={21} />;
    case 8:
      return <img src={MenuLabtests} alt='icon' width={21} height={17} style={{ objectFit: "contain" }} />;
    case 9:
      return <MenuDiseasesIcon />;
    case 10:
      return <img src={MenuPayement} alt='icon' width={20} />;
    case 11:
      return <MenuMessagesIcon />;
    case 12:
      return <MenuReportsIcon />;
    case 13:
      return <img src={MenuRefLabsIcon} alt="icon" width={21} />;
    case 14:
      return <PatientsListIcon style={{ width: '21px'}} />;
    case 15:
      return <MyAppointmentsIcon style={{ width: '20px'}} />;
    case 16:
      return <MyAppointmentsIcon style={{ width: '20px'}} />;
    case 17:
      return <MenuReportsIcon style={{ width: '20px'}} />;
    default:
      return null;
  }
}

const SideMenu = ({ open, intl: { messages }, theme, handleCloseDrawer }) => {
  const lang = useSelector((state) => state.lang);
  const token = useSelector((state) => state.auth?.token?.token) || localStorage.getItem("token");
  const { newCount } = useSelector((state) => state.payments);
  const dispatch = useDispatch();
  const side_menu = useSelector((state) => state?.dashboard?.side_menu?.data);
  const [openList, setOpenList] = React.useState(null);
  const roleId = parseInt(localStorage.getItem("role"));
  const classes = useStyles();
  const [selected, setSelected] = useState(history.location.pathname === "" || history.location.pathname === "/" ? "/dashboard" : history.location.pathname);

  useEffect(() => {
    setSelected(history.location.pathname === "" || history.location.pathname === "/" ? "/dashboard" : history.location.pathname);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  // OPEN SUBMENU 
  const mysubmenus = ["/allRequests", "/newRequests", "/activeRequests", "/completedRequests", "/canceledRequests", "/rejectedRequests"];
  const usersSubmenus = ["/users", "/users/internal", "/users/internal/add", "/users/internal/edit", "/users/labs", "/users/labs/add", "/users/labs/edit", "/users/hospitals", "/users/hospitals/add", "/users/hospitals/edit"];
  const appointmentsSubmenus = ["/allAppointments", "/newAppointments", "/activeAppointments", "/notCompletedAppointments", "/completedAppointments", "/canceledAppointments"];
  const reports = ["/reports", "/reports/requests"];
  
  useEffect(() => {
    if (mysubmenus.find((item) => item === history.location.pathname) && roleId === 2) {
        setOpenList(2);
    } else if (usersSubmenus.find((item) =>  history.location.pathname.includes(item))) {
        roleId ===  1 ? setOpenList(2) : setOpenList(1);
    } else if (appointmentsSubmenus.find((item) =>  history.location.pathname.includes(item))) {
        setOpenList(2);
    } else if (reports.find((item) =>  history.location.pathname.includes(item))) {
        setOpenList(1);
    } else {
        setOpenList(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);
 
  const handleListItemClick = (e, link) => {
    setSelected(link);
  };

  const handleClick = (index) => {
    if (openList === index) {
      setOpenList(null);
    } else {
      setOpenList(index);
    }
  };

  useEffect(() => {
    if (token && lang) {
      dispatch(sideMenuRequest(roleId === 7 || roleId === 8 ? {type: "agency"} : null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lang]);

  const renderList = () => {
    return side_menu?.map((item, index) =>
      item.has_menu ? (
        <React.Fragment key={index}>
          <ListItem button onClick={() => handleClick(index)}  >
            <ListItemIcon className="sideMenu_item_icon" ><IconsRender itemID={item?.id} /></ListItemIcon>
            <ListItemText primary={item?.name} className="fz-09" />
            {openList === index ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openList === index} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item?.submenu?.map((i, ind) => (
                <Link to={i.url !== "" ? i.url : history.location.pathname} key={`${index + "-" + ind}`}>
                  <ListItem
                    className={`${
                      lang === "en"
                        ? "sideMenu_nested-en sideMenu_border-left"
                        : "sideMenu_nested-ar sideMenu_border-right"
                    }`}
                    selected={i?.url?.length > 0 && selected.includes(i.url) ? true : false}
                    onClick={(event) =>
                      item?.url?.length > 0 && handleListItemClick(event, i.url)
                    }
                    button
                  >
                    <ListItemText primary={i?.name} className="fz-09" />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ) : (
        <Link to={item.url !== "" ? item.url : history.location.pathname} key={index}>
          <ListItem
            className={`${
              lang === "en"
                ? "sideMenu_item sideMenu_border-left"
                : "sideMenu_item sideMenu_border-right"
            }`}
            button
            selected={item.url !== "" && selected === item.url ? true : false}
            onClick={(event) =>
              item?.url.length > 0 && handleListItemClick(event, item.url)
            }
          >
            <ListItemIcon className="sideMenu_item_icon" ><IconsRender itemID={item?.id} /></ListItemIcon>
            <ListItemText className="fz-09"> 
              <span className="name_bullet_container">{item.name}&nbsp;&nbsp;&nbsp;{item?.id === 10 && newCount > 0 && <span className="notification_bullet">&nbsp;</span>}</span>
            </ListItemText>
          </ListItem>
        </Link>
      )
    );
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className="side-menu_logo">
        <img src={white_logo} alt="logo" />
      </div>
      <Divider />
      <List className="sideMenu">
        {side_menu?.length > 0 && renderList()}{" "}
        <ListItem
          className="sideMenu_item"
          button
          onClick={() => {roleId === 7 || roleId === 8 ? dispatch(agenciesLogoutRequest()) : dispatch(logoutRequest())}}
        >
          <ListItemIcon className="sideMenu_item_icon">
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={messages.auth.logout} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default injectIntl(SideMenu);