export const OLD_NOTIFICATIONS_REQUEST = "OLD_NOTIFICATIONS_REQUEST";
export const OLD_NOTIFICATIONS_RECEIVE = "OLD_NOTIFICATIONS_RECEIVE";

export const NEW_NOTIFICATIONS_REQUEST = "NEW_NOTIFICATIONS_REQUEST";
export const NEW_NOTIFICATIONS_RECEIVE = "NEW_NOTIFICATIONS_RECEIVE";

export const COUNT_NOTIFICATIONS_REQUEST = "COUNT_NOTIFICATIONS_REQUEST";
export const COUNT_NOTIFICATIONS_RECEIVE = "COUNT_NOTIFICATIONS_RECEIVE";

export const MARK_ASREADED_REQUEST = "MARK_ASREADED_REQUEST";
export const MARK_ASREADED_RECEIVE = "MARK_ASREADED_RECEIVE";

export const REALTIME_NOTIFICATIONS_REQUEST = "REALTIME_NOTIFICATIONS_REQUEST";
export const REALTIME_NOTIFICATIONS_RECEIVE = "REALTIME_NOTIFICATIONS_RECEIVE";
