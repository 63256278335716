import * as TYPES from "./types";
//all
export const allTasksRequest = (payload) => ({
  type: TYPES.ALL_TASKS_REQUEST,
  payload,
});
export const allTasksReceive = (payload) => ({
  type: TYPES.ALL_TASKS_RECEIVE,
  payload,
});
//new
export const newTasksRequest = (payload) => ({
  type: TYPES.NEW_TASKS_REQUEST,
  payload,
});
export const newTasksReceive = (payload) => ({
  type: TYPES.NEW_TASKS_RECEIVE,
  payload,
});
//inprogress
export const inprogressTasksRequest = (payload) => ({
  type: TYPES.INPROGRESS_TASKS_REQUEST,
  payload,
});
export const inprogressTasksReceive = (payload) => ({
  type: TYPES.INPROGRESS_TASKS_RECEIVE,
  payload,
});
//completed
export const completedTasksRequest = (payload) => ({
  type: TYPES.COMPLETED_TASKS_REQUEST,
  payload,
});
export const completedTasksReceive = (payload) => ({
  type: TYPES.COMPLETED_TASKS_RECEIVE,
  payload,
});

//Add
export const addTaskRequest = (payload) => ({
  type: TYPES.ADD_TASK_REQUEST,
  payload,
});
export const addTaskReceive = (payload) => ({
  type: TYPES.ADD_TASK_RECEIVE,
  payload,
});
//Edit
export const editTaskRequest = (payload) => ({
  type: TYPES.EDIT_TASK_REQUEST,
  payload,
});
export const editTaskReceive = (payload) => ({
  type: TYPES.EDIT_TASK_RECEIVE,
  payload,
});
//Delete
export const deleteTaskRequest = (payload) => ({
  type: TYPES.DELETE_TASK_REQUEST,
  payload,
});
export const deleteTaskReceive = (payload) => ({
  type: TYPES.DELETE_TASK_RECEIVE,
  payload,
});
//Finish
export const finishTaskRequest = (payload) => ({
  type: TYPES.FINISH_TASK_REQUEST,
  payload,
});
export const finishTaskReceive = (payload) => ({
  type: TYPES.FINISH_TASK_RECEIVE,
  payload,
});
//Statistics
export const tasksStatisticsRequest = (payload) => ({
  type: TYPES.TASKS_STATISTICS_REQUEST,
  payload,
});
export const tasksStatisticsReceive = (payload) => ({
  type: TYPES.TASKS_STATISTICS_RECEIVE,
  payload,
});
// Coordinators Tasks 
export const coordinatorsTasksRequest = (payload) => ({
  type: TYPES.COORDINATORS_TASKS_REQUEST,
  payload,
});
export const coordinatorsTasksReceive = (payload) => ({
  type: TYPES.COORDINATORS_TASKS_RECEIVE,
  payload,
});
//Coordinators New Tasks
export const coordinatorsNewTasksRequest = (payload) => ({
  type: TYPES.COORDINATORS_NEW_TASKS_REQUEST,
  payload,
});
export const coordinatorsNewTasksReceive = (payload) => ({
  type: TYPES.COORDINATORS_NEW_TASKS_RECEIVE,
  payload,
});
//Coordinators Inprogress Tasks
export const coordinatorsInprogressTasksRequest = (payload) => ({
  type: TYPES.COORDINATORS_INPROGRESS_TASKS_REQUEST,
  payload,
});
export const coordinatorsInprogressTasksReceive = (payload) => ({
  type: TYPES.COORDINATORS_INPROGRESS_TASKS_RECEIVE,
  payload,
});
//Coordinators Completed Tasks
export const coordinatorsCompletedTasksRequest = (payload) => ({
  type: TYPES.COORDINATORS_COMPLETED_TASKS_REQUEST,
  payload,
});
export const coordinatorsCompletedTasksReceive = (payload) => ({
  type: TYPES.COORDINATORS_COMPLETED_TASKS_RECEIVE,
  payload,
});
