//Get Statistics
export const GET_REFS_STATISTICS_REQUEST = "GET_REFS_STATISTICS_REQUEST";
export const GET_REFS_STATISTICS_RECEIVE = "GET_REFS_STATISTICS_RECEIVE";
//List Of RefrenceLabs
export const GET_REFERENCELABS_LIST_REQUEST = "GET_REFERENCELABS_LIST_REQUEST";
export const GET_REFERENCELABS_LIST_RECEIVE = "GET_REFERENCELABS_LIST_RECEIVE";
//Get REFERENCELABS List
export const GET_REFERENCELABS_REQUEST = "GET_REFERENCELABS_REQUEST";
export const GET_REFERENCELABS_RECEIVE = "GET_REFERENCELABS_RECEIVE";
//Get REFERENCELAB
export const GET_REFERENCELAB_REQUEST = "GET_REFERENCELAB_REQUEST";
export const GET_REFERENCELAB_RECEIVE = "GET_REFERENCELAB_RECEIVE";
//Add REFERENCELAB
export const ADD_REFERENCELAB_REQUEST = "ADD_REFERENCELAB_REQUEST";
export const ADD_REFERENCELAB_RECEIVE = "ADD_REFERENCELAB_RECEIVE";
//Edit REFERENCELAB
export const EDIT_REFERENCELAB_REQUEST = "EDIT_REFERENCELAB_REQUEST";
export const EDIT_REFERENCELAB_RECEIVE = "EDIT_REFERENCELAB_RECEIVE";
//Delete REFERENCELAB
export const DELETE_REFERENCELAB_REQUEST = "DELETE_REFERENCELAB_REQUEST";
export const DELETE_REFERENCELAB_RECEIVE = "DELETE_REFERENCELAB_RECEIVE";
//Active REFERENCELAB
export const ACTIVE_REFERENCELAB_REQUEST = "ACTIVE_REFERENCELAB_REQUEST";
export const ACTIVE_REFERENCELAB_RECEIVE = "ACTIVE_REFERENCELAB_RECEIVE";
//Desactive REFERENCELAB
export const DESACTIVE_REFERENCELAB_REQUEST = "DESACTIVE_REFERENCELAB_REQUEST";
export const DESACTIVE_REFERENCELAB_RECEIVE = "DESACTIVE_REFERENCELAB_RECEIVE";
//Countries List
export const GET_COUNTRIES_LIST_REQUEST = "GET_COUNTRIES_LIST_REQUEST";
export const GET_COUNTRIES_LIST_RECEIVE = "GET_COUNTRIES_LIST_RECEIVE";