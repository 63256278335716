import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";


//Get UsersLabs List
export function* getUsersLabsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getUsersLabsRequest, payload);
    yield put(ACTIONS.getUsersLabsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get UsersLab
export function* getUsersLabSaga({payload}) {
  try {
    const { data } = yield call(APIS.getUsersLabRequest, payload);
    yield put(ACTIONS.getUsersLabReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add UsersLab
export function* addUsersLabSaga({payload, history}) {
  try {
    yield call(APIS.addUsersLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getUsersLabsRequest({
      page: 1,
      sort: "created_at,desc",
      role_id: payload.role_id,
    }));
    payload.role_id === 7 ? history.push("/users/labs") : history.push("/users/hospitals");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit UsersLab
export function* editUsersLabSaga({payload, history}) {
  try {
    yield call(APIS.editUsersLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getUsersLabsRequest({
      page: 1,
      sort: "created_at,desc",
      role_id: payload.role_id,
    }));
    payload.role_id === 7 ? history.push("/users/labs") : history.push("/users/hospitals");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete UsersLab
export function* deleteUsersLabSaga({payload}) {
  try {
    yield call(APIS.deleteUsersLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteUsersLabReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Active UsersLab
export function* activeUsersLabSaga({payload}) {
  try {
    yield call(APIS.activeUsersLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.activeUsersLabReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Desactive UsersLab
export function* desactiveUsersLabSaga({payload}) {
  try {
    yield call(APIS.desactiveUsersLabRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.desactiveUsersLabReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Cities
export function* getCitiesSaga({payload}) {
  try {
    const { data } = yield call(APIS.getCitiesRequest, payload); 
    yield put(ACTIONS.getCitiesReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Regions
export function* getRegionsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getRegionsRequest, payload);
    yield put(ACTIONS.getRegionsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get cities by region
export function* getCitiesByRegionSaga({payload, query}) {
  try {
    const { data } = yield call(APIS.getCitiesByRegionRequest, payload, query);
    yield put(ACTIONS.getCitiesByRegionReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Get agencies List
export function* getAgenciesListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAgenciesListRequest, payload);
    yield put(ACTIONS.getAgenciesListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Check City Agency
export function* checkCityAgencySaga({payload}) {
  try {
    const { data } = yield call(APIS.checkCityAgencyRequest, payload);
    yield put(ACTIONS.checkCityAgencyReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* usersLabsSagas() {
  yield takeLatest(TYPES.GET_USERSLABS_REQUEST, getUsersLabsSaga);
  yield takeLatest(TYPES.GET_USERSLAB_REQUEST, getUsersLabSaga);
  yield takeLatest(TYPES.DELETE_USERSLAB_REQUEST, deleteUsersLabSaga);
  yield takeLatest(TYPES.ACTIVE_USERSLAB_REQUEST, activeUsersLabSaga);
  yield takeLatest(TYPES.DESACTIVE_USERSLAB_REQUEST, desactiveUsersLabSaga);
  yield takeLatest(TYPES.ADD_USERSLAB_REQUEST, addUsersLabSaga);
  yield takeLatest(TYPES.EDIT_USERSLAB_REQUEST, editUsersLabSaga);
  yield takeLatest(TYPES.GET_CITIES_REQUEST, getCitiesSaga);
  yield takeLatest(TYPES.GET_REGIONS_REQUEST, getRegionsSaga);
  yield takeLatest(TYPES.GET_CITIES_BY_REGION_REQUEST, getCitiesByRegionSaga);
  yield takeLatest(TYPES.GET_AGENCIES_LIST_REQUEST, getAgenciesListSaga);
  yield takeLatest(TYPES.CHECK_CITY_AGENCY_REQUEST, checkCityAgencySaga);
}


