import * as TYPES from "./types";

const initialState = {
  samples: [],
};

export default function agenciesReducer(state = initialState, action) {
  switch (action.type) {
    //Get Agency Samples list
    case TYPES.GET_AGENCY_SAMPLES_LIST_RECEIVE: {
      return {
        ...state,
        samples: action.payload,
      }
    }
   
    default:
      return state
  }
}
