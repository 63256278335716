import { combineReducers } from "redux";
import lang from "../lang/reducer";
import loader from "../loader/reducer";
import snackbar from "../snackbar/reducer";
import auth from "../authentication/reducer";
import dashboard from "../dashboard/reducer";
import profile from "../profile/reducer";
import tasks from "../Tasks/reducer";
import requests from "../requests/reducer";
import notifications from "../notifications/reducer";
import users from "../users/reducer";
import programs from "../programs/reducer";
import labTests from "../labTests/reducer";
import referenceLabs from "../referenceLabs/reducer";
import usersLabs from "../usersLabs/reducer";
import patients from "../patients/reducer";
import appointments from "../appointments/reducer";
import attachments from "../attachments/reducer";
import contacts from "../contacts/reducer";
import payments from "../payments/reducer";
import agencies from "../agencies/reducer";
import statistics from "../statistics/reducer";

export default combineReducers({
  lang,
  loader,
  snackbar,
  auth,
  dashboard,
  profile,
  requests,
  notifications,
  tasks,
  users,
  programs,
  labTests,
  referenceLabs,
  usersLabs,
  patients,
  appointments,
  attachments,
  contacts,
  payments,
  agencies,
  statistics,
});
