//all tasks
export const ALL_TASKS_REQUEST = "ALL_TASKS_REQUEST";
export const ALL_TASKS_RECEIVE = "ALL_TASKS_RECEIVE";
//new tasks
export const NEW_TASKS_REQUEST = "NEW_TASKS_REQUEST";
export const NEW_TASKS_RECEIVE = "NEW_TASKS_RECEIVE";
//in progress tasks
export const INPROGRESS_TASKS_REQUEST = "INPROGRESS_TASKS_REQUEST";
export const INPROGRESS_TASKS_RECEIVE = "INPROGRESS_TASKS_RECEIVE";
//completed tasks
export const COMPLETED_TASKS_REQUEST = "COMPLETED_TASKS_REQUEST";
export const COMPLETED_TASKS_RECEIVE = "COMPLETED_TASKS_RECEIVE";
//Add task
export const ADD_TASK_REQUEST = "ADD_TASK_REQUEST";
export const ADD_TASK_RECEIVE = "ADD_TASK_RECEIVE";
//Edit task
export const EDIT_TASK_REQUEST = "EDIT_TASK_REQUEST";
export const EDIT_TASK_RECEIVE = "EDIT_TASK_RECEIVE";
//Delete task
export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST";
export const DELETE_TASK_RECEIVE = "DELETE_TASK_RECEIVE";
//Finish Task
export const FINISH_TASK_REQUEST = "FINISH_TASK_REQUEST";
export const FINISH_TASK_RECEIVE = "FINISH_TASK_RECEIVE";
//Statistics
export const TASKS_STATISTICS_REQUEST = "TASKS_STATISTICS_REQUEST";
export const TASKS_STATISTICS_RECEIVE = "TASKS_STATISTICS_RECEIVE";
//Coordinators Tasks
export const COORDINATORS_TASKS_REQUEST = "COORDINATORS_TASKS_REQUEST";
export const COORDINATORS_TASKS_RECEIVE = "COORDINATORS_TASKS_RECEIVE";
//Coordinators New Tasks
export const COORDINATORS_NEW_TASKS_REQUEST = "COORDINATORS_NEW_TASKS_REQUEST";
export const COORDINATORS_NEW_TASKS_RECEIVE = "COORDINATORS_NEW_TASKS_RECEIVE";
//Coordinators Inprogress Tasks
export const COORDINATORS_INPROGRESS_TASKS_REQUEST = "COORDINATORS_INPROGRESS_TASKS_REQUEST";
export const COORDINATORS_INPROGRESS_TASKS_RECEIVE = "COORDINATORS_INPROGRESS_TASKS_RECEIVE";
//Coordinators Completed Tasks
export const COORDINATORS_COMPLETED_TASKS_REQUEST = "COORDINATORS_COMPLETED_TASKS_REQUEST";
export const COORDINATORS_COMPLETED_TASKS_RECEIVE = "COORDINATORS_COMPLETED_TASKS_RECEIVE";