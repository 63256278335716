import * as TYPES from "./types";

//Get Users List
export const getAppointmentsByUsersRequest = (payload) => ({
  type: TYPES.GET_USERS_APPOINTMENTS_REQUEST,
  payload,
});
export const getAppointmentsByUsersReceive = (payload) => ({
  type: TYPES.GET_USERS_APPOINTMENTS_RECEIVE,
  payload,
});
//Get Appointments List
export const getAppointmentsListRequest = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_LIST_REQUEST,
  payload,
});
export const getAppointmentsListReceive = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_LIST_RECEIVE,
  payload,
});
//Get MY Appointments
export const getMyAppointmentsRequest = (payload) => ({
  type: TYPES.GET_MY_APPOINTMENTS_REQUEST,
  payload,
});
export const getMyAppointmentsReceive = (payload) => ({
  type: TYPES.GET_MY_APPOINTMENTS_RECEIVE,
  payload,
});
//Get My Today Appointments
export const getMyTodayAppointmentsRequest = (payload) => ({
  type: TYPES.GET_MY_TODAY_APPOINTMENTS_REQUEST,
  payload,
});
export const getMyTodayAppointmentsReceive = (payload) => ({
  type: TYPES.GET_MY_TODAY_APPOINTMENTS_RECEIVE,
  payload,
});
//Get Appointments
export const getAppointmentsRequest = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_REQUEST,
  payload,
});
export const getAppointmentsReceive = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_RECEIVE,
  payload,
});
//Get Receptionist Appointments List
export const getReceptionistAppointmentsListRequest = (payload) => ({
  type: TYPES.GET_RECEPTIONIST_APPOINTMENTS_LIST_REQUEST,
  payload,
});
export const getReceptionistAppointmentsListReceive = (payload) => ({
  type: TYPES.GET_RECEPTIONIST_APPOINTMENTS_LIST_RECEIVE,
  payload,
});
//Get Upcomming Appointment
export const getUpcommingAppointmentRequest = () => ({
  type: TYPES.GET_UPCOMMING_APPOINTMENT_REQUEST,
});
export const getUpcommingAppointmentReceive = (payload) => ({
  type: TYPES.GET_UPCOMMING_APPOINTMENT_RECEIVE,
  payload,
});
//Get Appointment
export const getAppointmentRequest = (payload) => ({
  type: TYPES.GET_APPOINTMENT_REQUEST,
  payload,
});
export const getAppointmentReceive = (payload) => ({
  type: TYPES.GET_APPOINTMENT_RECEIVE,
  payload,
});
//Delete Appointment
export const deleteAppointmentRequest = (payload) => ({
  type: TYPES.DELETE_APPOINTMENT_REQUEST,
  payload,
});
export const deleteAppointmentReceive = (payload) => ({
  type: TYPES.DELETE_APPOINTMENT_RECEIVE,
  payload,
});
//Add Appointment
export const addAppointmentRequest = (payload, history) => ({
  type: TYPES.ADD_APPOINTMENT_REQUEST,
  payload,
  history,
});
export const addAppointmentReceive = (payload) => ({
  type: TYPES.ADD_APPOINTMENT_RECEIVE,
  payload,
});
//Edit Appointment
export const editAppointmentRequest = (payload, history) => ({
  type: TYPES.EDIT_APPOINTMENT_REQUEST,
  payload,
  history,
});
export const editAppointmentReceive = (payload) => ({
  type: TYPES.EDIT_APPOINTMENT_RECEIVE,
  payload,
});
//Active Appointment
export const activeAppointmentRequest = (payload, history) => ({
  type: TYPES.ACTIVE_APPOINTMENT_REQUEST,
  payload,
  history,
});
export const activeAppointmentReceive = (payload) => ({
  type: TYPES.ACTIVE_APPOINTMENT_RECEIVE,
  payload,
});
//Change Status Appointment
export const changeStatusAppointmentRequest = (payload) => ({
  type: TYPES.CHANGE_STATUS_APPOINTMENT_REQUEST,
  payload,
});
export const changeStatusAppointmentReceive = (payload) => ({
  type: TYPES.CHANGE_STATUS_APPOINTMENT_RECEIVE,
  payload,
});
//Upadate Change Status Appointment
export const updateChangeStatusAppointmentRequest = (payload) => ({
  type: TYPES.UPDATE_CHANGE_STATUS_APPOINTMENT_REQUEST,
  payload,
});
export const updateChangeStatusAppointmentReceive = (payload) => ({
  type: TYPES.UPDATE_CHANGE_STATUS_APPOINTMENT_RECEIVE,
  payload,
});
//Send To Patient
export const sendToPatientRequest = (payload) => ({
  type: TYPES.SEND_TO_PATIENT_REQUEST,
  payload,
});
export const sendToPatientReceive = (payload) => ({
  type: TYPES.SEND_TO_PATIENT_RECEIVE,
  payload,
});
//Get Appointment Reasons
export const getAppointmentReasonsRequest = (payload) => ({
  type: TYPES.GET_APPOINTMENT_REASONS_REQUEST,
  payload,
});
export const getAppointmentReasonsReceive = (payload) => ({
  type: TYPES.GET_APPOINTMENT_REASONS_RECEIVE,
  payload,
});
//Get Labtests List
export const getLabtestsListRequest = (payload) => ({
  type: TYPES.GET_LABTESTS_LIST_REQUEST,
  payload,
});
export const getLabtestsListReceive = (payload) => ({
  type: TYPES.GET_LABTESTS_LIST_RECEIVE,
  payload,
});
//Get Patient Labtests
export const getPatientLabtestsRequest = (payload, patient_id) => ({
  type: TYPES.GET_PATIENT_LABTESTS_REQUEST,
  payload,
  patient_id,
});
export const getPatientLabtestsReceive = (payload) => ({
  type: TYPES.GET_PATIENT_LABTESTS_RECEIVE,
  payload,
});
//Get Patient Labtests List
export const getPatientLabtestsListRequest = (payload) => ({
  type: TYPES.GET_PATIENT_LABTESTS_LIST_REQUEST,
  payload,
});
export const getPatientLabtestsListReceive = (payload) => ({
  type: TYPES.GET_PATIENT_LABTESTS_LIST_RECEIVE,
  payload,
});
//Add Labtest
export const addAppointmentLabtestRequest = (payload) => ({
  type: TYPES.ADD_APPOINTMENT_LABTEST_REQUEST,
  payload,
});
export const addAppointmentLabtestReceive = (payload) => ({
  type: TYPES.ADD_APPOINTMENT_LABTEST_RECEIVE,
  payload,
});
//Edit Labtest
export const editAppointmentLabtestRequest = (payload) => ({
  type: TYPES.EDIT_APPOINTMENT_LABTEST_REQUEST,
  payload,
});
export const editAppointmentLabtestReceive = (payload) => ({
  type: TYPES.EDIT_APPOINTMENT_LABTEST_RECEIVE,
  payload,
});
//Delete Labtest
export const deleteAppointmentLabtestRequest = (payload, patient_id) => ({
  type: TYPES.DELETE_APPOINTMENT_LABTEST_REQUEST,
  payload,
  patient_id
});
export const deleteAppointmentLabtestReceive = (payload) => ({
  type: TYPES.DELETE_APPOINTMENT_LABTEST_RECEIVE,
  payload,
});
//Appointments Statistics
export const getAppointmentsStatisticsRequest = () => ({
  type: TYPES.GET_APPOINTMENTS_STATISTICS_REQUEST,
});
export const getAppointmentsStatisticsReceive = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_STATISTICS_RECEIVE,
  payload,
});
//Appointments Statistics Block
export const getAppointmentsStatisticsBlockRequest = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_STATISTICS_BLOCK_REQUEST,
  payload,
});
export const getAppointmentsStatisticsBlockReceive = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_STATISTICS_BLOCK_RECEIVE,
  payload,  
});
//Appointments Users List
export const getAppointmentsUsersListRequest = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_USERS_LIST_REQUEST,
  payload,
});
export const getAppointmentsUsersListReceive = (payload) => ({
  type: TYPES.GET_APPOINTMENTS_USERS_LIST_RECEIVE,
  payload,
});
//Set added appointment
export const setAddedAppointment = (payload) => ({
  type: TYPES.SET_ADDED_APPOINTMENT,
  payload,
});
//Set Added Reason
export const setAddedReason = (payload) => ({
  type: TYPES.SET_ADDED_REASON,
  payload,
});
//Set Sent To Patient
export const setSentToPatient = (payload) => ({
  type: TYPES.SET_SENT_TO_PATIENT,
  payload,
});
