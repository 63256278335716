import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Get Contacts
export function* getContactsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getContactsRequest, payload);
    yield put(ACTIONS.getContactsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Contact
export function* getContactSaga({payload}) {
  try {
    const { data } = yield call(APIS.getContactRequest, payload);
    yield put(ACTIONS.getContactReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add Contact
export function* addContactSaga({payload, history, patientId}) {
  try {
    yield put(ACTIONS.setAddedContact(false));
    yield call(APIS.addContactRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getContactsRequest({
      page: 1,
      sort: "created_at,desc",
      patientId: payload.patient_id,
    }));
    yield put(ACTIONS.setAddedContact(true));
    history && history.push("/activeRequests")
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit Contact
export function* editContactSaga({payload, history}) {
  try {
    yield put(ACTIONS.setAddedContact(false));
    yield call(APIS.editContactRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getContactsRequest({
      page: 1,
      patientId: payload.patient_id,
      sort: "created_at,desc",
    }));
    yield put(ACTIONS.setAddedContact(true));
    history.push("/clients");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Delete Contact
export function* deleteContactSaga({payload}) {
  try {
    yield call(APIS.deleteContactRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deleteContactReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* contactsSagas() {
  yield takeLatest(TYPES.GET_CONTACTS_REQUEST, getContactsSaga);
  yield takeLatest(TYPES.GET_CONTACT_REQUEST, getContactSaga);
  yield takeLatest(TYPES.DELETE_CONTACT_REQUEST, deleteContactSaga);
  yield takeLatest(TYPES.ADD_CONTACT_REQUEST, addContactSaga);
  yield takeLatest(TYPES.EDIT_CONTACT_REQUEST, editContactSaga);
}


