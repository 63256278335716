import * as TYPES from "./types";

//Get Patients Statistics
export const getPatientsStatisticsRequest = () => ({
  type: TYPES.GET_PATIENTS_STATISTICS_REQUEST,
});
export const getPatientsStatisticsReceive = (payload) => ({
  type: TYPES.GET_PATIENTS_STATISTICS_RECEIVE,
  payload,
});
//Get Patients List
export const getPatientsRequest = (payload) => ({
  type: TYPES.GET_PATIENTS_REQUEST,
  payload,
});
export const getPatientsReceive = (payload) => ({
  type: TYPES.GET_PATIENTS_RECEIVE,
  payload,
});
//Get My Patients
export const getMyPatientsRequest = (payload) => ({
  type: TYPES.GET_MY_PATIENTS_REQUEST,
  payload,
});
export const getMyPatientsReceive = (payload) => ({
  type: TYPES.GET_MY_PATIENTS_RECEIVE,
  payload,
});
//Get Patient
export const getPatientRequest = (payload) => ({
  type: TYPES.GET_PATIENT_REQUEST,
  payload,
});
export const getPatientReceive = (payload) => ({
  type: TYPES.GET_PATIENT_RECEIVE,
  payload,
});
//Delete Patient
export const deletePatientRequest = (payload) => ({
  type: TYPES.DELETE_PATIENT_REQUEST,
  payload,
});
export const deletePatientReceive = (payload) => ({
  type: TYPES.DELETE_PATIENT_RECEIVE,
  payload,
});
//Active Patient
export const activePatientRequest = (payload) => ({
  type: TYPES.ACTIVE_PATIENT_REQUEST,
  payload,
});
export const activePatientReceive = (payload) => ({
  type: TYPES.ACTIVE_PATIENT_RECEIVE,
  payload,
});
//Desactive Patient
export const desactivePatientRequest = (payload) => ({
  type: TYPES.DESACTIVE_PATIENT_REQUEST,
  payload,
});
export const desactivePatientReceive = (payload) => ({
  type: TYPES.DESACTIVE_PATIENT_RECEIVE,
  payload,
});
//Add Patient
export const addPatientRequest = (payload, history) => ({
  type: TYPES.ADD_PATIENT_REQUEST,
  payload,
  history,
});
export const addPatientReceive = (payload) => ({
  type: TYPES.ADD_PATIENT_RECEIVE,
  payload,
});
//Edit Patient
export const editPatientRequest = (payload, history) => ({
  type: TYPES.EDIT_PATIENT_REQUEST,
  payload,
  history,
});
export const editPatientReceive = (payload) => ({
  type: TYPES.EDIT_PATIENT_RECEIVE,
  payload,
});
//Get Families
export const getFamiliesRequest = () => ({
  type: TYPES.GET_FAMILIES_REQUEST,
});
export const getFamiliesReceive = (payload) => ({
  type: TYPES.GET_FAMILIES_RECEIVE,
  payload,
});
//Add Family
export const addFamilyRequest = (payload) => ({
  type: TYPES.ADD_FAMILY_REQUEST,
  payload,
});
export const addFamilyReceive = (payload) => ({
  type: TYPES.ADD_FAMILY_RECEIVE,
  payload,
});
//Set Valide to False
export const setValideFalse = () => ({
  type: TYPES.SET_VALIDE_FALSE,
});
//Valid Step
export const validStep = (payload) => ({
  type: TYPES.VALID_STEP,
  payload,
});
//Valid Id
export const validIdRequest = (payload) => ({
  type: TYPES.VALID_ID_REQUEST,
  payload,
});
export const validIdReceive = (payload, error) => ({
  type: TYPES.VALID_ID_RECEIVE,
  payload,
  error
});
//Patient ValidID
export const patientValidIdRequest = (payload) => ({
  type: TYPES.PATIENT_VALID_ID_REQUEST, 
  payload
})
export const patientValidIdReceive = (payload, error) => ({
  type: TYPES.PATIENT_VALID_ID_RECEIVE,
  payload,
  error
})
//Reset ValidId
export const resetValidId = () => ({
  type: TYPES.RESET_VALID_ID,
})

//Sampling reservation
export const samplingReservationRequest = (payload) => ({
  type: TYPES.SAMPLING_RESERVATION_REQUEST,
  payload,
});
export const samplingReservationReceive = (payload) => ({
  type: TYPES.SAMPLING_RESERVATION_RECEIVE,
  payload,
});
//Update Sampling Reservation
export const updateSamplingReservationRequest = (payload) => ({
  type: TYPES.UPDATE_SAMPLING_RESERVATION_REQUEST,
  payload,
});
export const updateSamplingReservationReceive = (payload) => ({
  type: TYPES.UPDATE_SAMPLING_RESERVATION_RECEIVE,
  payload,
});
//Sampling Force Deposited
export const samplingForceDepositedRequest = (payload) => ({
  type: TYPES.SAMPLING_FORCE_DEPOSITED_REQUEST,
  payload,
});
export const samplingForceDepositedReceive = (payload) => ({
  type: TYPES.SAMPLING_FORCE_DEPOSITED_RECEIVE,
  payload,
});
//Set Sample Reserved
export const setSampleReserved = (payload) => ({
  type: TYPES.SET_SAMPLE_RESERVED,
  payload,
});
//Get Patient Notes
export const getPatientNotesRequest = (payload) => ({
  type: TYPES.GET_PATIENT_NOTES_REQUEST,
  payload,
});
export const getPatientNotesReceive = (payload) => ({
  type: TYPES.GET_PATIENT_NOTES_RECEIVE,
  payload,
});
//Add Patient Note
export const addPatientNoteRequest = (payload) => ({
  type: TYPES.ADD_PATIENT_NOTE_REQUEST,
  payload,
});
export const addPatientNoteReceive = (payload) => ({
  type: TYPES.ADD_PATIENT_NOTE_RECEIVE,
  payload,
});
