export const LOGIN_WITH_OTP_REQUEST = "LOGIN_WITH_OTP_REQUEST";
export const LOGIN_WITH_OTP_RECEIVE = "LOGIN_WITH_OTP_RECEIVE";

export const OTP_LOGIN_REQUEST = "OTP_LOGIN_REQUEST";
export const OTP_LOGIN_RECEIVE = "OTP_LOGIN_RECEIVE";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_RECEIVE = "RESEND_OTP_RECEIVE";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_RECEIVE = "FORGET_PASSWORD_RECEIVE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RECEIVE = "RESET_PASSWORD_RECEIVE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_RECEIVE = "GET_USER_RECEIVE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_RECEIVE = "LOGOUT_RECEIVE";

// Agencies

export const AGENCIES_LOGIN_WITH_OTP_REQUEST = "AGENCIES_LOGIN_WITH_OTP_REQUEST";
export const AGENCIES_LOGIN_WITH_OTP_RECEIVE = "AGENCIES_LOGIN_WITH_OTP_RECEIVE";

export const AGENCIES_OTP_LOGIN_REQUEST = "AGENCIES_OTP_LOGIN_REQUEST";
export const AGENCIES_OTP_LOGIN_RECEIVE = "AGENCIES_OTP_LOGIN_RECEIVE";

export const AGENCIES_RESEND_OTP_REQUEST = "AGENCIES_RESEND_OTP_REQUEST";
export const AGENCIES_RESEND_OTP_RECEIVE = "AGENCIES_RESEND_OTP_RECEIVE";

export const AGENCIES_FORGET_PASSWORD_REQUEST = "AGENCIES_FORGET_PASSWORD_REQUEST";
export const AGENCIES_FORGET_PASSWORD_RECEIVE = "AGENCIES_FORGET_PASSWORD_RECEIVE";

export const AGENCIES_RESET_PASSWORD_REQUEST = "AGENCIES_RESET_PASSWORD_REQUEST";
export const AGENCIES_RESET_PASSWORD_RECEIVE = "AGENCIES_RESET_PASSWORD_RECEIVE";

export const AGENCIES_GET_USER_REQUEST = "AGENCIES_GET_USER_REQUEST";
export const AGENCIES_GET_USER_RECEIVE = "AGENCIES_GET_USER_RECEIVE";

export const AGENCIES_LOGOUT_REQUEST = "AGENCIES_LOGOUT_REQUEST";
export const AGENCIES_LOGOUT_RECEIVE = "AGENCIES_LOGOUT_RECEIVE";