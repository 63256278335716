import { axiosInstance } from "../../network";

// Old Notifications list
export const oldNotificationsRequest = async (payload) => {
  if (payload && payload?.type === "agency") {
    return await axiosInstance.get("agency/v1/notifications/old");
  } else {
    return await axiosInstance.get("system/v1/notifications/old");
  }
};

// New Notifications list
export const newNotificationsRequest = async (payload) => {
  if (payload && payload?.type === "agency") {
    return await axiosInstance.get("agency/v1/notifications/new");
  } else {
    return await axiosInstance.get("system/v1/notifications/new");
  }
};

// Count Notifications
export const countNotificationsRequest = async (payload) => {
  if (payload && payload?.type === "agency") {
    return await axiosInstance.get("agency/v1/notifications/count");
  } else {
    return await axiosInstance.get("system/v1/notifications/count");
  }
};

// Mark Notification as readed
export const markAsReadedRequest = async (payload) => {
  if (payload && payload?.type === "agency") {
    return await axiosInstance.put(`agency/v1/notifications/${payload.note_id}/read`);
  } else {
    return await axiosInstance.put(`system/v1/notifications/${payload.note_id}/read`);
  }
};
