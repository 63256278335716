import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Get Patients Statistics
export function* getPatientsStatisticsSaga() {
  try {
    const { data } = yield call(APIS.getPatientsStatisticsRequest);
    yield put(ACTIONS.getPatientsStatisticsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Patients List
export function* getPatientsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPatientsRequest, payload);
    yield put(ACTIONS.getPatientsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get My Patients
export function* getMyPatientsSaga({payload}) {
  try {
    const { data } = yield call(APIS.getMyPatientsRequest, payload);
    yield put(ACTIONS.getMyPatientsReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Patient
export function* getPatientSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPatientRequest, payload);
    yield put(ACTIONS.getPatientReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add Patient
export function* addPatientSaga({payload, history}) {
  try {
    const { data } = yield call(APIS.addPatientRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getPatientsRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    if (history && data?.patient_id) {
      history.push("/patient-preview/" + data.patient_id);
    }
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Edit Patient
export function* editPatientSaga({payload, history}) {
  try {
    yield put(ACTIONS.validStep(false))
    yield call(APIS.editPatientRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.getPatientsRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    yield put(ACTIONS.getFamiliesRequest());
    yield put(ACTIONS.getPatientRequest(payload.id));
    yield put(ACTIONS.validStep(true))
    history.push("/clients");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
    yield put(ACTIONS.validStep(false))
  }
}
//Delete Patient
export function* deletePatientSaga({payload}) {
  try {
    yield call(APIS.deletePatientRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.deletePatientReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Active Patient
export function* activePatientSaga({payload}) {
  try {
    yield call(APIS.activePatientRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.activePatientReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Desactive Patient
export function* desactivePatientSaga({payload}) {
  try {
    yield call(APIS.desactivePatientRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.desactivePatientReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Families
export function* getFamiliesSaga({payload}) {
  try {
    const { data } = yield call(APIS.getFamiliesRequest, payload); 
    yield put(ACTIONS.getFamiliesReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
//Add Family
export function* addFamilySaga({payload}) {
  try {
    yield put(ACTIONS.setValideFalse());
    const { data } = yield call(APIS.addFamilyRequest, payload);
    if (data) {
      yield put(ACTIONS.addFamilyReceive(payload));
    }
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Valid Id
export function* validIdSaga({payload}) {
  try {
    yield call(APIS.validIdRequest, payload);
    yield put(ACTIONS.validIdReceive(true));
  } catch (err) {
    yield put(ACTIONS.validIdReceive(false, err.response?.data?.message));
  }
}

//Reset ValidId
export function* resetValidIdSaga() {
  try {
    console.log("reset", true)
    yield put(ACTIONS.validIdReceive(true));
  } catch (err) {
    console.log(err)
  }
}

//Patient ValidID
export function* patientValidIdSaga({payload}) {
  try {
    yield call(APIS.patientValidIdRequest, payload);
    yield put(ACTIONS.patientValidIdReceive(true));
  } catch (err) {
    yield put(ACTIONS.patientValidIdReceive(false, err.response?.data?.message));
  }
}

//Sample Reservation
export function* sampleReservationSaga({payload}) {
  try {
    console.log(payload)
    yield put(ACTIONS.samplingReservationReceive(false));
    yield call(APIS.samplingReservationRequest, payload);
    yield put(ACTIONS.samplingReservationReceive(true));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    yield put(ACTIONS.samplingReservationReceive(false));
    dispatchSnackbarError(err.response?.data);
  }
}

//Update Sample Reservation
export function* updateSampleReservationSaga({payload}) {
  try {
    yield put(ACTIONS.samplingReservationReceive(false));
    yield call(APIS.updateSamplingReservationRequest, payload);
    yield put(ACTIONS.samplingReservationReceive(true));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    yield put(ACTIONS.samplingReservationReceive(false));
    dispatchSnackbarError(err.response?.data);
  }
}

//Sampling Force Deposited
export function* samplingForceDepositedSaga({payload}) {
  try {
    yield put(ACTIONS.samplingForceDepositedReceive(false));
    yield call(APIS.samplingForceDepositedRequest, payload);
    dispatchSnackbarSuccess("success");
  } catch (err) {
    yield put(ACTIONS.samplingForceDepositedReceive(false));
    dispatchSnackbarError(err.response?.data);
  }
}

export function* setSampleReservedSaga({payload}) {
  try {
    yield put(ACTIONS.samplingForceDepositedReceive(false));
  } catch (err) {
    console.log(err)
  }
}

//Get Patient Notes
export function* getPatientNotesSaga({payload}) {
  try {
    const { data } = yield call(APIS.getPatientNotesRequest, payload);
    yield put(ACTIONS.getPatientNotesReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Add Patient Notes
export function* addPatientNotesSaga({payload}) {
  try {
    yield call(APIS.addPatientNotesRequest, payload);
    dispatchSnackbarSuccess("success");
    yield put(ACTIONS.addPatientNoteReceive(payload));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* patientsSagas() {
  yield takeLatest(TYPES.GET_PATIENTS_STATISTICS_REQUEST, getPatientsStatisticsSaga);
  yield takeLatest(TYPES.GET_PATIENTS_REQUEST, getPatientsSaga);
  yield takeLatest(TYPES.GET_MY_PATIENTS_REQUEST, getMyPatientsSaga);
  yield takeLatest(TYPES.GET_PATIENT_REQUEST, getPatientSaga);
  yield takeLatest(TYPES.DELETE_PATIENT_REQUEST, deletePatientSaga);
  yield takeLatest(TYPES.ACTIVE_PATIENT_REQUEST, activePatientSaga);
  yield takeLatest(TYPES.DESACTIVE_PATIENT_REQUEST, desactivePatientSaga);
  yield takeLatest(TYPES.ADD_PATIENT_REQUEST, addPatientSaga);
  yield takeLatest(TYPES.EDIT_PATIENT_REQUEST, editPatientSaga);
  yield takeLatest(TYPES.GET_FAMILIES_REQUEST, getFamiliesSaga);
  yield takeLatest(TYPES.ADD_FAMILY_REQUEST, addFamilySaga);
  yield takeLatest(TYPES.VALID_ID_REQUEST, validIdSaga);
  yield takeLatest(TYPES.PATIENT_VALID_ID_REQUEST, patientValidIdSaga);
  yield takeLatest(TYPES.RESET_VALID_ID, resetValidIdSaga);
  yield takeLatest(TYPES.SAMPLING_RESERVATION_REQUEST, sampleReservationSaga);
  yield takeLatest(TYPES.UPDATE_SAMPLING_RESERVATION_REQUEST, updateSampleReservationSaga);
  yield takeLatest(TYPES.SAMPLING_FORCE_DEPOSITED_REQUEST, samplingForceDepositedSaga);
  yield takeLatest(TYPES.SET_SAMPLE_RESERVED, setSampleReservedSaga);
  yield takeLatest(TYPES.GET_PATIENT_NOTES_REQUEST, getPatientNotesSaga);
  yield takeLatest(TYPES.ADD_PATIENT_NOTE_REQUEST, addPatientNotesSaga);
}


