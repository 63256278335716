import * as TYPES from "./types";

// oLD Notifications list
export const oldNotificationsRequest = (payload) => ({
  type: TYPES.OLD_NOTIFICATIONS_REQUEST,
  payload,
});
export const oldNotificationsReceive = (payload) => ({
  type: TYPES.OLD_NOTIFICATIONS_RECEIVE,
  payload,
});

// New Notifications list
export const newNotificationsRequest = (payload) => ({
  type: TYPES.NEW_NOTIFICATIONS_REQUEST,
  payload,
});
export const newNotificationsReceive = (payload) => ({
  type: TYPES.NEW_NOTIFICATIONS_RECEIVE,
  payload,
});

// Count Notifications
export const countNotificationsRequest = (payload) => ({
  type: TYPES.COUNT_NOTIFICATIONS_REQUEST,
  payload,
});
export const countNotificationsReceive = (payload) => ({
  type: TYPES.COUNT_NOTIFICATIONS_RECEIVE,
  payload,
});

// Mark Notification As Readed
export const markAsReadedRequest = (payload) => ({
  type: TYPES.MARK_ASREADED_REQUEST,
  payload,
});
export const markAsReadedReceive = (payload) => ({
  type: TYPES.MARK_ASREADED_RECEIVE,
  payload,
});

// Real Time Notifications
export const realTimeNotificationsRequest = (payload) => ({
  type: TYPES.REALTIME_NOTIFICATIONS_REQUEST,
  payload,
});
export const realTimeNotificationsReceive = (payload) => ({
  type: TYPES.REALTIME_NOTIFICATIONS_RECEIVE,
  payload,
});
