import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError, dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./types";

//Get Agency Samples List
export function* getAgencySamplesListSaga({payload}) {
  try {
    const { data } = yield call(APIS.getAgencySamplesListRequest, payload);
    yield put(ACTIONS.getAgencySamplesListReceive(data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

//Change Status
export function* agenciesChangeStatusSaga({payload}) {
  try {
    yield call(APIS.agenciesChangeStatusRequest, payload);
    yield put(ACTIONS.getAgencySamplesListRequest({
      page: 1,
      sort: "created_at,desc",
    }));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* agenciesSagas() {
  yield takeLatest(TYPES.GET_AGENCY_SAMPLES_LIST_REQUEST, getAgencySamplesListSaga);
  yield takeLatest(TYPES.AGENCIES_CHANGE_STATUS_REQUEST, agenciesChangeStatusSaga);
}


