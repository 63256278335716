import * as TYPES from "./types";

//Get Contacts
export const getContactsRequest = (payload) => ({
  type: TYPES.GET_CONTACTS_REQUEST,
  payload,
});
export const getContactsReceive = (payload) => ({
  type: TYPES.GET_CONTACTS_RECEIVE,
  payload,
});
//Get Contact
export const getContactRequest = (payload) => ({
  type: TYPES.GET_CONTACT_REQUEST,
  payload,
});
export const getContactReceive = (payload) => ({
  type: TYPES.GET_CONTACT_RECEIVE,
  payload,
});
//Add Contact
export const addContactRequest = (payload, history) => ({
  type: TYPES.ADD_CONTACT_REQUEST,
  payload,
  history,
});
export const addContactReceive = (payload) => ({
  type: TYPES.ADD_CONTACT_RECEIVE,
  payload,
});
//Edit Contact
export const editContactRequest = (payload, history) => ({
  type: TYPES.EDIT_CONTACT_REQUEST,
  payload,
  history,
});
//Delete Contact
export const deleteContactRequest = (payload) => ({
  type: TYPES.DELETE_CONTACT_REQUEST,
  payload,
});
export const deleteContactReceive = (payload) => ({
  type: TYPES.DELETE_CONTACT_RECEIVE,
  payload,
});
//Set added Contact
export const setAddedContact = (payload) => ({
  type: TYPES.SET_ADDED_CONTACT,
  payload,
});