import * as TYPES from "./types";

const initialState = {
  patients: [],
  myPatients: {},
  patient: {},
  families: [],
  valideFamilyName: false,
  validStep: false,
  patientsStatistics: {},
  validId: false,
  IDError: "",
  sampleReserved: false,
  patientNotes: {},
};

export default function patientsReducer(state = initialState, action) {
  switch (action.type) {
    //Get Patients Statistics
    case TYPES.GET_PATIENTS_STATISTICS_RECEIVE: {
      return {
        ...state,
        patientsStatistics: action.payload,
      }
    }
    //Get Patients
    case TYPES.GET_PATIENTS_RECEIVE: {
      return {
        ...state,
        patients: action.payload,
      }
    }
    //Get My Patients
    case TYPES.GET_MY_PATIENTS_RECEIVE: {
      return {
        ...state,
        myPatients: action.payload,
      }
    }
    //Get patient
    case TYPES.GET_PATIENT_RECEIVE: {
      return {
        ...state,
        patient: action.payload.data,
      }
    }
    case TYPES.DELETE_PATIENT_RECEIVE: {
      return {
        ...state,
        patients: { ...state.patients, data: state.patients?.data?.filter((user) => user.id !== action.payload) }
      }
    }
    case TYPES.ACTIVE_PATIENT_RECEIVE: {
      return {
        ...state,
        patients: { ...state.patients, data: state.patients?.data?.map((pa) => {
          if (pa.id === action.payload) {
            return { ...pa, is_active: 1 }
          }
          return pa
        }) }
      }
    }
    case TYPES.DESACTIVE_PATIENT_RECEIVE: {
      return {
        ...state,
        patients: { ...state.patients, data: state.patients?.data?.map((pa) => {
          if (pa.id === action.payload) {
            return { ...pa, is_active: 0 }
          }
          return pa
        }) }
      }
    }
    //Get Families
    case TYPES.GET_FAMILIES_RECEIVE: {
      return {
        ...state,
        families: action.payload.data,
      }
    }
    //Add Family
    case TYPES.ADD_FAMILY_RECEIVE: {
      return {
        ...state,
        families: [...state.families, {id: action.payload, name: action.payload}],
        valideFamilyName: true,
      }
    }
    case TYPES.SET_VALIDE_FALSE: {
      return {
        ...state,
        valideFamilyName: false,
      }
    }
    // Valid Step
    case TYPES.VALID_STEP: {
      return {
        ...state,
        validStep: action.payload,
      }
    }
    //Valid Id
    case TYPES.VALID_ID_RECEIVE: {
      return {
        ...state,
        validId: action.payload,
        IDError: action.payload ? "" : action?.error,
      }
    }
     //Patient ValidID
     case TYPES.PATIENT_VALID_ID_RECEIVE: {
      return {
        ...state,
        validId: action.payload,
        IDError: action.payload ? "" : action?.error,
      }
    }
    //Sample Reserved
    case TYPES.SAMPLING_RESERVATION_RECEIVE: {
      return {
        ...state,
        sampleReserved: action.payload,
      }
    }
    //Sample Force Deposited
    case TYPES.SAMPLING_FORCE_DEPOSITED_RECEIVE: {
      return {
        ...state,
        sampleReserved: action.payload,
      }
    }
    //Get Patient Notes
    case TYPES.GET_PATIENT_NOTES_RECEIVE: {
      return {
        ...state,
        patientNotes: action.payload,
      }
    }
    //Add Patient Notes
    case TYPES.ADD_PATIENT_NOTE_RECEIVE: {
      return {
        ...state,
        patientNotes: [...state.patientNotes, action.payload],
      }
    }
    default:
      return state
  }
}
