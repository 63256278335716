import { axiosInstance } from "../../network";

//GET Contacts
export const getContactsRequest = async (payload) => {
  return await axiosInstance.get('system/v1/contacts', {params: payload});
}
//Get Contact
export const getContactRequest = async (payload) => {
  return await axiosInstance.get('system/v1/contacts/' + payload);
}
//Add Contact
export const addContactRequest = async (payload) => {
  return await axiosInstance.post('system/v1/contacts', payload);
}
//Edit Contact
export const editContactRequest = async (payload) => {
  return await axiosInstance.put(`system/v1/contacts/${payload.id}`, payload);
}
//Delete Contact
export const deleteContactRequest = async (payload) => {
  return await axiosInstance.delete(`system/v1/contacts/${payload}`);
}

